import styled from "styled-components";

export const TableWrapper = styled.div`
  .top-wrapper {
    margin-bottom: 1rem;

    .title-wrapper {
      .custom-title {
        font-size: 1rem;
        font-weight: 600;
        font-family: "Montserrat", sans-serif;
        color: ${({ theme }) => theme.dark_color};
      }
    }

    .search-wrapper {
      margin-top: 0.5rem;
    }
  }

  .table-wrapper {
    overflow: hidden;
    overflow-x: auto;
    border-radius: 0.3125rem;
    background-color: ${({ theme }) => theme.light_color};

    &::-webkit-scrollbar {
      display: none;
    }

    table {
      width: 100%;
      overflow-x: auto;

      thead {
        th {
          overflow: hidden;
          white-space: nowrap;
          padding: 0.875rem 1rem;
          text-overflow: ellipsis;
          background-color: #e4e8ec;
        }
      }

      tbody {
        tr {
          &:not(:last-of-type) {
            border-bottom: 1px solid #e4e8ec;
          }

          td {
            overflow: hidden;
            white-space: nowrap;
            padding: 0.875rem 1rem;
            text-overflow: ellipsis;
          }
        }
      }
    }
  }

  @media (min-width: 768px) {
    .top-wrapper {
      display: flex;
      flex-wrap: nowrap;
      /* align-items: flex-start; */
      margin: 0 -1rem 1rem;

      .title-wrapper,
      .search-wrapper {
        width: 50%;
        padding: 0 1rem;
      }
      .filter-wrapper {
        text-align: left;
      }
      .search-wrapper {
        text-align: right;
      }
    }
  }
`;

export const StyledTable = styled.table`
  border-collapse: collapse;
  width: 100%;

  td,
  th {
    padding: 0.875rem 1rem;
    font-size: 14px;
    line-height: 17px;
  }

  th {
    text-align: left;
    background-color: #cacaca;
    color: #161616;
    font-weight: 600;
  }

  td {
    font-weight: normal;
    color: #393939;
  }

  tbody tr:not(:last-of-type) {
    border-bottom: 1px solid #e0e0e0;
  }
`;

export const StyledTableWrapper = styled.div`
  border: 1px solid #e0e0e0;
  border-radius: 2px;
  background-color: #f1f1f1;
  margin-bottom: 2.5rem;
`;

export const StyledTableWrapperHeading = styled.div`
  margin: 0.625rem 0 1.25rem 1rem;

  h4 {
    font-weight: bold;
    color: #18222f;
    font-size: 20px;
    line-height: 23px;
  }

  p {
    margin-top: 6px;
    font-weight: normal;
    font-size: 14px;
    line-height: 18px;
    color: #18222f;
  }

  &.company-rep {
    margin: 2.3125rem 0 1.25rem 1rem;
  }
`;

export const StyledTableWrapperFooter = styled.div`
  background-color: #fff;
  padding: 1.6rem 1rem 0.625rem;
`;
