import styled from "styled-components";

export const AdminWrapper = styled.div`
  padding-top: 3.125rem;

  h3 {
    font-weight: 800;
    font-size: 24px;
    line-height: 18px;
    color: #163327;
    margin-bottom: 2.875rem;

    a {
      text-decoration: none;
      color: #163327;
    }

    span {
      font-weight: normal;
    }
  }
`;

export const Card = styled.div`
  box-shadow: 0px 0px 1px rgba(9, 30, 66, 0.31), 0px 10px 18px rgba(9, 30, 66, 0.15);
  border-radius: 3px;
  background-color: #fff;
  padding: 1.875rem 1.25rem 1rem;
  display: flex;
  justify-content: space-between;
  border: ${(props) => (props.active ? "1px solid #00a3bf" : "none")};

  &:nth-of-type(1) > div:first-of-type {
    background-color: #00a3bf;
  }

  &:nth-of-type(2) > div:first-of-type {
    background-color: #ff5630;
  }

  &:nth-of-type(3) > div:first-of-type {
    background-color: #ff991f;
  }

  &:nth-of-type(4) > div:first-of-type {
    background-color: #ff991f;
  }
`;

export const CardList = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 2rem;
  margin-bottom: 3.3125rem;
`;

export const CardLeft = styled.div`
  min-width: 35px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  margin-right: 1.125rem;
`;

export const CardRight = styled.div`
  text-align: right;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  p:first-of-type {
    color: #505f79;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    margin-bottom: 0.3125rem;
  }

  p:last-of-type {
    font-weight: normal;
    font-size: 18px;
    line-height: 16px;
    color: #172b4d;
    margin-bottom: 1.25rem;
    text-align: right;
  }

  button {
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    padding: 0.3125rem 0.75rem;
    background-color: #4c9a2a;
    border-radius: 2px;
    border: none;
    color: #fff;
    cursor: pointer;
  }
`;

export const AdminBottom = styled.div`
  background-color: #fff;
  padding: 2.625rem 2.875rem 10.25rem;

  h4 {
    font-weight: 600;
    font-size: 22px;
    line-height: 18px;
    color: #163327;
  }

  .empty {
    text-align: center;
    margin-top: 10rem;

    p {
      display: inline-block;
    }
  }
`;

export const SearchInputWrapper = styled.div`
  position: relative;
  display: inline-block;
  width: 100%;
  max-width: 350px;
  margin-bottom: 2rem;

  &:before {
    content: "";
    background-image: url("/media/search-xs.svg");
    display: block;
    width: 16px;
    height: 16px;
    position: absolute;
    top: 9px;
    left: 15px;
  }

  input {
    display: block;
    width: 100%;
    height: 34px;
    font-weight: normal;
    font-size: 12px;
    line-height: 18px;
    padding: 8px 130px 8px 42px;
    border-radius: 2px;
    border: 1px solid #1e3c10;
    letter-spacing: 1px;
  }
`;

export const Table = styled.table`
  border: 2px solid #dfe1e6;
  border-collapse: collapse;
  width: 100%;

  td,
  th {
    padding: 0.875rem 1rem;
    font-size: 12px;
    line-height: 16px;
    text-align: left;
  }

  th {
    color: #6b778c;
  }

  td {
    color: #172b4d;
  }
  thead tr {
    border-bottom: 2px solid #dfe1e6;
  }

  a,
  button {
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    padding: 0.3125rem 1.3125rem;
    border-radius: 2px;
    border: none;
    color: #fff;
    cursor: pointer;
  }

  a {
    color: #42526e;
    text-decoration: none;
    border: 1px solid #4c9a2a;
  }

  /* button {
    &:first-of-type {
      margin-right: 1rem;
      background-color: #4c9a2a;
    }

    &:last-of-type {
      background-color: #ffbdad;
      color: #42526e;
    }
  } */
`;

export const DocumentsWrapper = styled.div`
  background-color: #fff;
  padding: 3.125rem 2.875rem 15rem;
  border-radius: 5px;

  h4 {
    font-weight: bold;
    font-size: 22px;
    line-height: 18px;
    color: #163327;
    margin-bottom: 2.6875rem;
  }

  ul {
    margin: 0;
    border: 2px solid #dfe1e6;

    li {
      padding: 1.5625rem 1.875rem;

      > div {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }

      button {
        -webkit-appearance: none;
        border: none;
        text-decoration: underline;
        font-weight: 600;
        font-size: 12px;
        line-height: 16px;
        background-color: transparent;
        padding: 0;
        margin-top: 0.5rem;
        outline: none;
        cursor: pointer;
        color: #f44336;
      }

      div {
        display: flex;
        align-items: center;

        p {
          font-weight: normal;
          font-size: 18px;
          line-height: 18px;
          color: #163327;
          margin-left: 1rem;
        }
      }

      &:not(:last-of-type) {
        border-bottom: 2px solid #dfe1e6;
      }

      a {
        font-weight: 600;
        font-size: 12px;
        line-height: 16px;
        padding: 0.3125rem 0.875rem;
        border-radius: 2px;
        color: #fff;
        cursor: pointer;
        background-color: transparent;
        color: #42526e;
        border: 1px solid #4c9a2a;
        text-decoration: none;
      }
    }
  }
`;

export const DocumentViewTop = styled.div`
  background-color: #fff;
  margin-bottom: 1.625rem;

  .container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 20px;
    padding-bottom: 20px;

    > div {
      display: flex;
      align-items: center;

      p {
        font-weight: 600;
        font-size: 22px;
        line-height: 18px;
        margin-right: 2.8125rem;
        color: #163327;
      }

      button {
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        padding: 0.3125rem 0.875rem;
        border-radius: 3px;
        border: none;
        cursor: pointer;
        margin: 0;

        &.approve {
          color: #fff;
          background-color: #4c9a2a;
          margin-right: 1rem;
          display: inline-flex;
        }

        &.reject {
          background-color: #ffbdad;
          color: #fff;
          color: #42526e;
        }
      }
    }

    a {
      font-weight: bold;
      font-size: 16px;
      line-height: 18px;
      text-decoration: none;
      cursor: pointer;
      display: inline-flex;
      align-items: center;
      color: #1e3c10;

      span {
        margin-left: 0.75rem;
      }
    }
  }
`;

export const RevocationReason = styled.div`
  background-color: #fff;
  width: 100%;
  max-width: 500px;
  margin: 10rem auto 0;
  padding: 1.875rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 3px;
  border-top: 4px solid #4c9a2a;

  h3 {
    margin-bottom: 2.5rem;
  }

  p {
    margin: 0;
    font-weight: normal;
    font-size: 14px;
    line-height: 22px;
    color: #1e3c10;
    text-align: center;
    margin-bottom: 2.5rem;
  }

  button:first-of-type {
    display: block;
    padding: 0.6875rem 2rem;
    background: #4c9a2a;
    border-radius: 2px;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 28px;
    color: #fff6fe;
    border: none;
    margin-right: 1rem;
    background-color: #f25c78;
  }

  textarea {
    width: 100%;
    height: 100px;
    margin-bottom: 2.5rem;
    border: 1px solid #4f4f4f;
    font-size: 16px;
    line-height: 18px;
    color: #393939;
    padding: 0.6875rem;
    border-radius: 0;
    resize: none;
  }

  .button-group {
    display: flex;
  }

  .checkbox-wrapper {
    text-align: left;
    width: 100%;
    margin-bottom: 2.5rem;

    label {
      display: block;
      font-size: 18px;
      line-height: 21px;

      &:not(:last-of-type) {
        margin-bottom: 0.5rem;
      }
    }
  }
`;

export const PageLoaderWrapper = styled.div`
  position: fixed;
  background-color: #fff;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;
