import React, { FC, ReactNode } from "react";
import { useLocation } from "@reach/router";
import { Link } from "gatsby";

import { AuthLayoutWrapper } from "./styles";

type Props = {
  title?: string;
  menu?: { label: string; link: string }[];
  breadcrumbs?: { label: string; link?: string }[];
  children: ReactNode;
  custom?: any;
};

const AuthLayout: FC<Props> = ({ title, custom, children, breadcrumbs = [] }) => {
  const { length, [length - 1]: lastIndex } = breadcrumbs;

  return (
    <AuthLayoutWrapper>
      <div className="container">
        {custom || (
          <div className="mb-4 mr-auto">
            {!!title && (
              <h4 className="page-title d-inline-block pr-3 mr-3 border-right">{title}</h4>
            )}

            {!!breadcrumbs.length && (
              <nav aria-label="breadcrumb" className="d-inline-block ">
                <ol className="breadcrumb p-0">
                  {breadcrumbs.map(({ label, link }, index) => (
                    <li key={index} className="breadcrumb-item">
                      {link ? (
                        <Link
                          to={link}
                          aria-current={lastIndex ? "page" : "false"}
                          className={lastIndex ? "breadcrumb-item active" : "breadcrumb-item"}
                        >
                          {label}
                        </Link>
                      ) : (
                        <span className={lastIndex ? "breadcrumb-item active" : "breadcrumb-item"}>
                          {label}
                        </span>
                      )}
                    </li>
                  ))}
                </ol>
              </nav>
            )}
          </div>
        )}

        <div className="row">{children}</div>
      </div>
    </AuthLayoutWrapper>
  );
};

export default AuthLayout;
