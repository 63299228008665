import React, { useState } from "react";

import { AccountWrapper } from "./styles";
import { DebtSummaryType, DebtType } from "models/app/Debt";
import { CompanyRepTable, DirectorTable } from "components/pages/company/components/index";
import { CompanyBankAccountType, PersonBankAccountType } from "models/app/Services";
import { Pages } from "components/pages/company/types";
import Button from "components/auth/Button";

interface AccountProps {
  debtSummary: DebtSummaryType;
  debts: Array<DebtType>;
  // representatives: Array<RepresentativeType>
  companyId?: string | number;
  bankAccounts: Array<CompanyBankAccountType | PersonBankAccountType>;
  goto: (x: Pages) => void;
}

const Account: React.FC<AccountProps> = ({ debtSummary, debts, companyId, bankAccounts, goto }) => {
  return (
    <AccountWrapper>
      <div className="row my-4">
        <div className="col-6 col-sm-4">
          <h4 className="mb-0">
            {/* {debtSummary.currency} */}
            {debtSummary.amountOwedStr}
          </h4>
          <small className="text-muted text-uppercase">Initial Debt</small>
        </div>

        <div className="col-6 col-sm-4">
          <h4 className="mb-0">
            {/* {debtSummary.currency} */}
            {debtSummary.amountOutstandingStr}
          </h4>
          <small className="text-muted text-uppercase">Outstanding Debt</small>
        </div>

        <div className="col-6 col-sm-4 mt-3 mt-sm-0">
          <h4 className="mb-0">
            {debtSummary.currency}
            {debtSummary.amountPaidStr}
          </h4>
          <small className="text-muted text-uppercase">Amount Paid</small>
        </div>
      </div>

      <div className="mb-5">
        <DirectorTable companyId={companyId} />
      </div>

      <CompanyRepTable companyId={companyId} />

      {/*<div className="d-flex mt-4">*/}
      {/*  <div className="mr-3">*/}
      {/*    <Button label="New Card Payment" onClick={() => goto(Pages.paymentCard)} />*/}
      {/*  </div>*/}

      {/*  <div>*/}
      {/*    <Button label="Direct Debit" onClick={() => goto(Pages.paymentDirectDebit)} />*/}
      {/*  </div>*/}
      {/*</div>*/}
    </AccountWrapper>
  );
};

export default Account;
