import styled from 'styled-components'

export const TextAreaWrapper = styled.div`
  margin-bottom: 1rem;

  label {
    font-weight: 600;
    font-size: 0.875rem;
    display: inline-block;
    margin-bottom: 0.5rem;
    font-family: 'Montserrat', sans-serif;
    color: ${({ theme }) => theme.dark_color};
  }

  .form-inner-wrapper {
    .form-icon-wrapper {
      display: flex;
      color: #495057;
      margin-right: -1px;
      align-items: center;
      justify-items: center;
      border: 1px solid #ced4da;
      padding: 0.375rem 0.75rem;
      border-top-left-radius: 0.25rem;
      border-bottom-left-radius: 0.25rem;
      background: ${({ theme }) => theme.light_color};
    }

    .form-control {
      height: auto;
      line-height: 2.2;
      font-size: 0.8rem;
      border-radius: 0.25rem;
      padding: 0.375rem 0.75rem;
      border: 1px solid #ced4da;
      background-color: ${({ theme }) => theme.white};
      transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

      &.is-invalid {
        border-color: ${({ theme }) => theme.error};
      }
    }
  }

  .invalid-feedback {
    display: block;
  }
`
