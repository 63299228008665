import React from "react";
import { RepresentativeType, UserMixin } from "models/app/Profile";
import { CastJqlType, JqlQueryType } from "models/types/Services";
import { Intents } from "common/enums";
import DataTable from "components/generic/table/DataTable";
import { FaCheck, FaTimes } from "react-icons/fa";
import { httpClient } from "utils/index";
import ActionMenu from "components/generic/menu/ActionMenu";
import { Tag } from "antd";
import If from "components/generic/If";
import { GrUserAdmin, ImBin } from "react-icons/all";
import { AUTHENTICATION_CLASS } from "common/constants";

type _RepresentativeType = RepresentativeType & {
  type: string;
};

const columns = [
  {
    Header: "Name",
  },
  {
    Header: "Email",
  },
  {
    Header: "Phone Number",
  },
  {
    Header: "Status",
  },
  {
    Header: "Admin",
  },
  {
    Header: "Action",
  },
];

const keys = [
  {
    key: "key__menuItem__approve",
    label: "Verify",
    icon: FaCheck,
  },
  {
    key: "key__menuItem__disapprove",
    label: "Un-verify",
    icon: FaTimes,
  },
  {
    key: "key__menuItem__make_admin",
    label: "Make Admin",
    icon: GrUserAdmin,
  },
  {
    key: "key__menuItem__remove_admin",
    label: "Remove as Admin",
    icon: FaTimes,
  },
  {
    key: "key__menuItem__disassociate",
    label: "Disassociate",
    icon: ImBin,
  },
];

export default ({ companyId }) => {
  const [draw, setDraw] = React.useState<number>(0);

  const jql: JqlQueryType<CastJqlType<_RepresentativeType>> = {
    __meta__: {
      authenticationClass: AUTHENTICATION_CLASS,
      namespace: "profile.CompanyRepresentative",
      schema: "model",
      intent: Intents.retrieve,
      filter: {
        company: companyId,
      },
    },
    id: null,
    user: {
      id: null,
      fullname: null,
      email: null,
      phone: null,
    },
    type: "Representative",
    verified: null,
    admin: null,
  };

  const process = React.useCallback(
    (representativeAction) => {
      if (!representativeAction) return;

      httpClient
        .post("/jql", {
          __meta__: {
            authenticationClass: AUTHENTICATION_CLASS,
            namespace: "profile.CompanyRepresentative",
            schema: "controller",
            intent: representativeAction.action,
          },
          representative: representativeAction.representativeId,
        })
        .then(() => {
          setDraw(draw + 1);
        });
    },
    [draw],
  );

  // construct menu
  function handleMenuClick(e, id) {
    switch (e.key) {
      case "key__menuItem__approve":
        process({ representativeId: id, action: "verify" });
        break;
      case "key__menuItem__disapprove":
        process({ representativeId: id, action: "unverify" });
        break;
      case "key__menuItem__make_admin":
        process({ representativeId: id, action: "make_admin" });
        break;
      case "key__menuItem__remove_admin":
        process({ representativeId: id, action: "remove_admin" });
        break;
      case "key__menuItem__disassociate":
        process({ representativeId: id, action: "disassociate" });
        break;
      default:
        break;
    }
  }

  // table row component
  const CompanyRepTableRow = ({ row }: { row: _RepresentativeType }) => {
    return (
      <tr>
        <td>{row.user?.fullname}</td>
        <td>{row.user?.email}</td>
        <td>{row.user?.phone}</td>
        <td>
          {
            <If is={row.verified} else={<Tag color="volcano">Not Verified</Tag>}>
              <Tag color="green">Verified</Tag>
            </If>
          }
        </td>
        <td>
          {
            <If is={row.admin} else={<Tag color="purple">Not Admin</Tag>}>
              <Tag color="purple">Admin</Tag>
            </If>
          }
        </td>
        <td>
          <ActionMenu
            id={row.id}
            buttonLabel={"Manage Rep"}
            keys={keys}
            clickHandler={handleMenuClick}
          />
        </td>
      </tr>
    );
  };

  // render
  return (
    <DataTable
      columns={columns}
      jqlQuery={jql}
      title={"Company Representative"}
      description={"Manage Your Company Accounts"}
      RowComponent={CompanyRepTableRow}
      draw={draw}
    />
  );
};
