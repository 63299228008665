import React, { FC, ReactNode } from "react";

import Card from "components/auth/Card";
import AuthLayout from "components/auth/AuthLayout";

type Props = {
  title?: string;
  className?: string;
  breadcrumbs?: { label: string; link?: string }[];
  children: ReactNode;
};

const defaultBreadcrumbs = [{ label: "Home", link: "/app/welcome" }, { label: "Admin" }];
const menu = [
  { label: "Users", link: "/app/admin/users" },
  { label: "Companies", link: "/app/admin/companies" },
  { label: "MDAs", link: "/app/admin/mdas" },
  { label: "Debts", link: "/app/admin/debts" },
  { label: "Payments", link: "/app/admin/payments" },
];

const AdminWrapper: FC<Props> = ({
  children,
  title = "Admin Section",
  className = "col-12",
  breadcrumbs = defaultBreadcrumbs,
}) => {
  return (
    <AuthLayout title={title} menu={menu} breadcrumbs={breadcrumbs}>
      <div className={className}>
        <Card>{children}</Card>
      </div>
    </AuthLayout>
  );
};

export default AdminWrapper;

// import React, { useEffect } from 'react'
// import { AdminWrapper } from 'components/pages/admin/styles'
// import { AppContent } from 'components/app/styles'
// import PortalTab from 'components/pages/admin/component/PortalTab'
// import { navigate } from 'gatsby-link'
// import { Pages, PortalTabProps } from 'components/pages/admin/types'
// import { parseUrlQuery } from 'utils/lib'
// import { useLocation, useNavigate } from '@reach/router'
// import TaxInformation from 'components/pages/company/TaxInformation'
// import Switch from 'components/Switch'
// import Companies from './companies'
// import People from './people'
// import Legacy from './Legacy'
// import Verification from './companies/verification'
// import { Box } from 'components/app/styles/generic'
// import { THEME } from "common/theme";
// import { H4 } from 'components/app/styles/typography'
// import AdminVerification from 'components/pages/admin/people/AdminVerification'

// type GotoFuncType = (x: string, options?: Object | null, keepOptions?: boolean) => void

// export default () => {
//   const [activeTab, setActiveTab] = React.useState<PortalTabProps['activeTab'] | string>(
//     Pages.companies,
//   )

//   const urlQuery = parseUrlQuery(useLocation().search)
//   const urlPage = urlQuery?.p
//   const navigate = useNavigate()

//   const goto: GotoFuncType = (urlPage, options = {}, keepOptions = false) => {
//     let formattedOptions = ''

//     if (keepOptions) {
//       options = {
//         p: urlPage,
//         ...urlQuery,
//         ...options,
//       }
//     } else {
//       options = {
//         p: urlPage,
//         ...options,
//       }
//     }

//     const queryStrings = []
//     // @ts-ignore
//     Object.keys(options || {}).forEach((k) => queryStrings.push(`${k}=${options[k]}`))
//     formattedOptions = queryStrings.join('&')

//     navigate(`/app/admin?${formattedOptions}`)
//   }

//   useEffect(() => {
//     setActiveTab(urlPage as Pages)
//   }, [urlQuery])

//   return <div>sdsds</div>

//   return (
//     <AdminWrapper>
//       <div className={'container'}>
//         <Box marginBottom={THEME.sizes.lg} backgroundColour={THEME.colors.white}>
//           <Box padding={THEME.sizes.lg}><H4>Admin Section</H4></Box>
//         </Box>
//         <AppContent>
//           <PortalTab activeTab={activeTab} goto={goto} />
//           <Switch condition={activeTab === Pages.companies}>
//             <Companies goto={goto} />
//           </Switch>
//           <Switch condition={activeTab === Pages.people}>
//             <People goto={goto} />
//           </Switch>
//           <Switch condition={activeTab === Pages.documents}>
//             <TaxInformation />
//           </Switch>
//           <Switch condition={activeTab === Pages.temp}>
//             <Legacy />
//           </Switch>

//           {/* Auxiliary */}
//           <Switch condition={activeTab === "companyDocumentVerification"}>
//             <Verification goto={goto} />
//           </Switch>
//           <Switch condition={activeTab === "companyAdminVerification"}>
//             <AdminVerification goto={goto} />
//           </Switch>
//         </AppContent>
//       </div>
//     </AdminWrapper>
//   )
// }
