import toast from "react-hot-toast";

import { UserTypes } from "common/enums";
import httpClient, { makeRequest } from "./httpClient";
import { AUTHENTICATION_CLASS } from "common/constants";

interface RegisterPayload {
  email: string;
  phone: string;
  first_name: string;
  last_name: string;
  user_type: string;
  nin: string;
}

interface LoginPayload {
  username: string;
  password: string;
}

export interface ICurrentUser {
  email: string;
  firstName: string;
  lastName: string;
  phone: string;
  phoneCountryCode: number;
  id: number;
  userType: UserTypes;
  token: {
    access: string;
    refresh: string;
  };
}
const LS: {
  getItem: (key: string) => string | null;
  setItem: (key: string, value: string) => void;
  removeItem: (key: string) => void;
} =
  typeof window !== "undefined"
    ? window.localStorage
    : {
        getItem: (key: string) => {
          return null;
        },
        setItem: (key: string, value: string): void => {},
        removeItem: (key: string): void => {},
      };

class Auth {
  isLoggedIn: boolean = LS.getItem("isLoggedIn") === "true";
  currentUser: ICurrentUser | null = LS.getItem("user") && JSON.parse(LS.getItem("user") || "");

  async login(payload: LoginPayload) {
    try {
      const [data, , error]: any = await makeRequest({
        path: "/jql",
        auth: false,
        method: "POST",
        config: {
          data: {
            __meta__: {
              authenticationClass: AUTHENTICATION_CLASS,
              namespace: "system.User",
              schema: "controller",
              intent: "authenticate",
              return: {
                email: null,
                first_name: null,
                last_name: null,
                user_type: null,
                phone: null,
                phone_country_code: null,
                id: null,
              },
            },
            username: payload.username,
            password: payload.password,
          },
        },
      });

      if (error && error.errors && Array.isArray(error.errors)) {
        error.errors.map(({ error_message }) => {
          toast.error(error_message);
        });
        return;
      }

      this.isLoggedIn = true;
      this.currentUser = data.data;

      LS.setItem("isLoggedIn", "true");
      LS.setItem("user", JSON.stringify(data.data));
      LS.setItem("accessToken", data.data.token.access);
    } catch (err) {
      // toast.error(err.message);
    }
  }

  register(payload: RegisterPayload) {
    return httpClient.post("/jql", {
      __meta__: {
        authenticationClass: AUTHENTICATION_CLASS,
        namespace: "system.Registration",
        schema: "controller",
        intent: "register",
      },
      ...payload,
      username: payload["email"],
    });
  }

  async logout() {
    LS.removeItem("isLoggedIn");
    LS.removeItem("user");

    // await httpClient.post('/jql', {
    //   __meta__: {
    //     namespace: 'system.User',
    //     schema: 'controller',
    //     intent: 'logout',
    //   }
    // })
  }

  isAuthenticated() {
    return this.isLoggedIn;
  }
}

export default new Auth();
