import React, { FC, useState, useContext, useEffect } from "react";
import { Link } from "gatsby";
import styled from "styled-components";
import { RouteComponentProps } from "@reach/router";
import { WelcomeWrapper } from "components/pages/welcome/styles";
import { AuthContext } from "contexts";
import Card from "components/auth/Card";
import AuthLayout from "components/auth/AuthLayout";
import { CompanyRepresentativeType } from "models/app/Profile";
import useJql from "hooks/useJql";
import { AUTHENTICATION_CLASS } from "common/constants";
import AssetsCategorization from "components/charts/AssetsCategorization";
import MDAsChart from "components/charts/MDAs";
import TopDebtors from "components/charts/TopDeptors";
import DebtRecorded from "components/charts/DebtRecorded";
import MetricsList from "components/charts/MetricsList";
import DebtAnalysis from "components/charts/DebtAnalysis";

const jql = {
  __rootMeta__: {
    authenticationClass: AUTHENTICATION_CLASS,
  },
  debtAmountAnalysisBin: {
    __meta__: {
      namespace: "debt.Debt",
      schema: "model",
      intent: "aggregate",
      authenticationClass: AUTHENTICATION_CLASS,
      bins: {
        field: "amount_outstanding",
        function: "sum",
        num_bins: 5,
        bins: [
          [0, 50000000],
          [50000000, 100000000],
          [100000000, 500000000],
          [500000000, 1000000000],
          [1000000000, 10000000000000],
        ],
      },
    },
  },
  debtCountAnalysisBin: {
    __meta__: {
      namespace: "debt.Debt",
      schema: "model",
      intent: "aggregate",
      authenticationClass: AUTHENTICATION_CLASS,
      bins: {
        field: "amount_outstanding",
        function: "count",
        num_bins: 5,
        bins: [
          [0, 50000000],
          [50000000, 100000000],
          [100000000, 500000000],
          [500000000, 1000000000],
          [1000000000, 10000000000000],
        ],
      },
    },
  },
};

const debtSizes = {
  "sum.0.50000000": "Less than 50 Million",
  "sum.50000000.100000000": "50 Million to 100 Million",
  "sum.100000000.500000000": "100 Million to 500 Million",
  "sum.500000000.1000000000": "500 Million to 1 Billion",
  "sum.1000000000.10000000000000": "1 Billion and Above",
};

const Welcome: FC<RouteComponentProps> = () => {
  const [companyRepresentatives, setCompanyRepresentatives] = useState<
    Array<CompanyRepresentativeType>
  >([]);
  const { currentUser } = useContext(AuthContext);

  const { data, isLoading } = useJql({
    jqlQuery: {
      __meta__: {
        authenticationClass: AUTHENTICATION_CLASS,
        namespace: "system.User",
        schema: "model",
        intent: "retrieveOne",
        uniqueObject: {
          id: currentUser?.id,
        },
      },
      companies: {
        admin: null,
        verified: null,
        company: {
          id: null,
          name: null,
          verified: null,
        },
      },
    },
  });

  const { data: debt } = useJql({
    jqlQuery: jql,
  });
  const { debtAmountAnalysisBin: amount, debtCountAnalysisBin: count } = debt || {};

  const analysis = Object.keys(amount?.data || {})
    ?.reverse()
    ?.map((key) => {
      return {
        id: key,
        size: debtSizes[key],
        amount: amount.data[key],
        count: count.data[key],
      };
    });

  useEffect(() => {
    data && setCompanyRepresentatives(data?.companies);
  }, [data]);

  return (
    <AuthLayout title="Dashboard">
      <div className="col-12 mb-4 mb-lg-0">
        {/* {false && } */}
        {/* "MDA_USER" */}
        {["SUPER_ADMIN", "ADMIN", "MDA_ADMIN"].includes(currentUser?.userType || "") ? (
          <div className="mt-4">
            <MetricsList />
            <Div>
              <DebtRecorded />
              <TopDebtors />
              <DebtAnalysis data={analysis} />
              <AssetsCategorization data={analysis} />
              <MDAsChart />
            </Div>
          </div>
        ) : (
          <Card title={`Welcome back ${currentUser?.firstName} ${currentUser?.lastName}!`}>
            <WelcomeWrapper className="row">
              <div className="col-12">
                <p className="text-muted mb-5">
                  This platform is designed to help the Federal Ministry of Finance, Budget and
                  National Planning report all debtors, keep track of debts and also give debtors
                  access to pay up.
                </p>

                {!!companyRepresentatives.length && (
                  <table className="table table-striped">
                    <thead>
                      <tr>
                        <th scope="col">#</th>
                        <th scope="col">Affiliated Company</th>
                        <th scope="col">Verification Status</th>
                      </tr>
                    </thead>

                    <tbody>
                      {companyRepresentatives.map(({ verified, company }, i) => {
                        const { id, name } = company;

                        return (
                          <tr key={id}>
                            <th scope="row">{i + 1}</th>
                            {/*<td>{verified ? <Link to={`/app/company/${id}`}>{name}</Link> : name}</td>*/}
                            <td>
                              <Link to={`/app/company/${id}`}>{name}</Link>
                            </td>
                            <td>{verified ? "Verified" : "Pending Verification"}</td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                )}
              </div>
            </WelcomeWrapper>
          </Card>
        )}
      </div>
    </AuthLayout>
  );
};

const Div = styled.div`
  display: grid;
  grid-gap: 20px;
  @media screen and (min-width: 992px) {
    grid-template-columns: repeat(6, 1fr);
    & > div {
      grid-column: span 3;
      border-radius: 0.3125rem;
      box-shadow: 0 1px 10px 1px rgba(0, 0, 0, 0.05);
      background-color: #fff;
      :first-of-type {
        grid-column: span 4;
      }
      :nth-of-type(2) {
        grid-column: span 2;
      }
      :last-of-type {
        grid-column: span 6;
      }
    }
  }
`;

export default Welcome;
