export const ColumnHeader = [
  {
    Header: "Tax Identification Number",
  },
  {
    Header: "Company Name",
  },
  {
    Header: "Status",
  },
  {
    Header: "Actions",
  },
];
