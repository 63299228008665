import React from "react";
import { Link } from "gatsby";

import AdminWrapper from "../index";
import { ColumnHeader } from "./constants";
import { Intents } from "common/enums";
import { DebtTransactionLogType } from "models/app/Debt";
import { CastJqlType, JqlQueryType } from "models/types/Services";
import DataTable from "components/generic/table/DataTable";
import { Caption } from "components/app/styles/typography";
import { Tag } from "antd";
import { CheckCircleOutlined, CloseCircleOutlined } from "@ant-design/icons";
import { AUTHENTICATION_CLASS } from "common/constants";

const PaymentStatus = ({ status }: { status: string }) => {
  return status === "success" ? (
    <Tag icon={<CheckCircleOutlined />} color="success">
      {status}
    </Tag>
  ) : (
    <Tag icon={<CloseCircleOutlined />} color="error">
      {status}
    </Tag>
  );
};

export default () => {
  const jql: JqlQueryType<CastJqlType<DebtTransactionLogType>> = {
    __meta__: {
      authenticationClass: AUTHENTICATION_CLASS,
      namespace: "debt.PaymentTransactionLog",
      schema: "model",
      intent: Intents.retrieve,
      filter: {
        completed: true,
      },
    },
    id: null,
    debt: {
      id: null,
      debtType: {
        id: null,
        name: null,
        mda: {
          id: null,
          name: null,
        },
      },
      company: {
        id: null,
        name: null,
      },
    },
    reference: null,
    paymentDate: null,
    status: null,
    "amount({function: to_locale})": null,
    // "currency({function: get_display_name})": null,
    currency: null,
  };

  const TableRow = ({ row }) => {
    return (
      <tr>
        <td>
          <strong>{row.reference}</strong>
        </td>
        <td>{row.debt.company.name}</td>
        <td>
          {row.debt.debtType.name}
          <br />
          <Caption italic>{row.debt.debtType.mda.name}</Caption>
        </td>
        <td align={"right"}>
          {row.currency?.toUpperCase()}
          {row.amount}
        </td>
        <td>{row.paymentDate}</td>
        <td>
          <PaymentStatus status={row.status} />
        </td>
      </tr>
    );
  };

  return (
    <AdminWrapper>
      <DataTable
        columns={ColumnHeader}
        jqlQuery={jql}
        title={"List of Payments"}
        description={<Link to="/app/admin/payments/add">Add Payment</Link>}
        enableSearch
        searchPlaceholder={"search for payment"}
        RowComponent={TableRow}
        debug={false}
      />
    </AdminWrapper>
  );
};
