import React from 'react'

import { Company } from './index'
import Button from 'components/auth/Button'
import { ConfirmAssociation } from './styles'
import { ModalWrapper } from '../../app/styles'

type HasAdminModalProps = {
  closeModal: () => void
  company: Company
  navigate?: (url: string) => void
}
const HasAdminModal: React.FC<HasAdminModalProps> = ({ closeModal, navigate, company }) => {
  const admin = company.representatives.find(({ admin }) => admin)

  const handleClick = () => {
    closeModal()
    navigate && navigate('/app/welcome')
  }

  return (
    <ModalWrapper>
      <ConfirmAssociation>
        <p>
          <b>{admin?.user.fullname}</b> is the admin representative of <b>{company.name}</b>, they
          will handle your request shortly.
        </p>
        <Button label="OK" onClick={handleClick} />
      </ConfirmAssociation>
    </ModalWrapper>
  )
}

export default HasAdminModal
