export const ColumnHeader = [
  {
    Header: 'Code',
  },
  {
    Header: 'Name',
  },
  {
    Header: 'Abbr',
  },
  {
    Header: 'Actions',
  },
]

export const DebtorsColumnHeader = [
  {
    Header: 'Debt',
  },
  {
    Header: 'Company',
  },
  {
    Header: 'Amount Outstanding',
  },
  {
    Header: 'Actions',
  },
]
