import React from "react";
import { DirectorType } from "models/app/Profile";
import { CastJqlType, JqlQueryType } from "models/types/Services";
import { Intents } from "common/enums";
import DataTable from "components/generic/table/DataTable";
import { AUTHENTICATION_CLASS } from "common/constants";

export interface _DirectorType extends DirectorType {
  type: string;
}

const columns = [
  {
    Header: "Name",
    accessor: "fullname",
  },
  {
    Header: "Email",
    accessor: "email",
  },
  {
    Header: "Phone Number",
    accessor: "phone",
  },
  {
    Header: "Type",
    accessor: "type",
  },
];

export default ({ companyId }) => {
  const jql: JqlQueryType<CastJqlType<_DirectorType>> = {
    __meta__: {
      authenticationClass: AUTHENTICATION_CLASS,
      namespace: "profile.Company",
      // query_field: "directors",
      schema: "model",
      intent: Intents.retrieve,
      uniqueObject: {
        id: companyId,
      },
    },
    id: null,
    fullname: null,
    email: null,
    phone: null,
    type: "Director",
  };

  return (
    <DataTable
      columns={columns}
      jqlQuery={jql}
      title={"Company Details"}
      description={"Company Directors and Major Shareholders"}
    />
  );
};
