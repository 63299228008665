import React, { Dispatch, SetStateAction } from "react";
import TextField from "components/auth/TextField";
import { Form, Formik } from "formik";
import toast from "react-hot-toast";
import { object, string, ref } from "yup";
import Button from "components/auth/Button";
import { AUTHENTICATION_CLASS, Schemas } from "common/constants";
import { httpClient } from "utils";

const validationSchema = object({
  //   oldPassword: string().required("Current password is required"),
  newPassword: string().required("New password is required"),
  confirmNewPassword: string()
    .oneOf([ref("newPassword"), null], "Passwords must match")
    .required("Passwords confirmation is required"),
});

const jqlQuery = (data: any) => ({
  __meta__: {
    namespace: "system.User",
    intent: "statelessChangePassword",
    schema: Schemas.controller,
    authenticationClass: AUTHENTICATION_CLASS,
  },
  ...data,
});

function ResetNewUserPassword({
  user,
  setResetPassword,
}: {
  user: { user: string; oldPassword: string } | undefined;
  setResetPassword: Dispatch<SetStateAction<{ user: string; oldPassword: string } | undefined>>;
}) {
  const handleSubmit = async (data: any, { resetForm }) => {
    try {
      const res = await httpClient.post("/jql", jqlQuery({ ...data, ...user }));
      resetForm();
      toast.success("Password changed successfully. Please login with you new credentials.");
      setResetPassword("");
    } catch (error) {
      toast.error(error?.response?.data?.errors?.[0]?.error_message || "Failed to change password");
    }
  };

  return (
    <div>
      {" "}
      <Formik
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
        initialValues={{
          oldPassword: "",
          newPassword: "",
          confirmNewPassword: "",
        }}
      >
        {({ isSubmitting }) => {
          return (
            <Form>
              {/* <div className="mb-4">
                <TextField
                  label="Old Password"
                  name="oldPassword"
                  type="password"
                  placeholder="password"
                />
              </div> */}
              <div className="mb-4">
                <TextField
                  label="New Password"
                  name="newPassword"
                  type="password"
                  placeholder="password"
                />
              </div>
              <div className="mb-4">
                <TextField
                  label="Confirm New Password"
                  name="confirmNewPassword"
                  type="password"
                  placeholder="password"
                />
              </div>
              <div>
                <Button
                  label="Change Password"
                  type="submit"
                  loading={isSubmitting}
                  disabled={isSubmitting}
                />
              </div>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
}

export default ResetNewUserPassword;
