import React, { FC } from "react";
import toast from "react-hot-toast";
import { navigate } from "@reach/router";

import MDAForm from "./MDAForm";
import Card from "components/auth/Card";
import { makeRequest } from "utils/httpClient";
import AuthLayout from "components/auth/AuthLayout";
import { Intents } from "common/enums";
import { AUTHENTICATION_CLASS } from "common/constants";

const breadcrumbs = [
  { label: "Home", link: "/app/welcome" },
  { label: "MDAs", link: "/app/admin/mdas" },
  { label: "Add MDA" },
];

const AddMDA: FC = () => {
  const handleSubmit = async (payload) => {
    const [data, , error] = await makeRequest({
      path: "/jql",
      method: "POST",
      config: {
        data: {
          __meta__: {
            authenticationClass: AUTHENTICATION_CLASS,
            namespace: "system.MDA",
            schema: "model",
            intent: Intents.create,
          },
          ...payload,
        },
      },
    });

    if (error && error.errors && Array.isArray(error.errors)) {
      error.errors.map(({ error_message }) => {
        return toast.error(error_message);
      });
    } else {
      toast.success(data.message);
      navigate("/app/admin/mdas");
    }
  };

  return (
    <AuthLayout title="Add MDA" breadcrumbs={breadcrumbs}>
      <div className="col-12 col-lg-8">
        <Card>
          <MDAForm handleSubmit={handleSubmit} />
        </Card>
      </div>
    </AuthLayout>
  );
};

export default AddMDA;
