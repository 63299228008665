import React, { FC, useCallback, useEffect, useState } from "react";
import { RouteComponentProps } from "@reach/router";
import { Formik, Form } from "formik";
import _ from "lodash";

import AdminWrapper from "../../index";
import Tabs from "components/auth/Tabs";
import { httpClient } from "utils/index";
import Button from "components/auth/Button";
import TextArea from "components/auth/TextArea";
import { Intents } from "common/enums";
import InfoSection from "components/auth/InfoSection";
import { CompanyDocumentType } from "models/app/Profile";
import { DocumentKeys, Tabs as TabsEnum } from "./constants";
import DocumentViewer from "components/generic/document-viewer";
import { AUTHENTICATION_CLASS } from "common/constants";

const keys = [
  {
    key: TabsEnum.certificateVerification,
    label: "Certificate of Incorporation",
  },
  {
    key: TabsEnum.cac2Verification,
    label: "Form CAC 2",
  },
  {
    key: TabsEnum.cac7Verification,
    label: "Form CAC 7",
  },
  {
    key: TabsEnum.directorConsentVerification,
    label: "Director Consent",
  },
];

const breadcrumbs = [
  { label: "Home", link: "/app/welcome" },
  { label: "Companies", link: "/app/admin/companies" },
  { label: "Verify Company" },
];

const Verification: FC<RouteComponentProps<{ id: string }>> = ({ id }) => {
  const [_documents, setDocuments] = useState<Partial<Record<DocumentKeys, CompanyDocumentType>>>();
  const [action, setAction] = useState<
    "approval" | "approvalConfirmed" | "rejection" | "rejectionConfirmed" | undefined
  >();

  // get document information
  useEffect(() => {
    httpClient
      .post("/jql", {
        __meta__: {
          authenticationClass: AUTHENTICATION_CLASS,
          namespace: "profile.CompanyDocument",
          schema: "model",
          intent: Intents.retrieve,
          filter: {
            company: id,
          },
        },
        id: null,
        name: null,
        file: null,
        comment: null,
        status: null,
      })
      .then((response) => {
        const documents = response.data.data;
        const documentBuffer = {};

        for (const key of keys) {
          const index = _.findIndex(documents, { name: key.key });
          if (index === -1) continue;
          documentBuffer[documents[index].name] = documents[index];
        }

        setDocuments(documentBuffer);
      });
  }, [id]);

  // make a call to the backend to submit rejection note
  const processStatus = useCallback(
    (formValues, status, docType) => {
      let data = {};

      if (status === "approvalConfirmed") {
        data = {
          status: "approved",
          comment: "",
        };
      } else {
        data = {
          status: "rejected",
          comment: formValues.comment,
        };
      }

      httpClient
        .post("/jql", {
          __meta__: {
            authenticationClass: AUTHENTICATION_CLASS,
            namespace: "profile.CompanyDocument",
            schema: "model",
            intent: Intents.update,
            uniqueObject: {
              id: _documents?.[docType]?.id,
            },
          },
          ...data,
        })
        .then((response) => {
          // console.log("Response", response.data.data);
        });
    },
    [_documents],
  );

  const RejectionForm: FC<{ docType: TabsEnum }> = ({ docType }) => {
    return (
      <div style={{ maxWidth: 450, margin: "0 auto 0.5rem" }}>
        <Formik
          initialValues={{ comment: "" }}
          onSubmit={(e, formikHelpers) => {
            setAction("rejectionConfirmed");
            processStatus(e, _documents, docType);
            formikHelpers.setSubmitting(false);
          }}
        >
          {({ isSubmitting }) => {
            return (
              <Form>
                <InfoSection>
                  <TextArea name="comment" label="Comment" />
                </InfoSection>

                <div className="d-flex justify-content-center w-100 mt-2">
                  <div className="mr-3">
                    <Button
                      type="submit"
                      label="Reject Document"
                      loading={isSubmitting}
                      disabled={isSubmitting}
                    />
                  </div>

                  <Button
                    type="button"
                    label="Clear Note"
                    disabled={isSubmitting}
                    onClick={() => {
                      setAction(undefined);
                    }}
                  />
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>
    );
  };

  // fragments
  const ApprovalFragment: FC<{ docType: TabsEnum }> = ({ docType }) => {
    return (
      <div>
        {["rejection", "rejectionConfirmed"].indexOf(action || "") > -1 ? (
          <RejectionForm docType={docType} />
        ) : (
          <div className="mb-2 d-flex justify-content-center">
            <div className="mr-3">
              <Button
                label="Approve Document"
                onClick={() => {
                  processStatus({}, "approvalConfirmed", docType);
                }}
                disabled={["rejection", "rejectionConfirmed"].indexOf(action || "") > -1}
              />
            </div>

            <div>
              <Button
                label="Reject with Note"
                onClick={() => {
                  setAction("rejectionConfirmed");
                }}
              />
            </div>
          </div>
        )}
      </div>
    );
  };

  // render
  return (
    <AdminWrapper title="Verify Company" breadcrumbs={breadcrumbs}>
      <Tabs>
        <div label="Certificate of Incorporation">
          {!!_documents?.[TabsEnum.certificateVerification]?.id && (
            <ApprovalFragment docType={TabsEnum.certificateVerification} />
          )}
          <DocumentViewer fileUrl={_documents?.[TabsEnum.certificateVerification]?.file} />
        </div>

        <div label="Form CAC 2">
          {!!_documents?.[TabsEnum.cac2Verification]?.id && (
            <ApprovalFragment docType={TabsEnum.cac2Verification} />
          )}
          <DocumentViewer fileUrl={_documents?.[TabsEnum.cac2Verification]?.file} />
        </div>

        <div label="Form CAC 7">
          {!!_documents?.[TabsEnum.cac7Verification]?.id && (
            <ApprovalFragment docType={TabsEnum.cac7Verification} />
          )}
          <DocumentViewer fileUrl={_documents?.[TabsEnum.cac7Verification]?.file} />
        </div>

        <div label="Director Consent">
          {!!_documents?.[TabsEnum.directorConsentVerification]?.id && (
            <ApprovalFragment docType={TabsEnum.directorConsentVerification} />
          )}
          <DocumentViewer fileUrl={_documents?.[TabsEnum.directorConsentVerification]?.file} />
        </div>
      </Tabs>
    </AdminWrapper>
  );
};

export default Verification;
