import styled from "styled-components";

export const TabsWrapper = styled.div`
  .tab-nav-wrapper {
    display: flex;
    padding-left: 0;
    overflow-x: auto;
    list-style: none;
    margin-bottom: 1.5rem;

    &::-webkit-scrollbar {
      display: none;
    }

    li {
      display: block;
      cursor: pointer;
      padding: 0.5rem 1rem;
      border-radius: 0.25rem;
      min-width: fit-content;
      color: ${({ theme }) => theme.black_light};

      &:hover {
        color: ${({ theme }) => theme.primary_color};
      }

      &.active {
        color: ${({ theme }) => theme.white};
        background-color: ${({ theme }) => theme.primary_color};
      }
    }
  }
`;
