import React, { FC } from 'react'
import { object, string } from 'yup'
import { Formik, Form, FormikHelpers } from 'formik'

import Button from 'components/auth/Button'
import TextField from 'components/auth/TextField'

const defaultInitialVals = {
  code: '',
  name: '',
  abbr: '',
}

const validationSchema = object({
  code: string().required(),
  name: string().required(),
  abbr: string().required(),
})

type MDAFormData = {
  code: string
  name: string
  abbr: string
}

type Props = {
  initialValues?: MDAFormData
  handleSubmit: (val: MDAFormData) => Promise<void>
}

const MDAForm: FC<Props> = ({ handleSubmit, initialValues = defaultInitialVals }) => {
  const onSubmit = async (values: MDAFormData, actions: FormikHelpers<MDAFormData>) => {
    await handleSubmit(values)
    actions.setSubmitting(false)
  }

  return (
    <Formik
      enableReinitialize
      onSubmit={onSubmit}
      initialValues={initialValues}
      validationSchema={validationSchema}
    >
      {({ isSubmitting }) => (
        <Form>
          <div className="row">
            <div className="col-md-12">
              <TextField name="code" label="Code" />
            </div>

            <div className="col-md-12">
              <TextField name="abbr" label="Abbreviation" />
            </div>

            <div className="col-md-12">
              <TextField name="name" label="MDA Name" />
            </div>
          </div>

          <div className="text-left mt-2">
            <Button type="submit" label="SUBMIT" loading={isSubmitting} disabled={isSubmitting} />
          </div>
        </Form>
      )}
    </Formik>
  )
}

export default MDAForm
