import React from "react";
import { Redirect } from "@reach/router";
import { useLocalObservable } from "mobx-react";
import AuthenticationStore from "stores/AuthenticationStore";

const PrivateRoute = ({ as: Comp, ...props }) => {
  const authenticationStore = useLocalObservable(() => AuthenticationStore);

  // console.log("authenticationStore.isLoggedIn", authenticationStore.isLoggedIn);

  return authenticationStore.isLoggedIn ? (
    <Comp {...props} />
  ) : (
    <Redirect
      from={location.pathname}
      to="/app/auth/login"
      state={{ from: props.location.pathname }}
      noThrow
    />
  );
};

export default PrivateRoute;
