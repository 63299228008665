
import React from "react";

interface defaultType {
    is: boolean;
    children: any;
    else?: any;
}

const If: React.FC<defaultType> = ({ is, children, else: or }) => {
    if (is) {
        return children;
    } else {
        return or || null;
    }
};

export default If;
