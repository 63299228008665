import React from 'react'
import { Formik, Field } from 'formik'

import {
  ModalWrapper,
  GenericForm,
  CloseModalBtn,
  FormRow,
  FormInputLabel,
  ActionButton,
} from 'components/app/styles'
import {DebtSummaryType, DebtType} from 'models/app/Debt'
import { httpClient } from 'utils/index'
import {CompanyBankAccountType, PersonBankAccountType} from "models/app/Services";
import {useParams} from "@reach/router";
import { AUTHENTICATION_CLASS } from 'common/constants'

type IProps = {
  debts: Array<DebtType>
  closeModal: () => void
  setDebtSummary: (debtSummary: DebtSummaryType) => void
  bankAccounts: Array<CompanyBankAccountType | PersonBankAccountType>
}

type DebtAmountType = {
  debt_type: number | ''
  amount: number | ''
}

const NewPaymentModal = ({ closeModal, debts, setDebtSummary, bankAccounts }: IProps) => {
  const [, setLoading] = React.useState(false)
  const { companyId } = useParams()

  const handlePayment = (value: DebtAmountType) => {
    ;(async () => {
      setLoading(true)
      const res = await httpClient.post('/jql', {
        __meta__: {
          authenticationClass: AUTHENTICATION_CLASS,
          namespace: 'debt.Payment',
          schema: 'controller',
          intent: 'payment_intent',
        },
        company: companyId,
        ...value,
      })
      setLoading(false)
      closeModal()

      setDebtSummary(res.data.data)
    })()
  }

  return (
    <ModalWrapper>
      <Formik
        initialValues={{
          debt_type: '',
          account: '',
          amount: '',
        }}
        onSubmit={(values, { setSubmitting }) => {
          handlePayment(values as DebtAmountType)
        }}
      >
        {({ handleSubmit }) => {
          return (
            <GenericForm onSubmit={handleSubmit}>
              <CloseModalBtn onClick={closeModal}>
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none">
                  <path
                    d="M18 6L6 18M6 6l12 12"
                    stroke="#C20303"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
                Close
              </CloseModalBtn>

              <p>Make a Payment</p>

              <FormRow>
                <FormInputLabel>
                  Debt Type
                  <Field component="select" name="debt">
                    <option value="">Select debt type</option>
                    {debts && debts.map((v: DebtType) => (
                      <option value={v.id}>{v.debt_description}</option>
                    ))}
                  </Field>
                </FormInputLabel>
              </FormRow>

              <FormRow>
                <FormInputLabel>
                  Select Account to Debit
                  <Field component="select" name="account">
                    <option value="">Select an account</option>
                    {bankAccounts && bankAccounts.map((account: PersonBankAccountType | CompanyBankAccountType) => (
                        <option value={account.id}>{account.__str__}</option>
                    ))}
                  </Field>
                </FormInputLabel>
              </FormRow>

              <FormRow>
                <FormInputLabel>
                  Amount to be paid
                  <Field type="number" name="amount" className="amount" />
                </FormInputLabel>
              </FormRow>
              <div style={{ marginTop: '3.125rem' }}>
                <ActionButton type="submit" className="save">
                  Proceed
                </ActionButton>
              </div>
            </GenericForm>
          )
        }}
      </Formik>
    </ModalWrapper>
  )
}

export default NewPaymentModal
