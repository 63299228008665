import React, { FC } from "react";
import toast from "react-hot-toast";
import { navigate } from "@reach/router";

import UserForm from "./UserForm";
import AdminWrapper from "../index";
import { makeRequest } from "utils/httpClient";
import { Intents } from "common/enums";
import { AUTHENTICATION_CLASS } from "common/constants";

const breadcrumbs = [
  { label: "Home", link: "/app/welcome" },
  { label: "Users", link: "/app/admin/users" },
  { label: "Add User" },
];

const AddUser: FC = () => {
  const handleSubmit = async (payload) => {
    const [data, , error] = await makeRequest({
      path: "/jql",
      method: "POST",
      config: {
        data: {
          __meta__: {
            authenticationClass: AUTHENTICATION_CLASS,
            namespace: "system.User",
            schema: "controller",
            intent: "create_user",
          },
          ...payload,
        },
      },
    });

    if (error && error.errors && Array.isArray(error.errors)) {
      error.errors.map(({ error_message }) => {
        return toast.error(error_message);
      });
    } else {
      toast.success(data.message);
      navigate("/app/admin/users");
    }
  };

  return (
    <AdminWrapper title="Add User" breadcrumbs={breadcrumbs} className="col-12 col-lg-8">
      <UserForm handleSubmit={handleSubmit} />
    </AdminWrapper>
  );
};

export default AddUser;
