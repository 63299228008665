import React from "react";

type IProps = {
  cell?: any;
  textAlign: "text-right" | "text-left" | "text-center";
  value: string;
};

export default ({ value, textAlign = "text-left" }: IProps) => (
  <div className={textAlign}>{value}</div>
);
