import React, { FC, ReactNode } from 'react'

import { CardWrapper } from './styles'

type Props = {
  title?: string;
  children: ReactNode;
}

const Card: FC<Props> = ({ title, children }) => (
  <CardWrapper className="card">
    {!!title && (
      <div className="card-header border-0">
        <div className="custom-title-wrap">
          <div className="custom-title">{title}</div>
        </div>
      </div>
    )}

    <div className="card-body">{children}</div>
  </CardWrapper>
)

export default Card
