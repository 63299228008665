import React, { useState, FC } from "react";
import toast from "react-hot-toast";
import * as yup from "yup";
import { navigate } from "@reach/router";
import { Formik, Form } from "formik";
import { makeRequest } from "utils/httpClient";
import AutoComplete from "components/auth/Autocomplete";
import { Modal, Button as AntButton } from "antd";
import TextField from "components/auth/TextField";
import { AUTHENTICATION_CLASS, Intents, Schemas } from "common/constants";

const initialValues = {
  mda: null,
  name: "",
};

const validationSchema = yup.object({
  mda: yup.string().required("MDA is required"),
  name: yup.string().required("Name is required"),
});

const CreateNewDeptType = ({ showCreateDeptType, onCancel }) => {
  const [isLoading, setIsLoading] = useState(false);

  const createDeptType = async ({ mda, name }) => {
    setIsLoading(true);
    const [data, , error] = await makeRequest({
      path: "/jql",
      method: "POST",
      config: {
        data: {
          __meta__: {
            authenticationClass: AUTHENTICATION_CLASS,
            intent: Intents.create,
            namespace: "debt.DebtType",
            return: {
              id: null,
            },
            schema: Schemas.model,
          },
          mda,
          name,
        },
      },
    });

    if (error && error.errors && Array.isArray(error.errors)) {
      error.errors.map(({ error_message }) => {
        return toast.error(error_message);
      });
    } else {
      toast.success(data.message);
      navigate("/app/admin/debts/configure");
    }
    setIsLoading(false);
    onCancel();
  };
  return (
    <Modal title="Create New Dept Type" open={showCreateDeptType} onCancel={onCancel} footer={null}>
      <Formik
        enableReinitialize
        onSubmit={createDeptType}
        initialValues={initialValues}
        validationSchema={validationSchema}
      >
        {({ isSubmitting }) => {
          return (
            <Form>
              <div className="row">
                <div className="col-12">
                  <TextField label="Dept Type" name="name" placeholder="Dept type title" />
                </div>
                <div className="col-12">
                  <AutoComplete
                    name="mda"
                    label="MDA"
                    namespace="system.MDA"
                    resultCallback={({ id, name }) => {
                      return { label: name, value: id };
                    }}
                    defaultOptions={[{ label: "Select MDA", value: "" }]}
                  />
                </div>
              </div>
              <div className="text-right">
                <AntButton key="back" onClick={onCancel} className="mr-4 mt-3">
                  Cancel
                </AntButton>
                <AntButton key="submit" htmlType="submit" type="primary" loading={isLoading}>
                  Create
                </AntButton>
              </div>
            </Form>
          );
        }}
      </Formik>
    </Modal>
  );
};

export default CreateNewDeptType;
