import React, { useMemo, useState } from "react";
import TableUnstyled from "components/TableUnstyled";
import numeral from "numeral";
import { Button, Modal } from "antd";
import { isEmpty } from "lodash";
import CompaniesWithinDebtRange from "./CompaniesWithinDebtRange";

function DebtAnalysis({ data }) {
  const [activeRow, setActiveRow] = useState<{ size: string }>();

  const columns = useMemo(
    () => [
      { Header: "Debt Size", accessor: "size" },
      {
        Header: () => <div className="text-center">Number</div>,
        accessor: "count",
        Cell: ({ value }) => <div className="text-center">{value}</div>,
      },
      {
        Header: () => (
          <div className="text-right">
            Amount <br /> (NGN)
          </div>
        ),
        accessor: "amount",
        Cell: ({ value }) => (
          <div className="text-right font-weight-bold">{numeral(value).format("0,0")}</div>
        ),
      },
      {
        Header: "",
        accessor: "actions",
        Cell: ({ row }) => (
          <div className="text-right">
            <Button className="small" onClick={() => setActiveRow(row.original)}>
              View
            </Button>
          </div>
        ),
      },
    ],
    [],
  );

  return (
    <div className="p-4">
      <div className="">
        <h4 className="fw-bold mb-2">Debt Analysis</h4>
        {/* <p className="text-muted">% of debt collected compared to the total debt owed</p> */}
      </div>
      <div className="pt-4">
        <TableUnstyled columns={columns} data={data || []} />
      </div>
      {!isEmpty(activeRow) && (
        <Modal
          title={`Companies with debt within ${activeRow?.size}`}
          centered
          open={!isEmpty(activeRow)}
          onOk={() => setActiveRow(undefined)}
          onCancel={() => setActiveRow(undefined)}
          width={1000}
        >
          <CompaniesWithinDebtRange activeRow={activeRow} />
        </Modal>
      )}
    </div>
  );
}

export default DebtAnalysis;
