import React from "react";
import { FcHighPriority, FcOk } from "react-icons/all";
import _ from "lodash";

type LabelMapType = {
  true: string | boolean;
  false: string | boolean;
};

type IProps = {
  value: boolean | string | number;
  labelMap?: LabelMapType;
  strict?: boolean;
};

/**
 * A label to display true false status of a component (works using truthy or falsy)
 */
export default ({ value, labelMap, strict = false }: IProps) => {
  if (labelMap) {
    value = _.mapKeys(_.invert(labelMap), (v, k) => k.toLowerCase())?.[
      value.toString().toLowerCase()
    ];
  }
  return !!(strict ? value === true : value) ? <FcOk size={18} /> : <FcHighPriority size={18} />;
};
