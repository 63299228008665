import React, { Dispatch, useState } from "react";
import { Field, Formik, Form } from "formik";

import {
  ActionButton,
  ChangePasswordBtn,
  FormInputLabel,
  FormRow,
  PersonalDetailsForm,
  SettingsWrapper,
} from "../../app/styles";

import Switch from "components/Switch";
import { httpClient } from "utils/index";
import Button from "components/auth/Button";
import TextField from "components/auth/TextField";
import BeatLoader from "react-spinners/BeatLoader";
import InfoSection from "components/auth/InfoSection";
import { PersonType, RepresentativeType } from "models/app/Profile";
import { PageLoaderWrapper } from "components/pages/admin/styles";
import {
  NotificationSetting,
  ShareComponent,
  CompanyRepTable,
  CompanyAccountManager,
} from "components/pages/company/components";
import { ChangePasswordModal } from "components/pages/company/settings/components";
import { BankType, CompanyBankAccountType, PersonBankAccountType } from "models/app/Services";
import { THEME } from "common/theme";
import { Box } from "components/app/styles/generic";
import { H5 } from "components/app/styles/typography";
import { AUTHENTICATION_CLASS } from "common/constants";

type IProps = {
  representatives: Array<RepresentativeType>;
  person: PersonType;
  bankAccounts: Array<CompanyBankAccountType | PersonBankAccountType>;
  banks: Array<BankType>;
  setBankAccounts: Dispatch<Array<CompanyBankAccountType | PersonBankAccountType>>;
  companyId: number | string;
};

const Settings = ({
  representatives,
  person,
  bankAccounts = [],
  banks = [],
  setBankAccounts,
  companyId,
}: IProps) => {
  const [_person, setPerson] = useState<PersonType>(person);
  const [isLoading, setLoading] = React.useState(false);
  const [isChangePasswordModalOpen, setChangePasswordModal] = React.useState(false);
  React.useEffect(() => {
    if (isChangePasswordModalOpen) {
      document.body.style.overflowY = "hidden";
    } else {
      document.body.style.overflowY = "scroll";
    }
  }, [isChangePasswordModalOpen]);

  const handlePersonFormSubmission = (values: any) => {
    (async () => {
      setLoading(true);
      const res = await httpClient.post("/jql", {
        __meta__: {
          authenticationClass: AUTHENTICATION_CLASS,
          namespace: "profile.Person",
          schema: "model",
          intent: "patch",
          uniqueObject: {
            id: person.id,
          },
          return: {
            id: null,
            first_name: null,
            last_name: null,
            email: null,
            phone: null,
            bvn: null,
            nin: null,
            // position: null,
          },
        },
        ...values,
      });
      setPerson(res.data.data);
      setLoading(false);
    })();
  };

  if (isLoading) {
    return (
      <PageLoaderWrapper>
        <BeatLoader color="#000" size={30} loading={true} />
      </PageLoaderWrapper>
    );
  }

  return (
    <>
      <Formik
        initialValues={{ ..._person }}
        onSubmit={(values, { setSubmitting }) => {
          handlePersonFormSubmission(values);
        }}
      >
        {({ submitForm, isSubmitting }) => {
          return (
            <Form>
              <InfoSection title="Personal Details">
                <div className="row">
                  <div className="col-12 col-sm-6">
                    <TextField label="First Name" name="firstName" />
                  </div>

                  <div className="col-12 col-sm-6">
                    <TextField label="Last Name" name="lastName" />
                  </div>

                  <div className="col-12 col-sm-6">
                    <TextField label="Tax Identification Number (TIN)" name="tin" />
                  </div>

                  <div className="col-12 col-sm-6">
                    <TextField label="National Identity Number (NIN)" name="nin" />
                  </div>

                  <div className="col-12 col-sm-6">
                    <TextField label="Email" type="email" name="email" />
                  </div>

                  <div className="col-12 col-sm-6">
                    <TextField label="Phone" name="phone" />
                  </div>
                </div>
              </InfoSection>

              <div className="mt-4">
                <Button
                  type="submit"
                  label="SAVE CHANGES"
                  loading={isSubmitting}
                  disabled={isSubmitting}
                />
              </div>
            </Form>
          );
        }}
      </Formik>

      {/*<div className="mt-5 mb-5">*/}
      {/*  <CompanyAccountManager*/}
      {/*    banks={banks}*/}
      {/*    bankAccounts={bankAccounts}*/}
      {/*    setBankAccounts={setBankAccounts}*/}
      {/*  />*/}
      {/*</div>*/}

      <Box marginTop={THEME.sizes.md}>
        <CompanyRepTable companyId={companyId} />
      </Box>

      <div className="mt-5 pt-2">
        <Button
          type="button"
          label="CHANGE PASSWORD"
          onClick={() => setChangePasswordModal(true)}
        />
      </div>

      <Switch condition={isChangePasswordModalOpen}>
        <ChangePasswordModal closeModal={() => setChangePasswordModal(false)} />
      </Switch>
    </>
  );
};

export default Settings;
