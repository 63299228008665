import React, { FC, BaseSyntheticEvent } from 'react'

import { FileFieldWrapper } from './styles'

type Props = {
  label: string
  files: Object
  name: string
  handleFileUpload: (evt: BaseSyntheticEvent) => void
}

const StatusMap = {
  pending: 'Pending approval by an administrator',
  rejected: 'This document was rejected, please upload another one',
  approved: 'This document has been approved',
}

const FileField: FC<Props> = ({ label, files, name, handleFileUpload }) => {
  const status = files[name]?.status || ''
  return (
    <FileFieldWrapper>
      {status === 'approved' ? (
        <span className="label-approved">{label}</span>
      ) : (
        <label>
          <span className="label">{label}</span>

          <div className="form-inner-wrapper">
            <div className="form-control-wrapper">
              <input type="file" name={name} className="form-control" onChange={handleFileUpload} />
              <div className="custom-file">Attach file</div>
            </div>

            <div className="form-icon-wrapper">
              <svg
                fill="none"
                strokeWidth="2"
                viewBox="0 0 24 24"
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M16 16l-4-4-4 4M12 12v9" />
                <path d="M20.39 18.39A5 5 0 0018 9h-1.26A8 8 0 103 16.3" />
                <path d="M16 16l-4-4-4 4" />
              </svg>
            </div>
          </div>
        </label>
      )}

      <div className="file-status">{StatusMap[status] || 'Please upload a document'}</div>
    </FileFieldWrapper>
  )
}

export default FileField
