import styled from 'styled-components'

export const AppWrapper = styled.div`
  padding-top: 3.125rem;
`

export const CompanyInfo = styled.div`
  margin-bottom: 1.9375rem;
  background-color: #fff;
  padding: 2.875rem 1.625rem;
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
`

export const CompanyInfoLeft = styled.div`
  display: flex;
  align-items: center;

  h3 {
    font-weight: 800;
    font-size: 20px;
    line-height: 28px;
    color: #1e3c10;
    margin-right: 3.1875rem;
  }

  p {
    font-weight: normal;
    font-size: 14px;
    line-height: 18px;
    color: #1e3c10;

    span {
      font-weight: 600;
    }

    &:last-of-type {
      margin-top: 0.3125rem;
    }
  }
`

export const CompanyInfoRight = styled.div`
  p {
    &:first-of-type {
      font-weight: normal;
      font-size: 12px;
      line-height: 18px;
      color: #474747;
    }
    &:last-of-type {
      font-weight: bold;
      font-size: 14px;
      line-height: 18px;
      color: #1e3c10;
      background-color: #e7eaec;
      padding: 0.625rem 3.25rem 0.625rem 0.875rem;

      span {
        font-weight: normal;
      }
    }
  }
`

export const AppContent = styled.div`
  background-color: #fff;
  border-radius: 5px;
  align-self: stretch;
  flex-direction: column;
  display: flex;
  padding: 1.875rem 2.875rem;
  min-height: 775px;
`

export const ActionButton = styled.button`
  background-color: #4c9a2a;
  border-radius: 2px;
  padding: 0.6rem 2rem;
  font-size: 18px;
  line-height: 28px;
  color: #fff6fe;
  font-weight: 600;
  border: 0;
  -webkit-appearance: none;
  cursor: pointer;
  outline: none;

  &.outline {
    border: 3px solid #4c9a2a;
    background-color: #fff;
    display: flex !important;
    align-items: center;
    color: #181438;
  }

  &.outline svg {
    margin-left: 1rem;
  }

  &.right {
    margin-left: auto;
    display: block;
  }

  &.save {
    padding: 0.6875rem 2rem;
  }
`

export const PortalTabList = styled.ul`
  display: flex;
  list-style: none;
  padding: 0;
  margin: 0;

  li {
    padding: 0.625rem 0;
    background-color: transparent;
  }

  li.active {
    border-bottom: 2px solid #4c9a2a;
  }

  li.active button {
    color: #1e3c10;
  }

  li button {
    -webkit-appearance: none;
    text-decoration: none;
    color: inherit;
    border: 0;
    padding: 0;
    background-color: transparent;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 23px;
    outline: none;
    cursor: pointer;
    color: #4c9a2a;
    opacity: 0.7;
  }

  li:not(:last-of-type) {
    margin-right: 1.875rem;
  }
`

export const PortalTabWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1.75rem;
`

export const PortalTabRight = styled.div`
  display: flex;
  align-items: center;

  a {
    margin-right: 2rem;
    padding: 5px 21px;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    background-color: #0065ff;
    border-radius: 2px;
    color: #ffffff;
    text-decoration: none;
  }
`
export const NotificationBell = styled.button`
  border: none;
  position: relative;
  padding: 0;
  cursor: pointer;
  background-color: transparent;

  span {
    position: absolute;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 16px;
    height: 16px;
    border-radius: 100%;
    background-color: #f25c78;
    font-weight: 600;
    font-size: 8px;
    line-height: 10px;
    color: #ffffff;
  }
`

// Accounts
export const AccountStatsStyle = styled.div`
  background-color: #f1f1f1;
  border-radius: 2px;
  padding: 1.3125rem 3.8125rem;
  display: flex;
  align-items: center;
  margin-bottom: 2.1875rem;
`

export const AccountStatsItem = styled.div`
  text-align: center;

  &:not(:last-of-type) {
    margin-right: 3.75rem;
  }

  h4 {
    font-size: 24px;
    line-height: 30px;
    color: #000;
  }

  p {
    margin-top: 0.625rem;
    margin-bottom: 0;
    font-size: 18px;
    line-height: 21px;
  }
`

export const DebtsCreditorsWrapper = styled.div`
  display: flex;
  margin-bottom: 2.5rem;
`

export const DebtsCreditorsCard = styled.div`
  background-color: #fff;
  box-shadow: 0px 4px 10px rgba(90, 90, 90, 0.25);
  border-radius: 2px;
  padding: 0.875rem 1.625rem;

  p {
    margin: 0;
    font-size: 14px;
    line-height: 18px;
    font-weight: 600;
    color: #1e3c10;
  }

  p strong {
    font-weight: 800;
  }

  div {
    display: flex;
    align-items: center;
    margin-top: 0.3125rem;
  }

  div p {
    margin-left: 0.3125rem;
    font-weight: normal;
    color: #525252;
  }

  &:not(:last-of-type) {
    margin-right: 2.1875rem;
  }

  &.creditors div span {
    color: #f80000;
  }

  &.debts div span {
    color: #4c9a2a;
  }
`

export const Table = styled.table`
  border-collapse: collapse;
  width: 100%;

  td,
  th {
    padding: 0.875rem 1rem;
    font-size: 14px;
    line-height: 17px;
  }

  th {
    text-align: left;
    background-color: #cacaca;
    color: #161616;
    font-weight: 600;
  }

  td {
    font-weight: normal;
    color: #393939;
  }

  tbody tr:not(:last-of-type) {
    border-bottom: 1px solid #e0e0e0;
  }
`

export const TableWrapper = styled.div`
  border: 1px solid #e0e0e0;
  border-radius: 2px;
  background-color: #f1f1f1;
  margin-bottom: 2.5rem;
`

export const TableWrapperHeading = styled.div`
  margin: 0.625rem 0 1.25rem 1rem;

  h4 {
    font-weight: bold;
    color: #18222f;
    font-size: 18px;
    line-height: 23px;
  }

  p {
    margin: 0;
    font-weight: normal;
    font-size: 14px;
    line-height: 18px;
    color: #18222f;
  }

  &.company-rep {
    margin: 2.3125rem 0 1.25rem 1rem;
  }
`

export const TableWrapperFooter = styled.div`
  background-color: #fff;
  padding: 1.6rem 1rem 0.625rem;
`

export const PrintReportBtn = styled.button`
  -webkit-appearance: none;
  display: flex;
  align-items: center;
  background-color: transparent;
  border: 0;
  padding: 0;
  font-weight: 600;
  font-size: 14px;
  line-height: 28px;
  cursor: pointer;
  color: #4c9a2a;

  svg {
    margin-left: 1rem;
  }
`

export const TaxInformationWrapper = styled.div`
  height: 400px;
  background-repeat: no-repeat;
  background-position: center center;
  background-image: url('/media/coming-soon.svg');
`

export const SettingsWrapper = styled.div`
  h4 {
    font-weight: 600;
    font-size: 18px;
    line-height: 18px;
    color: #163327;
    margin-bottom: 1.5rem;
  }
`

export const PersonalDetailsForm = styled.form`
  background-color: #f8f8f8;
  padding: 2.5rem 1rem;
  margin-bottom: 3.125rem;
`

export const FormRow = styled.div`
  display: grid;
  grid-template-columns: repeat(${({ col = 2 }) => col}, 1fr);
  grid-gap: 1.875rem;

  :not(:last-of-type) {
    margin-bottom: 1.25rem;
  }
`

export const FormInputLabel = styled.label`
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  display: block;

  input,
  select {
    display: block;
    width: 100%;
    margin-top: 0.625rem;
    font-weight: normal;
    font-size: 16px;
    line-height: 18px;
    color: #393939;
    padding: 0.625rem;
    border: 1px solid #4f4f4f;
  }

  select {
    -webkit-appearance: none;
    border-radius: 0;
    background-image: url('/media/caret-down.svg');
    background-repeat: no-repeat;
    background-position: right 1.5rem top 50%;
    background-size: 12px 6px;
  }

  .file-input {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
  }

  &.file-input-label {
    text-align: center;
    display: inline-block;
    place-self: start;
    cursor: pointer;
  }

  &.file-input-label::before {
    content: '';
    display: block;
    width: 60px;
    height: 60px;
    border-radius: 100%;
    background-color: #ffffff;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    background-image: url('/media/camera.svg');
    background-repeat: no-repeat;
    background-position: center center;
    margin-bottom: 0.625rem;
    margin-left: auto;
    margin-right: auto;
  }

  &.change-password {
    margin-bottom: 0.875rem;

    &:last-of-type {
      margin-bottom: 3.125rem;
    }
  }

  /* input.amount:before {
        content: '₦'
    } */
`

export const ChangePasswordBtn = styled.button`
  align-self: end;
  justify-self: start;
  font-weight: 600;
  font-size: 18px;
  line-height: 28px;
  color: #371fcd;
  padding-bottom: 0.2rem;
  text-decoration: none;
  border-bottom: 1px solid #371fcd;
  -webkit-appearance: none;
  border: 0;
  background-color: transparent;
  text-decoration: underline;
  padding: 0;
  outline: none;
  cursor: pointer;
`

export const NotificationList = styled.div`
  margin-bottom: 3rem;
  display: flex;
  padding: 2.5rem 1rem;
  background-color: #f8f8f8;
  margin: 0;
  margin-bottom: 3rem;
`

export const NotificationListLabel = styled.label`
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  color: #163327;
  display: flex;
  align-items: center;
  position: relative;
  display: inline-block;

  &:not(:last-of-type) {
    margin-right: 3.75rem;
  }

  input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  span {
    position: relative;
    display: inline-block;
    margin-left: 1rem;
    width: 28px;
    height: 14px;
    cursor: pointer;
    background-color: #ccc;
    -webkit-transition: 0.4s;
    transition: 0.4s;
    box-shadow: inset 0px 1px 2px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
  }

  span:before {
    position: absolute;
    content: '';
    height: 18px;
    width: 18px;
    left: -9px;
    top: 50%;
    transform: translateY(-50%);
    background-color: white;
    -webkit-transition: 0.4s;
    transition: 0.4s;
    border-radius: 50%;
    border: 0.1px solid rgba(196, 196, 196, 0.4);
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
  }

  input:checked + span {
    background-color: #2196f3;
  }

  input:checked + span:before {
    left: 20px;
  }
`

export const InviteCompanyList = styled.ul`
  display: flex;
  padding: 2.5rem 1rem;
  background-color: #f8f8f8;
  margin: 0;
  margin-bottom: 5rem;
`

export const InviteCompanyListItem = styled.li`
  display: flex;
  align-items: center;

  &:not(:last-of-type) {
    margin-right: 1.5rem;
  }

  svg {
    margmodalwrapperin-right: 0.3125rem;
  }

  a {
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    color: #163327;
    text-decoration: none;
  }
`

export const ModalWrapper = styled.div`
  position: fixed;
  background-color: rgba(22, 51, 39, 0.2);
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 90;

  button {
    &:not(:first-child) {
      margin-left: 1rem;
    }
  }
`

export const ChangePasswordForm = styled.form`
  background-color: #fff;
  width: 100%;
  max-width: 500px;
  margin: 10rem auto 0;
  padding: 1.875rem 1.5rem;
  position: relative;
  border-radius: 3px;
  border-top: 4px solid #156148;

  p {
    font-size: 1rem;
    font-weight: 600;
    font-family: 'Montserrat', sans-serif;
    color: ${({ theme }) => theme.dark_color};
  }
`

export const CloseModalBtn = styled.button`
  top: 0;
  right: 0;
  border: 0;
  padding: 0;
  position: absolute;
  margin: 1rem 1.5rem 0 0;
  -webkit-appearance: none;
  background-color: transparent;
`

export const TableActions = styled.div`
  background-color: #4c9a2a;
  display: flex;
`

export const TableActionsLeft = styled.div`
  padding: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-grow: 1;

  div {
    display: flex;
  }
`

export const TableActionsRight = styled.div`
  padding: 1rem;
  border-left: 1px solid #fff;
  display: flex;
  align-items: center;
`

export const SelectionCount = styled.p`
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  color: #fff;
`

export const TableActionsBtn = styled.button`
  -webkit-appearance: none;
  background-color: transparent;
  padding: 0;
  color: #fff;
  display: inline-flex;
  align-items: center;
  border: none;
  cursor: pointer;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  outline: 0;

  svg {
    margin-left: 1.25rem;
  }

  &:not(:last-of-type) {
    margin-right: 2.25rem;
  }
`

export const createCheckBoxCell = (el: 'td' | 'th') => styled[el]`
  padding-right: 0 !important;
`

export const CheckboxCellLabel = styled.label`
  cursor: pointer;
`

export const GenericForm = styled(ChangePasswordForm)``
