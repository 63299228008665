import styled from 'styled-components'

export const TableWrapper = styled.div`
  overflow-x: auto;
  margin: -1.5rem -1rem;

  &::-webkit-scrollbar {
    display: none;
  }

  table {
    width: 100%;

    thead {
      th {
        overflow: hidden;
        white-space: nowrap;
        padding: 0.875rem 1rem;
        text-overflow: ellipsis;
        background-color: #e4e8ec;
      }
    }

    tbody {
      tr {
        &:not(:last-of-type) {
          border-bottom: 1px solid #e4e8ec;
        }

        td {
          overflow: hidden;
          white-space: nowrap;
          padding: 0.875rem 1rem;
          text-overflow: ellipsis;
        }
      }
    }
  }

  .footer {
    padding: 0.875rem 1rem;
    background-color: #e4e8ec;
  }
`
