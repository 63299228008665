import React, { FC, useState } from "react";
import { Link } from "gatsby";
import { object, string } from "yup";
import { Form, Formik } from "formik";
import { RouteComponentProps } from "@reach/router";
import Button from "components/auth/Button";
import TextField from "components/auth/TextField";
import PublicLayout from "components/auth/PublicLayout";
import AuthenticationStore from "stores/AuthenticationStore";
import { observer, useLocalObservable } from "mobx-react";
import { JqlService } from "common/client";
import { AUTHENTICATION_CLASS } from "common/constants";
import ResetNewUserPassword from "./ResetNewUserPassword";
import { isEmpty } from "lodash";
import toast from "react-hot-toast";
import { handleError, handleResponse } from "common/utils";

const validationSchema = object({
  username: string().email().required(),
  password: string().required(),
});

const Login: FC<RouteComponentProps> = observer(({ location, navigate }: any) => {
  const authenticationStore = useLocalObservable(() => AuthenticationStore);
  const [resetPassword, setResetPassword] = useState<
    { user: string; oldPassword: string } | undefined
  >();
  const handleSubmit = async ({ username, password }) => {
    try {
      const response = await JqlService.post(JqlService.JQL_ENDPOINT, {
        __meta__: {
          authenticationClass: AUTHENTICATION_CLASS,
          namespace: "system.User",
          schema: "controller",
          intent: "authenticate",
          return: {
            email: null,
            first_name: null,
            firstTime: null,
            last_name: null,
            user_type: null,
            phone: null,
            phone_country_code: null,
            id: null,
          },
        },
        username,
        password,
      });
      if (response?.data?.data?.firstTime) {
        setResetPassword({ user: response?.data?.data?.id, oldPassword: password });
        toast.success("You need to set a new password!");
      } else {
        handleResponse(response);
        localStorage.setItem("token", response.data?.data.token.access);
        authenticationStore.setAuth({ user: response?.data?.data, isLoggedIn: true });
        if (location?.state?.from) {
          navigate(location.state.from);
        }
      }
    } catch (error) {
      handleError(error);
    }
  };

  return (
    <PublicLayout title={!isEmpty(resetPassword) ? "Change Temporal Password" : "Sign In"}>
      {!isEmpty(resetPassword) ? (
        <ResetNewUserPassword setResetPassword={setResetPassword} user={resetPassword} />
      ) : (
        <Formik
          onSubmit={handleSubmit}
          validationSchema={validationSchema}
          initialValues={{ username: "", password: "" }}
        >
          {({ handleSubmit, isSubmitting }) => {
            return (
              <Form>
                <TextField name="username" placeholder="john.doe@carterltd.com" />

                <div className="mb-4">
                  <TextField name="password" type="password" placeholder="password" />
                </div>

                <div className="d-flex flex-wrap align-items-center justify-content-between">
                  <div>
                    <Link to="/app/auth/forgot">Forgot password?</Link>
                  </div>

                  <div className="ml-4">
                    <Button
                      label="LOGIN"
                      type="submit"
                      loading={isSubmitting}
                      disabled={isSubmitting}
                    />
                  </div>
                </div>
                <div className="text-center mt-4">
                  <Link to="/app/auth/register" className="form-footer-link">
                    Create a new account
                  </Link>
                </div>
              </Form>
            );
          }}
        </Formik>
      )}
    </PublicLayout>
  );
});
export default Login;
