import React, { FC, useState, useEffect } from "react";
import { object, string } from "yup";
import { Formik, Form, FormikHelpers } from "formik";

import Button from "components/auth/Button";
import { makeRequest } from "utils/httpClient";
import TextField from "components/auth/TextField";
import { Intents } from "common/enums";
import SelectField from "components/auth/SelectField";
import { JqlService } from "common/client";
import { AUTHENTICATION_CLASS } from "common/constants";

const defaultInitialVals = {
  name: "",
  email: "",
  tin: "",
  registrationNumber: "",
  phone1: "",
  phone2: "",
  line1: "",
  line2: "",
  city: "",
  state: "",
  website: "",
};

const validationSchema = object({
  name: string().required(),
  email: string().email().required(),
  tin: string().required(),
  registrationNumber: string().required(),
  phone1: string().required(),
  phone2: string(),
  line1: string().required(),
  line2: string(),
  city: string().required(),
  state: string().required(),
  website: string(),
});

type CompanyFormData = {};

type Props = {
  initialValues?: CompanyFormData;
  handleSubmit: (val: CompanyFormData) => Promise<void>;
};

const jqlQuery = {
  __meta__: {
    authenticationClass: AUTHENTICATION_CLASS,
    intent: Intents.metadata,
    namespace: "profile.Company",
    schema: "model",
  },
};

const CompanyForm: FC<Props> = ({ handleSubmit, initialValues = defaultInitialVals }) => {
  const [states, setStates] = useState<{ label: string; value: string }[]>([]);

  useEffect(() => {
    let isMounted = true;
    (async () => {
      try {
        const response = await JqlService.post(JqlService.JQL_ENDPOINT, jqlQuery);
        const { data, errors } = response.data;
        if (isMounted && data) {
          setStates(data.injectable?.state?.options);
        }
      } catch ({ response }) {
        // console.log({ response });
      }
    })();
    return () => {
      isMounted = false;
    };
  }, []);

  const onSubmit = async (values: CompanyFormData, actions: FormikHelpers<CompanyFormData>) => {
    await handleSubmit(values);
    actions.setSubmitting(false);
  };

  return (
    <Formik
      enableReinitialize
      onSubmit={onSubmit}
      initialValues={initialValues}
      validationSchema={validationSchema}
    >
      {({ isSubmitting }) => (
        <Form>
          <div className="row">
            <div className="col-md-6">
              <TextField label="Name" name="name" />
            </div>

            <div className="col-md-6">
              <TextField label="Email" name="email" />
            </div>
          </div>

          <div className="row">
            <div className="col-md-6">
              <TextField label="TIN Number" name="tin" />
            </div>

            <div className="col-md-6">
              <TextField label="RC Number" name="registrationNumber" />
            </div>
          </div>

          <div className="row">
            <div className="col-md-6">
              <TextField label="Phone 1" name="phone1" />
            </div>

            <div className="col-md-6">
              <TextField label="Phone 2" name="phone2" />
            </div>
          </div>

          <div className="row">
            <div className="col-md-6">
              <TextField label="Address 1" name="line1" />
            </div>

            <div className="col-md-6">
              <TextField label="Address 2" name="line2" />
            </div>
          </div>

          <div className="row">
            <div className="col-md-6">
              <TextField label="City" name="city" />
            </div>

            <div className="col-md-6">
              <SelectField
                label="State"
                name="state"
                options={[{ value: "", label: "Select state" }, ...states]}
              />
            </div>
          </div>

          <div className="row">
            <div className="col-md-6">
              <TextField label="Website URL" type="url" name="website" />
            </div>
          </div>

          <div className="text-left mt-2">
            <Button type="submit" label="SUBMIT" loading={isSubmitting} disabled={isSubmitting} />
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default CompanyForm;
