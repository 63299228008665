import React, { useEffect, useState } from "react";
import AdminWrapper from "components/pages/admin/index";
import InfoSection from "components/auth/InfoSection/index";
import { JQL_ENDPOINT } from "common/config";
import client from "common/client";
import { useParams } from "@reach/router";
import { Intents } from "common/enums";
import { Profile_CompanyRepresentativeType } from "models/db-schema";
import { H3, Small } from "components/app/styles/typography";
import { FlexBox } from "components/app/styles/generic";
import { THEME } from "common/theme";
import CompanyRepresentativeActionMenu from "components/shared/CompanyRepresentativeActionMenu";
import { AUTHENTICATION_CLASS } from "common/constants";

const breadcrumbs = [
  { label: "Home", link: "/app/welcome" },
  { label: "Companies", link: "/app/admin/companies" },
  { label: "Company Representative" },
];

export default () => {
  const [draw, setDraw] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>();
  const [data, setData] = useState<Array<Profile_CompanyRepresentativeType>>([]);

  const { id } = useParams();

  // get the list of corporate representatives
  useEffect(() => {
    (async () => {
      const response = await client.post(JQL_ENDPOINT, {
        __meta__: {
          authenticationClass: AUTHENTICATION_CLASS,
          namespace: "profile.CompanyRepresentative",
          schema: "model",
          intent: Intents.retrieve,
          filter: {
            company: {
              id,
            },
          },
        },
        id: null,
        user: {
          id: null,
          fullname: null,
          email: null,
          phone: null,
        },
        admin: null,
        verified: null,
        comment: null,
      });

      setData(response?.data.data);
    })();
  }, [draw]);

  return (
    <AdminWrapper title="Verify Company" breadcrumbs={breadcrumbs}>
      <div className="mt-5 mb-4 col-12 col-sm-6">
        <InfoSection title="Manage Company Representatives">
          {data.map((v, i) => {
            // set up the colour indicators
            const verifiedColour = v.verified ? THEME.colors.green : THEME.colors.darkRed;
            const verifiedString = v.verified ? "Verified" : "Not Verified";

            const adminColour = v.admin ? THEME.colors.black : THEME.colors.green;
            const adminString = v.admin ? "Admin" : "Not Admin";

            return (
              <FlexBox
                key={`key__CompanyRepresentative__${i}`}
                className="row"
                alignContent={"center"}
                justifyContent={"space-between"}
                margin={0}
                padding={`0 ${THEME.sizes.md}px`}
              >
                <div>
                  <strong>{v.user.fullname}</strong>
                  <p>{v.user.email}</p>
                  <p>{v.user.phone}</p>
                  <FlexBox>
                    <FlexBox alignItems={"center"}>
                      <H3 colour={verifiedColour}>&bull;</H3>
                      <Small marginLeft={THEME.sizes.sm}>{verifiedString}</Small>
                    </FlexBox>
                    <FlexBox alignItems={"center"} marginLeft={THEME.sizes.md}>
                      <H3 colour={adminColour}>&bull;</H3>
                      <Small marginLeft={THEME.sizes.sm}>{adminString}</Small>
                    </FlexBox>
                  </FlexBox>
                </div>
                <div>
                  <CompanyRepresentativeActionMenu
                    representative={v}
                    draw={draw}
                    setDraw={setDraw}
                  />
                </div>
              </FlexBox>
            );
          })}
        </InfoSection>
      </div>
    </AdminWrapper>
  );
};
