import styled from 'styled-components'

export const InfoSectionWrapper = styled.div`
  .custom-title {
    font-size: 1rem;
    font-weight: 600;
    margin-bottom: 1rem;
    font-family: 'Montserrat', sans-serif;
    color: ${({ theme }) => theme.dark_color};
  }

  .section-body {
    overflow: hidden;
    padding: 1.5rem 1rem;
    border-radius: 0.3125rem;
    background-color: ${({ theme }) => theme.light_color};
  }
`
