import styled from 'styled-components'

export const SelectFieldWrapper = styled.div`
  margin-bottom: 1rem;

  label {
    font-weight: 600;
    font-size: 0.875rem;
    display: inline-block;
    margin-bottom: 0.5rem;
    font-family: 'Montserrat', sans-serif;
    color: ${({ theme }) => theme.dark_color};
  }

  .form-control {
    height: 2.625rem;
    font-size: 0.8rem;
    border-radius: 0.25rem;
    padding: 0.375rem 0.75rem;
    border: 1px solid #ced4da;
    background-color: ${({ theme }) => theme.white};
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

    &.is-invalid {
      border-color: ${({ theme }) => theme.error};
    }

    &:disabled {
      background-color: ${({ theme }) => theme.light_color};
    }
  }

  .invalid-feedback {
    display: block;
  }
`
