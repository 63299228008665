import React from "react";
import { AUTHENTICATION_CLASS } from "common/constants";
import TableUnstyled from "components/TableUnstyled";
import useJql from "hooks/useJql";
import numeral from "numeral";

const columns = [
  {
    Header: "Company",
    accessor: "company",
    Cell: ({ value }) => {
      return <span>{value?.name}</span>;
    },
  },
  {
    Header: () => (
      <div className="text-center">
        Amount <br /> (NGN)
      </div>
    ),
    accessor: "debtByCompany",
    Cell: ({ value }) => {
      return <div className="text-right font-weight-bold">{numeral(value).format("0,0")}</div>;
    },
  },
];

const jql = {
  __meta__: {
    namespace: "debt.Debt",
    schema: "model",
    intent: "aggregate",
    authenticationClass: AUTHENTICATION_CLASS,
    groupBy: ["company"],
    limit: 10,
    ordering: "-debtByCompany",
  },
  company: {
    id: null,
    name: null,
  },
  debtByCompany: {
    __meta__: {
      field: "amount_outstanding",
      function: "sum",
    },
  },
};

function TopDebtors() {
  const { data, isLoading } = useJql({
    jqlQuery: jql,
  });
  return (
    <div className="p-4">
      <div className="">
        <h4 className="fw-bold mb-2">Top Debtors</h4>
        {/* <p className="text-muted">% of debt collected compared to the total debt owed</p> */}
      </div>
      <div className="">
        <TableUnstyled columns={columns} data={data || []} />
      </div>
    </div>
  );
}

export default TopDebtors;
