import React from "react";
import ReactPaginate from "react-paginate";
import { useTable } from "react-table";
import styled from "styled-components";

function TableUnstyled({
  columns,
  data,
  paginate = false,
  pageSize,
  totalCount,
  onPageClick,
}: {
  columns: any[];
  data: any[];
  paginate?: boolean;
  pageSize?: number;
  totalCount?: number;

  onPageClick?: (arg: number) => void;
}) {
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable({
    columns,
    data,
  });

  return (
    <TableContainer>
      <table {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th {...column.getHeaderProps()}>{column.render("Header")}</th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map((cell) => {
                  return <td {...cell.getCellProps()}>{cell.render("Cell")}</td>;
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
      {!(data?.length > 0) && (
        <div>
          <p className="text-center">No data</p>
        </div>
      )}
      {paginate && (
        <ReactPaginate
          nextLabel=">"
          onPageChange={({ selected }) => {
            onPageClick && onPageClick(selected);
          }}
          pageCount={totalCount && pageSize ? Math.ceil(totalCount / pageSize) : 0}
          previousLabel="<"
          // pageClassName="page-item"
          // pageLinkClassName="page-link"
          // previousClassName="page-item"
          // previousLinkClassName="page-link"
          // nextClassName="page-item"
          // nextLinkClassName="page-link"
          // breakClassName="page-item"
          // breakLinkClassName="page-link"
          renderOnZeroPageCount={null}
          containerClassName={"pagination"}
          previousLinkClassName={"pagination__link"}
          nextLinkClassName={"pagination__link"}
          disabledClassName={"pagination__link--disabled"}
          activeClassName={"pagination__link--active"}
          breakLabel="..."
        />
      )}
    </TableContainer>
  );
}
const TableContainer = styled.div`
  table {
    border-collapse: separate;
    border-spacing: 0 10px;
    width: 100%;
    transition: all 0.25ms ease-in-out;
  }
  tbody > tr:hover {
    background-color: #d3d3d333;
    td {
      /* font-weight: 500; */
      color: #2d2d2d;
    }
  }
  td,
  th {
    font-size: 0.8rem;
    text-align: left;
    padding: 10px;
  }
  th {
    /* color: #4b4b4b; */
    font-weight: 700;
    padding-bottom: 20px;
  }
  td {
    /* color: #7f7f7f; */
    font-weight: 400;
  }
  tbody > tr > td {
    border-top: 1px solid #eee;
    border-bottom: 1px solid #eee;

    &:first-of-type {
      border-left: 1px solid #eee;
      border-radius: 5px 0 0 5px;
    }
    &:last-of-type {
      border-right: 1px solid #eee;
      border-radius: 0 5px 5px 0;
    }
  }

  .pagination {
    display: flex;
    gap: 0.5em;
  }

  .pagination a {
    height: 30px;
    padding: 0 10px;
    border-radius: 5px;
    border: 1px solid #156148;
    color: #156148;
    display: flex;
    align-items: center;
  }

  .pagination__link {
    font-weight: bold;
  }

  .pagination__link--active a {
    color: #fff;
    background: #156148;
  }

  .pagination__link--disabled a {
    color: rgb(198, 197, 202);
    border: 1px solid rgb(198, 197, 202);
  }
`;
export default TableUnstyled;
