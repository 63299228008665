import React from "react";
import { Formik, Form } from "formik";
import { Link } from "gatsby";
import { RouteComponentProps } from "@reach/router";
import toast from "react-hot-toast";
import { object, string } from "yup";

import { auth } from "utils";

import PublicLayout from "components/auth/PublicLayout";
import TextField from "components/auth/TextField";
import Button from "components/auth/Button";
import { UserTypes } from "common/constants";

const validationSchema = object({
  first_name: string().required(),
  last_name: string().required(),
  phone: string().required(),
  email: string().email().required(),
});

const Register: React.FC<RouteComponentProps> = ({ navigate }) => {
  const handleSubmit = async (values) => {
    try {
      await auth.register(values);
      navigate && navigate("/app/auth/login");
    } catch (err) {
      toast.error(err.response.data.errors[0].error_message);
    }
  };

  return (
    <PublicLayout title="Register">
      <Formik
        initialValues={{
          firstName: "",
          lastName: "",
          email: "",
          password: "",
          confirmPassword: "",
          phone: "",
          nin: "",
          userType: UserTypes.corporate_representative,
        }}
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
      >
        {({ handleSubmit, isSubmitting }) => {
          return (
            <Form onSubmit={handleSubmit}>
              <TextField name="email" placeholder="Email" />

              <TextField name="password" placeholder="Password" type="password" />

              <TextField name="confirmPassword" placeholder="Confirm Password" type="password" />

              <div className="d-flex flex-wrap mx-n2">
                <div className="w-50 px-2">
                  <TextField name="first_name" placeholder="First name" />
                </div>

                <div className="w-50 px-2">
                  <TextField name="last_name" placeholder="Last name" />
                </div>
              </div>

              <TextField name="phone" placeholder="Phone" />

              <TextField name="nin" placeholder="National Identify Number (NIN)" />

              <div className="d-flex flex-wrap align-items-center justify-content-between">
                <div className="d-flex  align-items-center">
                  <input type="checkbox" required className="mr-2" />
                  <Link to="/terms">Agree to terms of use</Link>
                </div>

                <div className="ml-4">
                  <Button
                    type="submit"
                    label="CREATE"
                    loading={isSubmitting}
                    disabled={isSubmitting}
                  />
                </div>
              </div>

              <div className="text-center mt-4">
                Already have an account?{" "}
                <Link to="/app/auth/login" className="form-footer-link">
                  Sign in
                </Link>
              </div>
            </Form>
          );
        }}
      </Formik>
    </PublicLayout>
  );
};

export default Register;
