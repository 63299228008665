import React from "react";
import { Modal } from "antd";
import { Link } from "gatsby";
import toast from "react-hot-toast";
import { navigate } from "@reach/router";
import { FaEdit, FaTimes } from "react-icons/fa";

import AdminWrapper from "../index";
import { ColumnHeader } from "./constants";
import { MdaType } from "models/app/System";
import { makeRequest } from "utils/httpClient";
import { Intents } from "common/enums";
import { CastJqlType, JqlQueryType } from "models/types/Services";
import DataTable from "components/generic/table/DataTable";
import ActionMenu from "components/generic/menu/ActionMenu";
import { AUTHENTICATION_CLASS } from "common/constants";

const keys = [
  {
    key: "key__mdasMenuItem__edit",
    label: "Edit MDA",
    icon: FaEdit,
  },
  {
    key: "key__mdasMenuItem__delete",
    label: "Delete MDA",
    icon: FaTimes,
  },
];

export default () => {
  const { confirm } = Modal;
  const jql: JqlQueryType<CastJqlType<MdaType>> = {
    __meta__: {
      authenticationClass: AUTHENTICATION_CLASS,
      namespace: "system.MDA",
      schema: "model",
      intent: Intents.retrieve,
    },
    id: null,
    code: null,
    name: null,
    abbr: null,
  };

  const handleMenuClick = (e, id) => {
    switch (e.key) {
      case "key__mdasMenuItem__edit":
        navigate(`/app/admin/mdas/${id}/edit`);
        break;

      case "key__mdasMenuItem__delete":
        confirm({
          okText: "Yes",
          centered: true,
          okType: "danger",
          cancelText: "No",
          title: "Are you sure you want to delete this user?",
          onOk() {
            const runAsync = async () => {
              const [data, , error] = await makeRequest({
                path: "/jql",
                method: "POST",
                config: {
                  data: {
                    __meta__: {
                      authenticationClass: AUTHENTICATION_CLASS,
                      namespace: "system.MDA",
                      schema: "model",
                      intent: Intents.deleteOne,
                      uniqueObject: {
                        id,
                      },
                    },
                  },
                },
              });

              if (error && error.errors && Array.isArray(error.errors)) {
                error.errors.map(({ error_message }) => {
                  return toast.error(error_message);
                });
              } else {
                toast.success(data.message);
              }
            };

            runAsync();
          },
        });
        break;

      default:
        break;
    }
  };

  const TableRow = ({ row }: { row: MdaType }) => {
    return (
      <tr>
        <td>{row.code}</td>
        <td>{row.name}</td>
        <td>{row.abbr}</td>
        <td>
          <ActionMenu id={row.id} keys={keys} clickHandler={handleMenuClick} />
        </td>
      </tr>
    );
  };

  return (
    <AdminWrapper>
      <DataTable
        columns={ColumnHeader}
        jqlQuery={jql}
        title={"List of MDAs"}
        description={<Link to="/app/admin/mdas/add">Add MDA</Link>}
        enableSearch
        searchPlaceholder={"search for a company"}
        RowComponent={TableRow}
        debug={true}
      />
    </AdminWrapper>
  );
};
