import React from "react"

export enum ChartTypes {
    up,
    down,
    steady,
}

export default ({ chartType }) => {
    if (chartType == ChartTypes.up) {
        return (
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="none">
                <path
                    d="M15.311 5.728a13.391 13.391 0 01-3.934 9.4 13.398 13.398 0 01-9.502 3.934.938.938 0 010-1.875 11.488 11.488 0 008.176-3.386 11.485 11.485 0 003.385-8.07l.939-1.252.936 1.248zm3.377 1.897a.938.938 0 00.187-1.313l-3.75-5a.938.938 0 00-1.5 0l-3.75 5a.938.938 0 001.5 1.125l3-4 3 4a.937.937 0 001.313.188z"
                    fill="#4C9A2A"
                />
            </svg>
        )
    } else if (chartType == ChartTypes.down) {
        return (
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="none">
                <path
                    d="M19.063 1.875a13.4 13.4 0 01-3.936 9.502 13.392 13.392 0 01-9.4 3.934l-1.248-.936 1.252-.939a11.485 11.485 0 008.07-3.385 11.487 11.487 0 003.386-8.176.938.938 0 011.875 0zM7.624 18.688a.937.937 0 00-.188-1.313l-4-3 4-3a.937.937 0 00-1.125-1.5l-5 3.75a.938.938 0 000 1.5l5 3.75a.938.938 0 001.313-.188z"
                    fill="#F80000"
                />
            </svg>
        )
    } else {
        return (
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none">
                <path d="M21.5 12l-4-4v3h-15v2h15v3l4-4z" fill="#054ADA" />
            </svg>
        )
    }
}