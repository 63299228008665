import React, { useContext } from "react";
import { Modal } from "antd";
import { Link } from "gatsby";
import toast from "react-hot-toast";
import { navigate } from "@reach/router";
import { FaCheck, FaEdit, FaTimes, FaUserEdit } from "react-icons/fa";

import AdminWrapper from "../index";
import { httpClient } from "utils/index";
import { makeRequest } from "utils/httpClient";
import { CompanyType } from "models/app/Profile";
import { Intents } from "common/enums";
import { CastJqlType, JqlQueryType } from "models/types/Services";
import DataTable from "components/generic/table/DataTable";
import ActionMenu from "components/generic/menu/ActionMenu";
import TrueFalseLabel from "components/generic/TrueFalseLabel";
import { ColumnHeader } from "components/pages/admin/companies/constants";
import { AUTHENTICATION_CLASS } from "common/constants";
import { AuthContext } from "contexts";

const keys = [
  {
    key: "key__companiesMenuItem__edit",
    label: "Edit Company",
    icon: FaEdit,
  },
  {
    key: "key__companiesMenuItem__delete",
    label: "Delete Company",
    icon: FaTimes,
  },
  {
    key: "key__companiesMenuItem__verify",
    label: "Verify Company",
    icon: FaCheck,
  },
  {
    key: "key__companiesMenuItem__manage_users",
    label: "Manage Associated Users",
    icon: FaUserEdit,
  },
];

const Companies = () => {
  const { confirm } = Modal;

  const jql: JqlQueryType<CastJqlType<CompanyType>> = {
    __meta__: {
      authenticationClass: AUTHENTICATION_CLASS,
      namespace: "profile.Company",
      schema: "model",
      intent: Intents.retrieve,
      side_effects: {
        global: true,
      },
    },
    id: null,
    name: null,
    uuid: null,
    registrationNumber: null,
    tin: null,
    phone1: null,
    phone2: null,
    email: null,
    website: null,
    verified: null,
    debts: [
      {
        id: null,
        amount_owed: null,
        amount_paid: null,
        amount_outstanding: null,
        debt_type: {
          name: null,
          mda: {
            name: null,
          },
        },
      },
    ],
  };



  const process = React.useCallback((actionItem) => {
    if (!actionItem) return;

    httpClient.post("/jql", {
      __meta__: {
        authenticationClass: AUTHENTICATION_CLASS,
        namespace: "profile.Company",
        schema: "controller",
        intent: actionItem.action,
      },
      company: actionItem.companyId,
    });
  }, []);

  // menu click handler
  function handleMenuClick(e, id) {
    switch (e.key) {
      case "key__companiesMenuItem__edit":
        navigate(`/app/admin/companies/${id}/edit`);
        break;

      case "key__companiesMenuItem__delete":
        confirm({
          okText: "Yes",
          centered: true,
          okType: "danger",
          cancelText: "No",
          title: "Are you sure you want to delete this company?",
          onOk() {
            const runAsync = async () => {
              const [data, , error] = await makeRequest({
                path: "/jql",
                method: "POST",
                config: {
                  data: {
                    __meta__: {
                      authenticationClass: AUTHENTICATION_CLASS,
                      namespace: "profile.Company",
                      schema: "model",
                      intent: Intents.deleteOne,
                      uniqueObject: {
                        id,
                      },
                    },
                  },
                },
              });

              if (error && error.errors && Array.isArray(error.errors)) {
                error.errors.map(({ error_message }) => {
                  return toast.error(error_message);
                });
              } else {
                toast.success(data.message);
              }
            };

            runAsync();
          },
        });
        break;

      case "key__companiesMenuItem__verify":
        navigate(`/app/admin/companies/${id}/verify`);
        break;

      case "key__companiesMenuItem__manage_users":
        navigate(`/app/admin/companies/${id}/corporate-representatives`);
        break;

      default:
        break;
    }
  }

  const TableRow = ({ row }: { row: CompanyType }) => {
    const mdaName = row?.debts?.[0];
    //@ts-ignore
    const debt_type = mdaName?.debt_type?.name;

    return (
      <tr>
        <td>{row.tin}</td>
        <td>
          <Link to={`/app/company/${row.id}`}>{row.name?.toUpperCase()}</Link>
        </td>

        <td>
          <TrueFalseLabel value={row.verified} />
        </td>
        <td>
          <ActionMenu id={row.id} keys={keys} clickHandler={handleMenuClick} />
        </td>
      </tr>
    );
  };

  return (
    <AdminWrapper>
      <DataTable
        columns={ColumnHeader}
        jqlQuery={jql}
        title={"List of Companies"}
        description={<Link to="/app/admin/companies/add">Add Company</Link>}
        enableSearch
        searchPlaceholder={"search for a company"}
        RowComponent={TableRow}
        debug={true}
      />
    </AdminWrapper>
  );
};

export default Companies;
