import React, { useContext } from "react";
import TextField from "components/auth/TextField";
import { Form, Formik } from "formik";
import toast from "react-hot-toast";
import { object, string, ref } from "yup";
import Button from "components/auth/Button";
import { AUTHENTICATION_CLASS } from "common/constants";
import { httpClient } from "utils";
import { AuthContext } from "contexts";

const validationSchema = object({
  oldPassword: string().required("Current password is required"),
  newPassword: string().required("New password is required"),
  confirmNewPassword: string()
    .oneOf([ref("newPassword"), null], "Passwords must match")
    .required("Passwords confirmation is required"),
});

const jqlQuery = (data: any) => ({
  __meta__: {
    namespace: "system.User",
    intent: "changePassword",
    schema: "controller",
    authenticationClass: AUTHENTICATION_CLASS,
  },
  ...data,
});

function ChangePassword() {
  const { currentUser } = useContext(AuthContext);

  const handleSubmit = async (data: any, { resetForm }) => {
    try {
      const res = await httpClient.post("/jql", jqlQuery({ user: currentUser?.id, ...data }));
      resetForm();
      toast.success(res?.data?.data?.message || "Password changed successfully");
    } catch ({ response }) {
      toast.error(response?.data?.errors?.[0]?.error_message || "Failed to change password");
    }
  };
  return (
    <div>
      {" "}
      <Formik
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
        initialValues={{
          oldPassword: "",
          newPassword: "",
          confirmNewPassword: "",
        }}
      >
        {({ isSubmitting }) => {
          return (
            <Form>
              <div className="mb-4">
                <TextField
                  label="Old Password"
                  name="oldPassword"
                  type="password"
                  placeholder="password"
                />
              </div>
              <div className="mb-4">
                <TextField
                  label="New Password"
                  name="newPassword"
                  type="password"
                  placeholder="password"
                />
              </div>
              <div className="mb-4">
                <TextField
                  label="Confirm New Password"
                  name="confirmNewPassword"
                  type="password"
                  placeholder="password"
                />
              </div>
              <div>
                <Button
                  label="Reset"
                  type="submit"
                  loading={isSubmitting}
                  disabled={isSubmitting}
                />
              </div>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
}

export default ChangePassword;
