import styled from 'styled-components'

export const CardWrapper = styled.div`
  border-radius: 0.3125rem;
  box-shadow: 0 1px 10px 1px rgba(0, 0, 0, 0.05);
  border-color: ${({ theme }) => theme.border_color};

  .card-header,
  .card-footer {
    background: ${({ theme }) => theme.white};
  }

  .custom-title-wrap {
    margin-top: 0.625rem;
    margin-left: -1.25rem;
    padding-left: 1.25rem;
    border-left: ${({ theme }) => `2px solid ${theme.primary_color}`};

    .custom-title {
      font-weight: 600;
      font-size: 0.875rem;
      font-family: 'Montserrat', sans-serif;
      color: ${({ theme }) => theme.dark_color};
    }
  }
`
