import React from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";
import ChartDataLabels from "chartjs-plugin-datalabels";

ChartJS.register(ArcElement, Tooltip, Legend);

const options = {
  plugins: {
    datalabels: {
      formatter: (value: number, ctx: { chart: { data: { datasets: { data: any }[] } } }) => {
        let sum = 0;
        const dataArr = ctx.chart.data.datasets[0].data;
        dataArr.map((data: number) => {
          sum += data;
        });
        const percentage = ((value * 100) / sum).toFixed(2) + "%";
        return percentage;
      },
      color: "#fff",
    },
    legend: {
      position: "right" as const,
      labels: {
        boxWidth: 20,
        boxHeight: 20,
      },
    },
  },
};
function AssetsCategorization({ data: analysis }) {
  const data = {
    labels: analysis?.map((item) => item.size) || [],
    datasets: [
      {
        label: "Debt analysis",
        data: analysis?.map((item) => item.amount) || [],
        backgroundColor: [
          "#4774C2",
          "#EB7D3C",
          "#A5A5A5",
          "#FEBF2D",
          "hsl(300, 100%, 35.62745098039216%)",
        ],
        borderCOlor: "transparent",
        borderWidth: 5,
      },
    ],
  };
  return (
    <div className="p-4">
      <div className="">
        <h4 className="fw-bold mb-2">Debt Analysis 2</h4>
        {/* <p className="text-muted">% of debt collected compared to the total debt owed</p> */}
      </div>
      <div className="pt-4 mx-auto" style={{ maxWidth: "100%" }}>
        <Doughnut data={data} options={options} plugins={[ChartDataLabels]} />
      </div>
    </div>
  );
}

export default AssetsCategorization;
