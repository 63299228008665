import React, { ReactNode } from "react";
import { useTable, usePagination } from "react-table";
import { TableWrapper, StyledTableWrapperFooter } from "components/app/styles/table";
import { TableHeaderType, TableOptionsType } from "components/generic/table/types";
import { Box } from "components/app/styles/generic";
import { StyledSearch } from "components/app/styles/form";
import { BiSearch } from "react-icons/bi";


type IProps = {
  columns: Array<TableHeaderType>;
  data: any;
  fetchData: any;
  loading: boolean;
  pageCount: number;
  dataCount: number;
  title?: string;
  description?: ReactNode;
  searchPlaceholder?: string;
  enableSearch?: boolean;
  RowComponent?: React.FC;
  onSearch?: (x: any) => void;
  setTableStateOptions?: (x: TableOptionsType) => void;
};

export default ({
  columns,
  data = [],
  fetchData,
  loading,
  pageCount: controlledPageCount,
  dataCount,
  title,
  description,
  searchPlaceholder,
  enableSearch,
  RowComponent,
  onSearch,
}: IProps) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    setTableStateOptions,
    // Get the state from the instance
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0 }, // Pass our hoisted table state
      manualPagination: true, // Use custom pagination
      pageCount: controlledPageCount,
    },
    usePagination,
  );

  // Listen for changes in pagination and use the state to fetch our new data
  React.useEffect(() => {
    setTableStateOptions && setTableStateOptions({ pageIndex, pageSize });
    fetchData({ pageIndex, pageSize });
  }, [fetchData, pageIndex, pageSize]);

  // Render the UI for your table
  return (
    <TableWrapper>
      <div className="top-wrapper">
        <div className="title-wrapper">
          {!!title && <div className="custom-title">{title}</div>}
          {!!description && <div className="custom-description">{description}</div>}
        </div>
        <div className="filter-wrapper"></div>

        <div className="search-wrapper">
          {enableSearch && (
            <Box>
              <StyledSearch
                prefix={<BiSearch />}
                placeholder={searchPlaceholder || "input search text"}
                allowClear
                onChange={(e) => {
                  onSearch && onSearch(e.target.value);
                }}
                style={{ width: 260 }}
              />
            </Box>
          )}
        </div>
      </div>

      <div className="table-wrapper">
        <table {...getTableProps()} className="table-responsive">
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th {...column.getHeaderProps()}>
                    {column.render("Header")}
                    <span>{column.isSorted ? (column.isSortedDesc ? " 🔽" : " 🔼") : ""}</span>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {page.map((row, i) => {
              prepareRow(row);
              if (RowComponent) return <RowComponent {...row.getRowProps()} row={data[i]} />;
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell) => {
                    return <td {...cell.getCellProps()}>{cell.render("Cell")}</td>;
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>

      {pageOptions.length > 1 && (
        <StyledTableWrapperFooter>
          <button onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
            {"<<"}
          </button>{" "}
          <button onClick={() => previousPage()} disabled={!canPreviousPage}>
            {"<"}
          </button>{" "}
          <button onClick={() => nextPage()} disabled={!canNextPage}>
            {">"}
          </button>{" "}
          <button onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
            {">>"}
          </button>{" "}
          <span>
            Page{" "}
            <strong>
              {pageIndex + 1} of {pageOptions.length}
            </strong>{" "}
          </span>
          <span>{`(Total: ${dataCount})`}</span>
        </StyledTableWrapperFooter>
      )}
    </TableWrapper>
  );
};
