import React, { FC } from 'react'
import { Field, useField, ErrorMessage } from 'formik'

import { TextAreaWrapper } from './styles'

type Props = {
  name: string
  rows?: number
  label?: string
  readOnly?: boolean
  placeholder?: string
}

const TextArea: FC<Props> = ({
  name,
  rows = 5,
  label = '',
  readOnly = false,
  placeholder = '',
}) => {
  const [_, meta] = useField<string>(name)
  const isInvalid = !!(meta.touched && meta.error)

  return (
    <TextAreaWrapper className="form-group">
      {label && <label htmlFor={name}>{label}</label>}

      <div className="form-inner-wrapper">
        <Field
          name={name}
          rows={rows}
          readOnly={readOnly}
          component="textarea"
          placeholder={placeholder}
          className={isInvalid ? 'form-control is-invalid' : 'form-control'}
        />
      </div>

      <ErrorMessage name={name}>
        {(errMsg) => <div className="invalid-feedback">{errMsg}</div>}
      </ErrorMessage>
    </TextAreaWrapper>
  )
}

export default TextArea
