import React from "react";
import { Viewer, Worker } from "@react-pdf-viewer/core";

import "@react-pdf-viewer/core/lib/styles/index.css";
import { Body } from "components/app/styles/typography";

type IProps = {
  fileUrl?: string;
};

export default ({ fileUrl }: IProps) => {
  if (!fileUrl) return <Body>No file to display</Body>;

  return (
    <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.6.347/build/pdf.worker.min.js">
      <Viewer fileUrl={fileUrl} />
    </Worker>
  );
};
