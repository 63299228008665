import styled from "styled-components";
import {FlexBoxType, GridType} from "./types";

export const Box = styled.div<FlexBoxType>`
    color: ${props => props.colour || 'inherit'};
    cursor: ${props => props.cursor || ''};
    width: ${props => props.width?.toString() + "px" || ""};
    height: ${props => props.height?.toString() + "px" || ""};
    min-width: ${props => props.minWidth?.toString() + "px" || ""};
    min-height: ${props => props.minHeight?.toString() + "px" || ""};
    max-width: ${props => props.maxWidth?.toString() + "px" || ""};
    max-height: ${props => props.maxHeight?.toString() + "px" || ""};
    margin: ${props =>
    (typeof props.margin === "number" ? props.margin.toString() + "px" : props.margin) ||
    ""};
    margin-top: ${props =>
    (typeof props.marginTop === "number"
        ? props.marginTop.toString() + "px"
        : props.marginTop) || ""};
    margin-right: ${props =>
    (typeof props.marginRight === "number"
        ? props.marginRight.toString() + "px"
        : props.marginRight) || ""};
    margin-bottom: ${props =>
    (typeof props.marginBottom === "number"
        ? props.marginBottom.toString() + "px"
        : props.marginBottom) || ""};
    margin-left: ${props =>
    (typeof props.marginLeft === "number"
        ? props.marginLeft.toString() + "px"
        : props.marginLeft) || ""};
    padding: ${props =>
    (typeof props.padding === "number" ? props.padding.toString() + "px" : props.padding) ||
    ""};
    padding-top: ${props =>
    (typeof props.paddingTop === "number"
        ? props.paddingTop.toString() + "px"
        : props.paddingTop) || ""};
    padding-right: ${props =>
    (typeof props.paddingRight === "number"
        ? props.paddingRight.toString() + "px"
        : props.paddingRight) || ""};
    padding-bottom: ${props =>
    (typeof props.paddingBottom === "number"
        ? props.paddingBottom.toString() + "px"
        : props.paddingBottom) || ""};
    padding-left: ${props =>
    (typeof props.paddingLeft === "number"
        ? props.paddingLeft.toString() + "px"
        : props.paddingLeft) || ""};
    background-color: ${props => props.backgroundColour || ""};
`;

export const FlexBox = styled(Box)<FlexBoxType>`
    display: flex;
    flex-direction: ${props => props.direction || "row"};
    align-items: ${props => props.alignItems || ""};
    justify-content: ${props => props.justifyContent || ""};
`;

export const GridBox = styled(Box)<GridType>`
  display: grid;
  grid-template-columns: repeat(${props => props.columns || 3}, 1fr);
  grid-gap: ${props =>
    (typeof props.paddingLeft === "number"
        ? props.paddingLeft.toString() + "px"
        : props.paddingLeft) || "20px 28px"};
`