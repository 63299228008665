import styled from 'styled-components'

export const WelcomeWrapper = styled.div`
  .debt-icon {
    width: 100%;
    height: auto;
    max-height: 10.5rem;
    object-fit: contain;
  }
`
