import React from "react";

import { Box, GridBox } from "components/app/styles/generic";
import { Field, Formik } from "formik";
import Select from "react-select";
import { Label, Row } from "components/pages/verify/styles";
import { Body, H5 } from "components/app/styles/typography";
import { THEME } from "common/theme";
import { SubmitButton } from "components/auth";
import cx from "classnames";
import MoonLoader from "react-spinners/MoonLoader";
import { CompanyType } from "models/app/Profile";
import httpClient from "common/client";
import { etranzactClient } from "common/client";
import { ETRANZACT_ENV, ETRANZACT_URLS } from "common/config";
import { DebtType } from "models/app/Debt";
import { formatSelectOptions } from "common/utils";
import { AUTHENTICATION_CLASS } from "common/constants";

type IProps = {
  company: Partial<CompanyType>;
  debts: Array<DebtType>;
};

export default ({ company, debts }: IProps) => {
  const [isLoading] = React.useState(false);
  const [isSubmitting, setSubmitting] = React.useState(false);
  const [debtAmount, setDebtAmount] = React.useState<string | null>(null);
  const debtOptions = formatSelectOptions(debts, "id", "debt_description");

  const handleSubmit = async (values) => {
    setSubmitting(true);
    try {
      const res = await httpClient.post("/jql", {
        __meta__: {
          authenticationClass: AUTHENTICATION_CLASS,
          namespace: "debt.Payment",
          schema: "controller",
          intent: "signPayment",
          sideEffects: {
            casing: "snakeCase",
          },
        },
        company: company.id,
        ...values,
      });
      setSubmitting(false);

      const signed_payload = res.data.data.signed_payload;

      // console.log("signed_payload", signed_payload);

      const payment_init = await etranzactClient({ mode: "testing" }).post(
        ETRANZACT_URLS[ETRANZACT_ENV].cardPaymentIntent,
        signed_payload,
      );
      window.location.href = payment_init.data.data.authorization_url;
    } catch (e) {
      setSubmitting(false);
    }
  };

  const getAmount = async (debt_id) => {
    const res = await httpClient.post("jql", {
      __meta__: {
        authenticationClass: AUTHENTICATION_CLASS,
        namespace: "debt.Debt",
        schema: "model",
        intent: "retrieveOne",
        uniqueObject: {
          id: debt_id,
        },
      },
      id: null,
      'amount_outstanding_str({concatenate: [currency, " ", amount_outstanding], transform: uppercase})': null,
    });
    setDebtAmount(res.data.data.amount_outstanding_str);
  };

  if (isLoading) return null;

  return (
    <Box margin={0}>
      <Formik
        initialValues={{
          fullname: "",
          email: "",
          phone_no: "",
          amount: "",
          debt: "",
        }}
        onSubmit={handleSubmit}
      >
        {({ handleSubmit, setFieldValue }) => (
          <form onSubmit={handleSubmit}>
            <Box>
              <Row>
                <H5 marginBottom={THEME.sizes.md} weight={"medium"}>
                  Payment Details
                </H5>

                <GridBox
                  backgroundColour={THEME.colors.grayA100}
                  padding={THEME.sizes.md}
                  marginBottom={THEME.sizes.md}
                  columns={2}
                >
                  <Label>
                    Select a Debt to Pay
                    <Select
                      name="debt"
                      placeholder=""
                      onChange={(event) => {
                        setFieldValue("debt", event.value);
                        getAmount(event.value);
                      }}
                      options={debtOptions}
                    />
                    {debtAmount && (
                      <Body marginTop={THEME.sizes.xs}>{`Amount Outstanding: ${debtAmount}`}</Body>
                    )}
                  </Label>
                </GridBox>

                <GridBox
                  backgroundColour={THEME.colors.grayA100}
                  padding={THEME.sizes.md}
                  marginBottom={THEME.sizes.md}
                  columns={2}
                >
                  <Label>
                    Full Name
                    <Field type="text" name="fullname" placeholder="" />
                  </Label>
                  <Label>
                    Email
                    <Field type="text" name="email" placeholder="" />
                  </Label>
                  <Label>
                    Phone
                    <Field type="text" name="phone_no" placeholder="" />
                  </Label>
                  <Label>
                    Amount
                    <Field type="number" name="amount" placeholder="" />
                  </Label>
                </GridBox>
              </Row>
              <SubmitButton
                className={cx({ loading: isSubmitting, verify: true })}
                type="submit"
                disabled={isSubmitting}
              >
                <MoonLoader color="#fff" size={15} loading={isSubmitting} />
                <span>Make Payment</span>
              </SubmitButton>
            </Box>
          </form>
        )}
      </Formik>
    </Box>
  );
};
