import styled from "styled-components";

export const PublicLayoutWrapper = styled.div`
  padding-top: 6.75rem;
  padding-bottom: 2.5rem;
  -webkit-font-smoothing: antialiased;
  background-color: ${({ theme }) => theme.dark_color};

  h1 {
    font-size: 2.25rem;
  }

  h4 {
    font-size: 1.125rem;
    color: ${({ theme }) => theme.primary_color};
  }

  h1,
  h4 {
    line-height: 1.2;
    font-weight: bold;
    margin-bottom: 0.5rem;
  }

  p {
    font-weight: 400;
    line-height: 1.575;
  }

  a {
    color: ${({ theme }) => theme.dark_color};
    &:hover {
      text-decoration: underline;
    }

    &.form-footer-link {
      color: ${({ theme }) => theme.secondary_color};
    }
  }

  .form-wrapper {
    width: 40%;
    float: left;
    padding: 3rem;
    border-radius: 10px;
    background: ${({ theme }) => theme.white};
  }

  .form-promo {
    order: 1;
    width: 60%;
    float: left;
    border-radius: 10px;
    min-height: 550px;
    background: ${({ theme }) => theme.primary_color};
    background: ${({ theme }) =>
      `linear-gradient(-45deg, ${theme.primary_color}, ${theme.secondary_color})`};
  }

  .form-promo-content {
    width: 100%;
    padding: 5rem;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }

  @media (max-width: 992px) {
    .form-wrapper,
    .form-promo {
      width: 100%;
      display: block;
      border-radius: 10px;
      margin-bottom: 1rem;
    }

    .form-wrapper,
    .form-promo-content {
      padding: 1.5rem;
    }

    .form-promo {
      min-height: 400px;
    }
  }
`;
