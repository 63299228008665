export const DEFAULT_PAGE_LIMIT = 10;

export const THEME = {
  colors: {
    grayA100: "#F5F5F7",
    gray50: "#EFEFEF",
    gray100: "#EEE",
    gray200: "#DDD",
    gray300: "#CCC",
    gray400: "#AAA",
    gray500: "#999",
    gray600: "#777",
    gray700: "#555",
    gray800: "#333",
    dark: "#222",
    black: "#000",
    white: "#FFF",
    green: "#156148",
    link: "#156148",
    darkRed: "#C00",
  },
  sizes: {
    xss: 4,
    xs: 6,
    sm: 8,
    md: 16,
    lg: 24,
    xl: 32,
    xxl: 48,
  },
  fonts: {
    primary: `"Segoe UI", Roboto, "Helvetica Neue", Arial`,
  },
  fontSizes: {
    caption: 10,
    small: 12,
    normal: 14,
    h6: 16,
    h5: 18,
    h4: 20,
    h3: 24,
    h2: 38,
    h1: 32,
    title: 36,
  },
  fontColours: {
    lighter: "#AAA",
    light: "#777",
    dark: "#333",
    darker: "#000",
  },
};
