import React from "react";
import AuthLayout from "components/auth/AuthLayout";
import Tabs from "components/auth/Tabs";
import ChangePassword from "./ChangePassword";

function Profile() {
  return (
    <AuthLayout title="My Profile">
      <div className="col-lg-8 mb-4 mb-lg-0">
        <div>
          <Tabs>
            <div label="Reset Password">
              <ChangePassword />
            </div>
            <div label="">
            </div>
          </Tabs>
        </div>
      </div>
    </AuthLayout>
  );
}

export default Profile;
