import React, { useContext, useEffect, useState } from "react";
import { Button as AntButton, Modal } from "antd";
import { Formik, Form } from "formik";
import { Link } from "gatsby";
import toast from "react-hot-toast";
import { FaTimes } from "react-icons/fa";
import AdminWrapper from "../index";
import { ColumnHeader } from "./constants";
import { makeRequest } from "utils/httpClient";
import { Intents } from "common/enums";
import { CastJqlType, JqlQueryType } from "models/types/Services";
import DataTable from "components/generic/table/DataTable";
import ActionMenu from "components/generic/menu/ActionMenu";
import { StyledButton } from "components/app/styles/components";
import { AUTHENTICATION_CLASS, MdaAdmin } from "common/constants";
import { BiEditAlt } from "react-icons/bi";
import { MdAddTask } from "react-icons/md";
import AutoComplete from "components/auth/Autocomplete";
import * as yup from "yup";
import { navigate } from "@reach/router";
import * as Yup from "yup";
import axios from "axios";

import Button from "components/auth/Button";
import TextField from "components/auth/TextField";
import { AuthContext } from "contexts";
import { validate } from "gatsby/graphql";
const initialValues = {
  mda: null,
  name: "",
};

// const validationSchema = yup.object({
//   mda: yup.string().required("MDA is required"),
//   name: yup.string().required("Name is required"),
// });

const validationSchema = Yup.object().shape({
  debtType: Yup.string().required("Debt Type is required"),
  company: Yup.string().required("Company is required"),
  amountOutstanding: Yup.number()
    .typeError("Amount Outstanding must be a number")
    .min(0, "Amount Outstanding must be greater than or equal to 0")
    .required("Amount Outstanding is required"),
});

const MDAAdminkeys = [
  {
    key: "key__debtMenuItem__verify",
    label: "Verify Debt",
    icon: MdAddTask,
  },
  {
    key: "key__debtMenuItem__edit",
    label: "Edit Debt",
    icon: BiEditAlt,
  },
  {
    key: "key__debtMenuItem__delete",
    label: "Delete Debt",
    icon: FaTimes,
  },
];

const keys = [
  {
    key: "key__debtMenuItem__delete",
    label: "Delete Debt",
    icon: FaTimes,
  },
];

export default () => {
  const [showUpdateDebt, setShowUpdateDebt] = useState(false);
  const [selectedId, setSelectedId] = useState("");
  const { confirm } = Modal;
  const { isLoggedIn, logout, currentUser } = useContext(AuthContext);
  //@ts-ignore
  const jql: JqlQueryType<CastJqlType<any>> = {
    __meta__: {
      authenticationClass: AUTHENTICATION_CLASS,
      namespace: "debt.Debt",
      schema: "model",
      intent: Intents.retrieve,
      filter: {
        company: {
          isnull: false,
        },
      },
    },
    id: null,
    debtType: {
      id: null,
      name: null,
      mda: {
        id: null,
        name: null,
      },
    },
    company: {
      id: null,
      name: null,
    },
    amountOwed: null,
    amountPaid: null,
    amountOutstanding: null,
    currency: null,
  };

  const handleEditDebt = (id: any) => {
    setShowUpdateDebt(true);
    setSelectedId(id);
  };

  // menu click handler
  const handleMenuClick = (e, id) => {
    switch (e.key) {
      case "key__debtMenuItem__delete":
        return confirm({
          okText: "Yes",
          centered: true,
          okType: "danger",
          cancelText: "No",
          title: "Are you sure you want to delete this debt?",
          onOk() {
            const runAsync = async () => {
              const [data, , error] = await makeRequest({
                path: "/jql",
                method: "POST",
                config: {
                  data: {
                    __meta__: {
                      authenticationClass: AUTHENTICATION_CLASS,
                      namespace: "debt.Debt",
                      schema: "model",
                      intent: Intents.deleteOne,
                      uniqueObject: {
                        id,
                      },
                    },
                  },
                },
              });

              if (error && error.errors && Array.isArray(error.errors)) {
                error.errors.map(({ error_message }) => {
                  return toast.error(error_message);
                });
              } else {
                toast.success(data.message);
              }
            };

            runAsync();
          },
        });
      case "key__debtMenuItem__verify":
        return "";
      case "key__debtMenuItem__edit":
        return handleEditDebt(id);

      default:
        break;
    }
  };

  const TableRow = ({ row }) => {
    return (
      <tr>
        <td>
          <strong>{row.debtType.name}</strong>
          <br />
          <span>{row.debtType.mda.name}</span>
        </td>
        <td>{row.company.name}</td>
        <td align={"right"}>
          {row.currency.toUpperCase()}
          {row.amountOutstanding.toLocaleString("en-GB")}
        </td>
        <td>
          <a href={`/app/admin/debts/${row.id}/log-debt`}>
            <StyledButton>Log Debt</StyledButton>
          </a>
        </td>
        <td>
          {MdaAdmin.indexOf(currentUser?.userType as string) > -1 ? (
            <ActionMenu id={row.id} keys={MDAAdminkeys} clickHandler={handleMenuClick} />
          ) : (
            <ActionMenu id={row.id} keys={keys} clickHandler={handleMenuClick} />
          )}
        </td>
      </tr>
    );
  };

  return (
    <AdminWrapper>
      <DataTable
        columns={ColumnHeader}
        jqlQuery={jql}
        title={"List of Debt"}
        description={<Link to="/app/admin/debts/configure">Configure Debt</Link>}
        enableSearch
        searchPlaceholder={"search for debt"}
        RowComponent={TableRow}
        debug={true}
      />

      <UpdateDebtValue
        onCancel={() => setShowUpdateDebt(false)}
        updateDebt={showUpdateDebt}
        id={selectedId}
      />
    </AdminWrapper>
  );
};

const defaultInitialVals = {
  debtType: "",
  debtTypeString: "",
  mda: "",
  amountOutstanding: "",
  company: "",
  companyString: "",
  currency: "",
};

const UpdateDebtValue = ({ updateDebt, onCancel, id }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [initialValues, setInitialValues] = useState(defaultInitialVals);
  const [debt, setDebt] = useState("");
  const [coy, setCoy] = useState("");

  useEffect(() => {
    if (id !== "") {
      const runAsync = async () => {
        const [data, error] = await makeRequest({
          path: "/jql",
          method: "POST",
          config: {
            data: {
              __meta__: {
                authenticationClass: AUTHENTICATION_CLASS,
                intent: "retrieveOne",
                namespace: "debt.Debt",
                schema: "model",
                uniqueObject: {
                  id,
                },
              },
              id: null,
              debtType: {
                id: null,
                name: null,
                mda: {
                  id: null,
                  name: null,
                },
              },
              company: {
                id: null,
                name: null,
              },
              amountOwed: null,
              amountPaid: null,
              amountOutstanding: null,
              currency: null,
            },
          },
        });

        const { amountOutstanding, company, debtType, currency } = data.data;
        setInitialValues({
          debtType: debtType?.id,
          mda: debtType?.mda?.name,
          amountOutstanding: amountOutstanding,
          company: company?.id,
          currency: currency,
          debtTypeString: debtType?.name,
          companyString: company?.name,
        });
        setCoy(company?.name);
        setDebt(debtType?.name);
      };

      runAsync();
    }
  }, [id]);

  // console.log(coy);
  // console.log(debt);
  const handleDebtUpdate = async (values) => {
    setIsLoading(true);

    const [data, , error] = await makeRequest({
      path: "/jql",
      method: "POST",
      config: {
        data: {
          __meta__: {
            authenticationClass: AUTHENTICATION_CLASS,
            intent: Intents.update,
            namespace: "debt.Debt",
            uniqueObject: {
              id,
            },
            schema: "model",
            return: {
              id: null,
            },
          },

          amount_owed: values?.amountOutstanding,
          company: values?.company,
          currency: "ngn",
          debt_type: values?.debtType,
        },
      },
    });

    if (error && error.errors && Array.isArray(error.errors)) {
      error.errors.map(({ error_message }) => {
        return toast.error(error_message);
      });
    } else {
      toast.success(data.message);
      // redirect
    }
    setIsLoading(false);
    onCancel();
  };

  // console.log(Object.prototype.toString.call(coy), "initialValues");

  return (
    <Modal
      title={`Edit ${initialValues?.companyString}'s Information`}
      open={updateDebt}
      onCancel={onCancel}
      footer={null}
    >
      <Formik
        enableReinitialize
        onSubmit={(values, { setSubmitting }) => {
          handleDebtUpdate(values);
          setSubmitting(false);
        }}
        initialValues={initialValues}
        validationSchema={validationSchema}
      >
        {({
          values,
          touched,
          errors,
          dirty,
          isValid,
          isSubmitting,
          handleChange,
          handleBlur,
          handleSubmit,
          handleReset,
        }) => (
          <Form>
            <div className="row">
              <div className="col-12 ">
                <AutoComplete
                  name="debtType"
                  label="Debt Type"
                  namespace="debt.DebtType"
                  resultObj={{
                    id: null,
                    name: null,
                    mda: {
                      id: null,
                      name: null,
                    },
                  }}
                  resultCallback={({ id, name, mda }) => ({
                    label: `${name} - ${mda.name}`,
                    value: id,
                  })}
                  defaultOptions={[{ label: debt, value: "" }]}
                  defaultValue={[{ label: debt, value: "" }]}
                />
              </div>
              {/* <div className="col-12">
                <AutoComplete
                  name="mda"
                  label="MDA"
                  namespace="system.MDA"
                  resultCallback={({ id, name }) => {
                    return { label: name, value: id };
                  }}
                  defaultOptions={[{ label: values?.mda, value: values?.mda }]}
                />
              </div> */}
              <div className="col-12">
                <AutoComplete
                  name="company"
                  label="Company"
                  namespace="profile.Company"
                  defaultOptions={[{ label: values?.companyString, value: "" }]}
                  defaultValue={[
                    {
                      label: values?.companyString,
                      value: values?.company,
                    },
                  ]}
                />
              </div>
              <div className="col-12">
                <TextField
                  label="Amount Outstanding"
                  name="amountOutstanding"
                  placeholder="amountOutstanding"
                />
              </div>
            </div>
            <div className="text-right">
              <AntButton key="back" onClick={onCancel} className="mr-4 mt-3">
                Cancel
              </AntButton>

              <AntButton key="submit" htmlType="submit" type="primary" loading={isLoading}>
                Update
              </AntButton>
            </div>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};
