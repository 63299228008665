import React, { FC, useState, Children } from "react";
import { useLocation } from "@reach/router";
import { TabsWrapper } from "./styles";
import { AuthContext } from "contexts";

const Tabs: FC = ({ children }: any) => {
  const defaultActiveTab = children?.[0]?.props?.label;
  const { currentUser } = React.useContext(AuthContext);
  const [activeTab, setActiveTab] = useState(() => {
    if (currentUser?.userType === "ADMIN") {
      return "Debt Portfolio";
    }
    return defaultActiveTab || "";
  });

  const handleClick = (label: string) => {
    setActiveTab(label);
  };

  return (
    <TabsWrapper>
      <ul className="tab-nav-wrapper" role="tablist">
        {Children.map(children, (child: any) => {
          const label = child?.props?.label;
          const isExcluded =
            currentUser?.userType === "MDA_USER" ||
            (currentUser?.userType === "ADMIN" &&
              (label === "Settings" || label === "Company Settings" || label === "Account"));
          if (!label) {
            return <></>;
          }
          return (
            !isExcluded && (
              <li
                className={label === activeTab ? "active" : undefined}
                onClick={() => handleClick(label)}
              >
                {label}
              </li>
            )
          );
        })}
      </ul>

      <div className="tab-content-wrapper">
        {Children.map(children, (child: any) => {
          const label = child?.props?.label;
          if (label !== activeTab) return undefined;
          return child.props.children;
        })}
      </div>
    </TabsWrapper>
  );
};

export default Tabs;
