import React, { FC, useState, useEffect } from "react";
import { RouteComponentProps, navigate } from "@reach/router";
import toast from "react-hot-toast";

import AdminWrapper from "../index";
import CompanyForm from "./CompanyForm";
import { makeRequest } from "utils/httpClient";
import { Intents } from "common/enums";
import { AUTHENTICATION_CLASS } from "common/constants";

const defaultInitialVals = {
  name: "",
  email: "",
  tin: "",
  registrationNumber: "",
  phone1: "",
  phone2: "",
  line1: "",
  line2: "",
  city: "",
  state: "",
  website: "",
};

const breadcrumbs = [
  { label: "Home", link: "/app/welcome" },
  { label: "Companies", link: "/app/admin/companies" },
  { label: "Edit Company" },
];

const EditCompany: FC<RouteComponentProps<{ id: string }>> = ({ id }) => {
  const [initialValues, setInitialValues] = useState(defaultInitialVals);

  useEffect(() => {
    let isMounted = true;

    const runAsync = async () => {
      const [data, , error] = await makeRequest({
        path: "/jql",
        method: "POST",
        config: {
          data: {
            __meta__: {
              authenticationClass: AUTHENTICATION_CLASS,
              namespace: "profile.Company",
              schema: "model",
              intent: Intents.retrieveOne,
              uniqueObject: {
                id,
              },
            },
            name: null,
            email: null,
            tin: null,
            registrationNumber: null,
            phone1: null,
            phone2: null,
            line1: null,
            line2: null,
            city: null,
            state: null,
            website: null,
          },
        },
      });

      if (isMounted) {
        setInitialValues(data.data);
      }

      //! handle error
    };

    runAsync();

    return () => {
      isMounted = false;
    };
  }, [id]);

  const handleSubmit = async (payload) => {
    const [data, , error] = await makeRequest({
      path: "/jql",
      method: "POST",
      config: {
        data: {
          __meta__: {
            authenticationClass: AUTHENTICATION_CLASS,
            namespace: "profile.Company",
            schema: "model",
            intent: Intents.update,
            uniqueObject: {
              id,
            },
          },
          ...payload,
        },
      },
    });

    if (error && error.errors && Array.isArray(error.errors)) {
      error.errors.map(({ error_message }) => {
        return toast.error(error_message);
      });
    } else {
      toast.success(data.message);
      navigate("/app/admin/companies");
    }
  };

  return (
    <AdminWrapper title="Edit Company" breadcrumbs={breadcrumbs}>
      <CompanyForm handleSubmit={handleSubmit} initialValues={initialValues} />
    </AdminWrapper>
  );
};

export default EditCompany;
