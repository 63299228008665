import React, { useMemo, useState } from "react";
import { AUTHENTICATION_CLASS } from "common/constants";
import numeral from "numeral";
import TableUnstyled from "components/TableUnstyled";
import useSWR from "swr";
import { Input } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { debounce } from "lodash";

const pageSize = 6;
function CompaniesWithinDebtRange({ activeRow }) {
  const [pageIndex, setPageIndex] = useState(0);
  const [searchString, setSearchString] = useState("");
  const [, gte, lte] = activeRow?.id?.split(".") || [];

  const jql = useMemo(
    () => ({
      __meta__: {
        authenticationClass: AUTHENTICATION_CLASS,
        filter: {
          amountOutstanding: {
            gte,
            lte,
          },
          company: {
            "name.icontains": searchString,
          },
        },
        intent: "retrieve",
        namespace: "debt.DebtorCorporate",
        schema: "model",
        limit: pageSize,
        offset: pageSize * pageIndex,
      },
      id: null,
      amountOwed: null,
      amountPaid: null,
      amountOutstanding: null,
      currency: null,
      company: {
        name: null,
      },
    }),
    [gte, lte, pageIndex, searchString],
  );
  const { data } = useSWR(jql, { keepPreviousData: true });

  const handleSearch = React.useCallback(
    debounce((searchString: string) => {
      setSearchString(searchString);
    }, 500),
    [],
  );

  const columns = [
    {
      Header: (
        <div className="d-flex align-items-center">
          <span>Company</span>
          <Input
            onChange={(evt) => {
              handleSearch(evt.target.value);
            }}
            className="ml-3"
            style={{ maxWidth: "300px" }}
            allowClear
            placeholder="Search company..."
            prefix={<SearchOutlined />}
          />
        </div>
      ),
      accessor: "company",
      Cell: ({ value }) => <div className="">{value.name}</div>,
    },
    {
      Header: () => (
        <div className="text-right">
          Amount Owed <br /> (NGN)
        </div>
      ),
      accessor: "amountOwed",
      Cell: ({ value }) => <div className="text-right">{numeral(value).format("0,0.00")}</div>,
    },
    {
      Header: () => (
        <div className="text-right">
          Amount Outstanding <br /> (NGN)
        </div>
      ),
      accessor: "amountOutstanding",
      Cell: ({ value }) => <div className="text-right">{numeral(value).format("0,0.00")}</div>,
    },
  ];

  return (
    <div>
      <TableUnstyled
        columns={columns}
        data={data?.data || []}
        paginate
        onPageClick={(page) => {
          setPageIndex(page);
        }}
        pageSize={pageSize}
        totalCount={data?.extras?.filtered || 0}
      />
    </div>
  );
}

export default CompaniesWithinDebtRange;
