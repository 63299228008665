import React, { useState } from "react";
import { FaCheck, FaTimes } from "react-icons/fa";
import { GrUserAdmin, ImBin } from "react-icons/all";
import ActionMenu from "components/generic/menu/ActionMenu";
import { Profile_CompanyRepresentativeType } from "models/db-schema";
import { httpClient } from "utils/index";
import { JQL_ENDPOINT } from "common/config";
import client from "common/client";
import { AUTHENTICATION_CLASS } from "common/constants";

const keys = [
  {
    key: "key__menuItem__approve",
    label: "Verify",
    icon: FaCheck,
  },
  {
    key: "key__menuItem__disapprove",
    label: "Un-verify",
    icon: FaTimes,
  },
  {
    key: "key__menuItem__make_admin",
    label: "Make Admin",
    icon: GrUserAdmin,
  },
  {
    key: "key__menuItem__remove_admin",
    label: "Remove as Admin",
    icon: FaTimes,
  },
  {
    key: "key__menuItem__disassociate",
    label: "Disassociate",
    icon: ImBin,
  },
];

type IProps = {
  representative: Profile_CompanyRepresentativeType;
  buttonLabel?: string;
  draw: number;
  setDraw: (x: number) => void;
};

export default ({ representative, buttonLabel, draw, setDraw }: IProps) => {
  // process the action button
  const process = React.useCallback(
    (representativeAction) => {
      if (!representativeAction) return;

      client
        .post(JQL_ENDPOINT, {
          __meta__: {
            authenticationClass: AUTHENTICATION_CLASS,
            namespace: "profile.CompanyRepresentative",
            schema: "controller",
            intent: representativeAction.action,
          },
          representative: representativeAction.representativeId,
        })
        .then(() => {
          setDraw(draw + 1);
        });
    },
    [draw],
  );

  // based on the key in the menu clicked - handle appropriately
  const handleMenuClick = (e, id) => {
    switch (e.key) {
      case "key__menuItem__approve":
        process({ representativeId: id, action: "verify" });
        break;
      case "key__menuItem__disapprove":
        process({ representativeId: id, action: "unverify" });
        break;
      case "key__menuItem__make_admin":
        process({ representativeId: id, action: "make_admin" });
        break;
      case "key__menuItem__remove_admin":
        process({ representativeId: id, action: "remove_admin" });
        break;
      case "key__menuItem__disassociate":
        process({ representativeId: id, action: "disassociate" });
        break;
      default:
        break;
    }
  };

  return (
    <ActionMenu
      id={representative.id}
      keys={keys}
      buttonLabel={buttonLabel}
      clickHandler={handleMenuClick}
    />
  );
};
