import React, { useState, useEffect } from "react";
import { JqlQueryType } from "models/types/Services";
import { JqlService } from "common/index";

type IProps<T> = {
  jqlQuery: JqlQueryType;
};

export default <T = any>({ jqlQuery }: IProps<T>) => {
  const [isLoading, setLoading] = useState<boolean>(false);
  const [data, setData] = React.useState<T>();

  useEffect(() => {
    (async () => {
      setLoading(true);
      try {
        const response = await JqlService.post(JqlService.JQL_ENDPOINT, jqlQuery);
        setData(response.data.data as T);
      } catch (error) {
        // console.log("ERROR WRROR",error);
      } finally {
        setLoading(false);
      }
    })();
  }, []);

  return {
    data,
    isLoading,
  };
};
