import React, { ReactNode } from "react";
import toast from "react-hot-toast";
import { Table } from "components/generic/index";

import { StyledTableWrapper } from "components/app/styles/table";
import { makeRequest } from "utils/httpClient";
import { TableHeaderType, TableOptionsType } from "components/generic/table/types";
import { CastJqlType } from "models/types/Services";
import { DEFAULT_PAGE_LIMIT } from "common/constants";

export type DataTableType = {
  columns: Array<TableHeaderType>;
  jqlQuery: CastJqlType<{}>;
  limit?: number;
  title?: string;
  description?: ReactNode;
  searchPlaceholder?: string;
  enableSearch?: boolean;
  RowComponent?: React.FC<any>;
  debug?: boolean;
  draw?: number;
};

export default ({
  columns,
  limit,
  jqlQuery,
  title,
  description,
  searchPlaceholder,
  enableSearch,
  RowComponent,
  debug = false,
  draw = 0,
}: DataTableType) => {
  const ColumnComponent = React.useMemo(() => columns, []);

  // We'll start our table without any data
  const [data, setData] = React.useState<Array<Object>>([]);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [pageCount, setPageCount] = React.useState<number>(0);
  const [dataCount, setDataCount] = React.useState<number>(0);
  const [searchText, setSearchText] = React.useState<string>("");
  const [tableStateOptions, setTableStateOptions] = React.useState<TableOptionsType>(
    {} as TableOptionsType,
  );

  const fetchIdRef = React.useRef(0);

  const fetchData = React.useCallback(
    async ({ pageSize, pageIndex }) => {
      // This will get called when the table needs new data
      // You could fetch your data from literally anywhere,
      // even a server. But for this example, we'll just fake it.

      // Give this fetch an ID
      const fetchId = ++fetchIdRef.current;

      // Set the loading state
      setLoading(true);

      // make search meta fragment
      let searchFragment = {};
      if (searchText) {
        searchFragment = {
          search: {
            value: searchText,
          },
        };
      }

      const [data, , error] = await makeRequest({
        path: "/jql",
        method: "POST",
        config: {
          data: {
            ...jqlQuery,
            __meta__: {
              ...jqlQuery.__meta__,
              limit: limit || DEFAULT_PAGE_LIMIT,
              offset: pageSize * pageIndex,
              ...searchFragment,
            },
          },
        },
      });

      if (error && error.errors && Array.isArray(error.errors)) {
        error.errors.map(({ error_message }) => {
          return toast.error(error_message);
        });
        return;
      }

      // log out the data if we are in debug mode
      if (debug) {
        // console.log("LOGGING TABLE JQL DATA...");
      }

      // Only update the data if this is the latest fetch
      if (fetchId === fetchIdRef.current) {
        setData(data?.data);
        setPageCount(Math.ceil(data?.extras?.filtered / pageSize));
        setDataCount(data?.extras?.filtered);
        setLoading(false);
      }
    },
    [searchText, draw],
  );

  const searchHandler = (e: string) => {
    setSearchText(e);
  };

  return (
    <Table
      columns={ColumnComponent}
      data={data || []}
      fetchData={fetchData}
      loading={loading}
      pageCount={pageCount}
      dataCount={dataCount}
      title={title}
      description={description}
      enableSearch
      searchPlaceholder={searchPlaceholder}
      RowComponent={RowComponent}
      onSearch={searchHandler}
      setTableStateOptions={setTableStateOptions}
    />
  );
};
