import React from "react";
import { Menu, Space } from "antd";
import { FlexBox } from "components/app/styles/generic";
import { Body } from "components/app/styles/typography";
import { THEME } from "common/theme";
import { StyledDropdownButton } from "components/app/styles/components";
import { KeyType } from "./types";

type IProps = {
  id: string | number;
  keys: Array<KeyType>;
  buttonLabel?: string;
  clickHandler: (...x: any) => void;
};

const DropdownMenu = ({ id, clickHandler, keys }: IProps) => (
  <Menu onClick={(e) => clickHandler(e, id)}>
    {keys.map((key, i) => (
      <Menu.Item key={key.key}>
        <FlexBox alignItems={"center"}>
          <key.icon /> <Body marginLeft={THEME.sizes.sm}>{key.label}</Body>
        </FlexBox>
      </Menu.Item>
    ))}
  </Menu>
);

export default (x: IProps) => {
  return (
    <Space wrap>
      <StyledDropdownButton overlay={DropdownMenu(x)}>{x.buttonLabel || ""}</StyledDropdownButton>
    </Space>
  );
};
