import styled from "styled-components";

export const AuthLayoutWrapper = styled.div`
  padding-top: 6.75rem;
  padding-bottom: 2.5rem;
  -webkit-font-smoothing: antialiased;
  background-color: ${({ theme }) => theme.light_color};

  .menu-wrapper {
    margin-top: -3rem;
    padding: 1rem 0;

    .menu-item {
      min-width: fit-content;

      a {
        font-size: 0.875rem;
        text-decoration: none;
        padding: 0.35rem 0.25rem;
        border-radius: 0.25rem;
        transition: all 0.3s ease;
        color: ${({ theme }) => theme.black_light};

        &:hover {
          color: ${({ theme }) => theme.white};
          background-color: ${({ theme }) => theme.primary_color};
        }

        &.active {
          color: ${({ theme }) => theme.white};
          background-color: ${({ theme }) => theme.primary_color};
        }
      }
    }
  }

  .page-title {
    line-height: 1.2;
    font-weight: 500;
    font-size: 1.125rem;
    margin-bottom: 0.5rem;
  }

  .breadcrumb {
    margin-bottom: 0;
    background: transparent;

    .breadcrumb-item {
      padding-left: 0;
      font-size: 0.8125rem;
    }

    a {
      color: ${({ theme }) => theme.gray};

      &:hover {
        color: ${({ theme }) => theme.primary_color};
      }
    }
  }

  .breadcrumb-item.active {
    color: ${({ theme }) => theme.gray_dark};
  }

  .breadcrumb-item + .breadcrumb-item::before {
    content: "\f105";
    font-weight: 900;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    display: inline-block;
    font-family: "Font Awesome 5 Free";
    color: ${({ theme }) => theme.gray_light};
  }

  /* fonts start */
  h4 {
    font-size: 1.125rem;
  }

  h4 {
    line-height: 1.2;
    font-weight: bold;
    margin-bottom: 0.5rem;
  }

  p {
    font-weight: 400;
    line-height: 1.575;
  }

  a {
    color: ${({ theme }) => theme.secondary_color};
    &:hover {
      text-decoration: underline;
    }
  }
  /* fonts end */

  /* table reset start */
  .table {
    thead th {
      vertical-align: bottom;
      border-bottom: ${({ theme }) => `0px solid ${theme.border_color}`};
    }

    &.table-striped tbody tr:nth-of-type(odd) {
      background-color: rgba(0, 0, 0, 0.02);
    }
  }

  /* table reset end */

  @media (min-width: 768px) {
    .menu-wrapper {
      margin-top: -2.15rem;

      .menu-item {
        &:not(:last-child) {
          margin-right: 0.5rem;
        }

        a {
          padding: 0.5rem 1rem;
        }
      }
    }
  }
`;
