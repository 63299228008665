export enum Pages {
    account = "account",
    debtPortfolio = "debt-portfolio",
    taxInformation = "tax-information",
    settings = "settings",
    companySettings = "company-settings",
    paymentDirectDebit = "payment-direct-debit",
    paymentCard = "payment-card",
    paymentCardConfirmation = "payment-card-confirmation",
}

export interface PortalTabProps {
    companyId: number | string
    activeTab: Pages
    goto: (urlPage: Pages) => void
}

export type PageTypes = {}