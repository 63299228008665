import axios, { Method, AxiosRequestConfig } from "axios";
import { JqlService } from "common/client";

type EtranzactCardType = {
  mode: "testing" | "production";
  method?: "cardPayment" | "cardPaymentQuery";
};

type RequestType = {
  path: string;
  auth?: boolean;
  method?: Method;
  config?: AxiosRequestConfig;
};

declare var ENVIRONMENT: string;

export const etranzactClient = ({ mode }: EtranzactCardType) =>
  axios.create({
    headers: {
      "Api-Key":
        "umWpFCOyVktReQ43Hg56TPiYaqT762qoCNsCQaICJkgXZkPeyz2EEMyLnpTfaNLYjF01UIUTReq45Re46",
      "Secret-Key": "DEMO_KEY",
      "Terminal-Id": "0000000001",
    },
  });

export const makeRequest = async ({
  path,
  config = {},
  auth = true,
  method = "POST",
}: RequestType) => {
  let data, status, error;

  try {
    const response = await JqlService(path, {
      method,
      ...config,
    });

    data = response.data;
    status = response.status;
  } catch ({ response }) {
    status = response.status;
    error = response.data;
  }

  return [data, status, error];
};

export default JqlService;
