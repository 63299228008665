import React, { useState, useCallback, useEffect } from "react";
import { Field, Formik } from "formik";
import toast from "react-hot-toast";

import { httpClient } from "utils/index";
import Button from "components/auth/Button";
import { makeRequest } from "utils/httpClient";
import TextField from "components/auth/TextField";
import FileField from "components/auth/FileField";
import { Box } from "components/app/styles/generic";
import { THEME } from "common/theme";
import { Intents } from "common/enums";
import SelectField from "components/auth/SelectField";
import InfoSection from "components/auth/InfoSection";
import { H5, Small } from "components/app/styles/typography";
import { DetailsRow, FileRow, Label, Row } from "components/pages/verify/styles";
import { CaseComponent, SwitchComponent } from "components/generic/switch/index";
import { CompanyDocumentStatusType, CompanyDocumentType, CompanyType } from "models/app/Profile";
import { AUTHENTICATION_CLASS } from "common/constants";

type IProps = {
  company: CompanyType;
  states: Array<{
    label: string;
    value: string;
  }>;
};

export default ({ company, states }: IProps) => {
  const [_company] = useState<Partial<CompanyType>>({
    name: company.name,
    tin: company.tin,
    registrationNumber: company.registrationNumber,
    website: company.website,
    email: company.email,
    phone1: company.phone1,
    line1: company.line1,
    line2: company.line2,
    city: company.city,
    state: company.state,
  });
  const [isLoading, setLoading] = useState<boolean>(false);
  const [isSubmitting, setSubmitting] = useState<boolean>(false);
  const [files, setFiles] = useState<Object>({});

  const update = useCallback(async () => {
    try {
      setLoading(true);

      const [data, , error]: any = await makeRequest({
        path: "/jql",
        method: "POST",
        config: {
          data: {
            __meta__: {
              authenticationClass: AUTHENTICATION_CLASS,
              namespace: "profile.CompanyDocument",
              schema: "model",
              intent: Intents.retrieve,
              filter: {
                company: company.id,
              },
            },
            id: null,
            name: null,
            file: null,
            comment: null,
            status: null,
          },
        },
      });

      if (error && error.errors && Array.isArray(error.errors)) {
        error.errors.map(({ error_message }) => {
          return toast.error(error_message);
        });
        return;
      }

      const documents = data.data;
      const _files = {};
      documents.map((document: CompanyDocumentType) => {
        _files[document.name] = {
          id: document.id,
          status: document.status,
          url: document.file,
        };
      });
      setFiles(_files);
    } catch (err) {
      toast.error(err.message);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    update();
  }, []);

  const handleFileUpload = (evt) => {
    const imageInput = evt.target;
    const file = imageInput.files[0];

    httpClient
      .put(`/api/model/profile/company-document/upload/file`, file, {
        headers: {
          "Content-Disposition": `attachment; filename=${file.name}`,
          "File-Meta": `{"name": "${imageInput.name}", "company": "${company.id}"}`,
        },
      })
      .then((res) => {
        update();
      })
      .catch((err) => {
        // console.log(err);
      });
  };

  const handleSubmit = async (values) => {
    setSubmitting(true);
    try {
      await httpClient.post("/jql", {
        __meta__: {
          authenticationClass: AUTHENTICATION_CLASS,
          namespace: "profile.Company",
          schema: "model",
          intent: "patch",
          uniqueObject: {
            id: company.id,
          },
        },
        ...values.company,
      });
      setSubmitting(false);
    } catch (err) {
      setSubmitting(false);
    }
  };

  if (isLoading) return null;

  return (
    <Box margin={0}>
      <Formik
        initialValues={{
          company: {
            tin: _company.tin,
            registrationNumber: _company.registrationNumber,
            website: _company.website,
            line1: _company.line1,
            line2: _company.line2,
            city: _company.city,
            state: _company.state,
            phone1: _company.phone1,
            email: _company.email,
          },
        }}
        onSubmit={handleSubmit}
      >
        {({ handleSubmit }) => {
          return (
            <form onSubmit={handleSubmit}>
              <InfoSection title="Upload CAC Registration Files">
                <div className="row">
                  <div className="col-12 col-sm-6">
                    <FileField
                      files={files}
                      handleFileUpload={handleFileUpload}
                      name="certificate-of-incorporation"
                      label="Certificate Of Incorporation"
                    />
                  </div>

                  <div className="col-12 col-sm-6">
                    <FileField
                      name="cac-2"
                      label="CAC 2"
                      files={files}
                      handleFileUpload={handleFileUpload}
                    />
                  </div>

                  <div className="col-12 col-sm-6">
                    <FileField
                      files={files}
                      name="cac-7"
                      label="CAC 7"
                      handleFileUpload={handleFileUpload}
                    />
                  </div>

                  <div className="col-12 col-sm-6">
                    <FileField
                      files={files}
                      name="director-consent"
                      label="Director Consent Form"
                      handleFileUpload={handleFileUpload}
                    />
                  </div>
                </div>
              </InfoSection>

              <div className="mt-5 mb-4">
                <InfoSection title="Company’s Details">
                  <div className="row">
                    <div className="col-12 col-sm-6">
                      <TextField label="TIN Number" readOnly name="company.tin" />
                    </div>

                    <div className="col-12 col-sm-6">
                      <TextField label="RC Number" readOnly name="company.registrationNumber" />
                    </div>

                    <div className="col-12 col-sm-6">
                      <TextField label="Website URL" type="url" name="company.website" />
                    </div>

                    <div className="col-12 col-sm-6">
                      <TextField label="Address 1" name="company.line1" />
                    </div>

                    <div className="col-12 col-sm-6">
                      <TextField label="Address 2" name="company.line2" />
                    </div>

                    <div className="col-12 col-sm-6">
                      <TextField label="City" name="company.city" />
                    </div>

                    <div className="col-12 col-sm-6">
                      <SelectField label="State" name="company.state" options={states} />
                    </div>

                    <div className="col-12 col-sm-6">
                      <TextField
                        name="company.phone1"
                        label="Contact Phone Number"
                        placeholder="+2348160000078"
                      />
                    </div>

                    <div className="col-12 col-sm-6">
                      <TextField
                        type="email"
                        name="company.email"
                        label="Company Email"
                        placeholder="+2348160000078"
                      />
                    </div>
                  </div>
                </InfoSection>
              </div>

              <Button
                type="submit"
                loading={isSubmitting}
                disabled={isSubmitting}
                label="UPDATE COMPANY DETAILS"
              />
            </form>
          );
        }}
      </Formik>
    </Box>
  );
};
