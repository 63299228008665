import React, { FC, ReactElement } from 'react'

import { TableWrapper } from './styles'
import InfoSection from '../InfoSection'

type Props = {
  title: string
  columns: string[]
  footer?: ReactElement
  rows: { [key: string]: ReactElement | string }[]
}

const Table: FC<Props> = ({ title, rows, columns, footer }) => {
  return (
    <InfoSection title={title}>
      <TableWrapper>
        <table>
          <thead>
            <tr>
              {columns.map((column, i) => (
                <th key={i} scope="col">
                  {column}
                </th>
              ))}
            </tr>
          </thead>

          <tbody>
            {rows.map((row, i) => (
              <tr key={i}>
                {Object.values(row).map((val, index) => (
                  <td key={index} scope="row">
                    {val}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>

        {!!footer && <div className="footer">{footer}</div>}
      </TableWrapper>
    </InfoSection>
  )
}
export default Table
