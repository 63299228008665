import React, { useCallback, useEffect, useState } from "react";
import BeatLoader from "react-spinners/BeatLoader";
import { PageLoaderWrapper } from "components/pages/admin/styles";
import { H4 } from "components/app/styles/typography";
import { Box } from "components/app/styles/generic";
import { Pages } from "components/pages/company/types";
import { ActionButton } from "components/app/styles";
import { THEME } from "common/theme";
import { httpClient } from "utils/index";
import { AUTHENTICATION_CLASS } from "common/constants";

type IProps = {
  transactionId?: string | number | null;
  goto: (x: Pages) => void;
};

type MessageProps = {
  message: string;
  buttonName: string;
  goto: (x: Pages) => void;
};

const Message: React.FC<MessageProps> = ({ goto, message, buttonName }) => (
  <Box padding={THEME.sizes.md} backgroundColour={THEME.colors.grayA100}>
    <H4 marginBottom={THEME.sizes.md}>{message}</H4>
    <ActionButton onClick={() => goto(Pages.paymentCard)}>{buttonName}</ActionButton>
  </Box>
);

export default ({ transactionId, goto }: IProps) => {
  const [paymentStatus, setPaymentStatus] = useState<string | null>("success");
  // const queryCall = useCallback(async () => {
  //   if (transactionId) {
  //     const res = await httpClient.post('/jql', {
  //       __meta__: {
  //         namespace: 'debt.Payment',
  //         schema: 'controller',
  //         intent: 'verify_card_payment',
  //       },
  //       transaction_id: transactionId,
  //     })
  //
  //     setPaymentStatus(res.data?.data?.status)
  //   }
  // }, [transactionId])

  useEffect(() => {
    // console.log("calling query", transactionId);
    // queryCall().then(response => console.log("Response", response))

    if (!transactionId) return;

    httpClient
      .post("/jql", {
        __meta__: {
          authenticationClass: AUTHENTICATION_CLASS,
          namespace: "debt.Payment",
          schema: "controller",
          intent: "verify_card_payment",
        },
        transaction_id: transactionId,
      })
      .then((response) => {
        // console.log("Response", response.data);
        setPaymentStatus(response.data?.data?.status);
      });
  }, [transactionId]);

  if (paymentStatus === null) {
    return (
      <PageLoaderWrapper>
        <BeatLoader color="#000" size={30} loading={true} />
        <H4>Checking state of payment please wait</H4>
      </PageLoaderWrapper>
    );
  } else if (paymentStatus === "success") {
    return (
      <Message
        goto={goto}
        message={
          "Your payment was a successful. Click the button below if you would like to make another"
        }
        buttonName={"Make Another Payment"}
      />
    );
  } else {
    return (
      <Message
        goto={goto}
        message={"Payment Failed. Click the button below to try again"}
        buttonName={"Try Again"}
      />
    );
  }
};
