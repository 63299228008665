import styled from 'styled-components'

export const VerifyWrapper = styled.div`
  padding-top: 3.125rem;
`

export const VerifyForm = styled.form`
  background-color: #fff;
  padding: 2.5rem 2.875rem 8.4375rem;
  border-radius: 5px;
`

export const Row = styled.div`
  h4 {
    font-weight: 600;
    font-size: 18px;
    line-height: 18px;
    color: #163327;
    margin-bottom: 1.5rem;
  }
`

export const FileRow = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 1.875rem;
  background-color: #f8f8f8;
  padding: 2.5rem 1rem;
  margin-top: 0;
  margin-bottom: 2.5rem;
`

export const DetailsRow = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 20px 28px;
  background-color: #f8f8f8;
  padding: 2.5rem 1rem;
  margin-top: 0;
  margin-bottom: 2.5rem;
`

export const Label = styled.label`
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  color: #1e3c10;

  input,
  select {
    display: block;
    width: 100%;
    margin-top: 0.625rem;
    font-weight: normal;
    font-size: 16px;
    line-height: 18px;
    color: #393939;
    padding: 0.625rem;
    border: 1px solid #4f4f4f;
    border-radius: 0;
  }

  select {
    -webkit-appearance: none;
    border-radius: 0;
    background-image: url('/media/caret-down.svg');
    background-repeat: no-repeat;
    background-position: right 1.5rem top 50%;
    background-size: 12px 6px;
  }

  input {
    &[type='file'] {
      width: 0.1px;
      height: 0.1px;
      opacity: 0;
      overflow: hidden;
      position: absolute;
      z-index: -1;
    }

    &[type='file'] + span {
      display: block;
      font-weight: normal;
      font-size: 16px;
      line-height: 18px;
      color: #1e3c10;
      padding: 0.625rem;
      background: #ffffff;
      border: 0.2px solid #4f4f4f;
      margin-top: 0.625rem;
      cursor: pointer;
      background-image: url('/media/upload.svg');
      background-repeat: no-repeat;
      background-position-x: calc(100% - 23px);
      background-position-y: center;
    }

    &::placeholder {
      /* Chrome, Firefox, Opera, Safari 10.1+ */
      color: #a8a8a8;
      opacity: 1; /* Firefox */
    }

    &:-ms-input-placeholder {
      /* Internet Explorer 10-11 */
      color: #a8a8a8;
    }

    &::-ms-input-placeholder {
      /* Microsoft Edge */
      color: #a8a8a8;
    }
  }
`

export const VerificationSuccess = styled.div`
  background-color: #fff;
  width: 100%;
  max-width: 500px;
  margin: 10rem auto 0;
  padding: 1.875rem 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 3px;
  border-top: 4px solid #4c9a2a;

  p {
    font-weight: normal;
    font-size: 14px;
    line-height: 22px;
    color: #1e3c10;
    max-width: 310px;
    text-align: center;
    margin-bottom: 2.5rem;
  }

  button {
    display: block;
    padding: 0.6875rem 2rem;
    background: #4c9a2a;
    border-radius: 2px;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 28px;
    color: #fff6fe;
    border: none;
  }
`
