import React, { FC, useState, useEffect, useContext, useCallback } from "react";
import { Formik, Form, useFormikContext } from "formik";
import debounce from "lodash.debounce";
import toast from "react-hot-toast";
import MoonLoader from "react-spinners/MoonLoader";
import { RouteComponentProps } from "@reach/router";

import { httpClient } from "utils";
import useModal from "hooks/useModal";
import { AuthContext } from "contexts";
import { makeRequest } from "utils/httpClient";

import Card from "components/auth/Card";
import Icon from "components/layout_v2/Icon";
import Button from "components/auth/Button";
import TextField from "components/auth/TextField";
import AuthLayout from "components/auth/AuthLayout";
import SearchImg from "assets/images/new/search.jpg";
import Switch from "components/Switch";
import ConfirmAssociationModal from "./ConfirmAssociationModal";
import HasAdminModal from "./HasAdminModal";

import { SearchWrapper } from "./styles";
import { AUTHENTICATION_CLASS } from "common/constants";

export type Company = {
  id: number;
  name: string;
  verified: boolean;
  representatives: Array<{
    admin: true;
    user: {
      id: number;
      fullname: string;
    };
  }>;
};

const breadcrumbs = [{ label: "Welcome", link: "/app/welcome" }, { label: "Search" }];

const FormikContext: FC<{ onChange: (val: string) => void }> = ({ onChange }) => {
  const { values } = useFormikContext<{ query: string }>();
  const { query } = values;

  useEffect(() => {
    onChange(values.query);
  }, [query]);

  return null;
};

const Search: FC<RouteComponentProps> = ({ navigate }) => {
  const [isLoading, setLoading] = useState(false);
  const [isSubmitting, setSubmitting] = useState(false);
  const [companies, setCompanies] = useState<Company[]>([]);
  const [company, setCompany] = useState<Company>({
    id: 0,
    name: "",
    verified: false,
    representatives: [],
  });

  const { currentUser } = useContext(AuthContext);
  const { isModalOpen, openModal, closeModal } = useModal();
  const {
    isModalOpen: isHasAdminModalOpen,
    openModal: openHasAdminModal,
    closeModal: closeHasAdminModal,
  } = useModal();

  const handleDebounceFn = async (value: string) => {
    if (!value.trim()) {
      setCompanies([]);
      return;
    }

    try {
      setLoading(true);

      const [data, , error]: any = await makeRequest({
        path: "/jql",
        method: "POST",
        config: {
          data: {
            __meta__: {
              authenticationClass: AUTHENTICATION_CLASS,
              namespace: "profile.Company",
              schema: "model",
              intent: "retrieve",
              search: {
                value,
              },
              side_effects: {
                global: true,
              },
            },
            id: null,
            name: null,
            representatives: {
              admin: null,
              user: {
                id: null,
                fullname: null,
              },
            },
            verified: null,
          },
        },
      });

      if (error && error.errors && Array.isArray(error.errors)) {
        error.errors.map(({ error_message }) => {
          return toast.error(error_message);
        });
        return;
      }

      setCompanies(data.data);
    } catch (err) {
      toast.error(err.message);
    } finally {
      setLoading(false);
    }
  };

  const debounceFn = useCallback(debounce(handleDebounceFn, 300), []);

  const associateToCompany = async ({ representatives }) => {
    setSubmitting(true);
    const hasRepresentatives = representatives.length > 0;
    try {
      await httpClient.post("/jql", {
        __meta__: {
          authenticationClass: AUTHENTICATION_CLASS,
          namespace: "profile.CompanyRepresentative",
          intent: "create",
          schema: "model",
          return: {
            admin: null,
            user: {
              id: null,
              fullname: null,
            },
          },
        },
        admin: !hasRepresentatives,
        company: company.id,
        user: currentUser?.id,
      });
      if (!hasRepresentatives) {
        navigate && navigate(`/app/company/${company.id}/verify`);
      } else {
        closeModal();
        openHasAdminModal();
      }
    } catch (error) {
      navigate && navigate("/app/welcome");
    } finally {
      setSubmitting(false);
    }
  };

  const isEmpty = !isLoading && !companies.length;

  return (
    <SearchWrapper>
      <AuthLayout title="Search" breadcrumbs={breadcrumbs}>
        <div className="col-lg-7">
          <Card title="Find your company here">
            <Formik onSubmit={() => {}} initialValues={{ query: "" }}>
              {() => (
                <Form>
                  <TextField
                    name="query"
                    placeholder="Search for your company"
                    icon={<Icon name="Search" size={20} />}
                  />

                  <FormikContext onChange={debounceFn} />
                </Form>
              )}
            </Formik>

            <div
              className={`search-result-wrapper ${
                (!companies.length || isLoading) && "empty-list"
              }`}
            >
              <MoonLoader color="#1E3C10" size={25} loading={isLoading} />
              {isEmpty && <Icon name="Search" size={40} />}
              {!isLoading && !!companies.length && (
                <table className="table table-striped company-list">
                  <thead>
                    <tr>
                      <th scope="col">#</th>
                      <th scope="col">Company</th>
                      <th scope="col"></th>
                    </tr>
                  </thead>

                  <tbody>
                    {companies.map((company, i) => {
                      const { id, name } = company;

                      // handle when verification is pending
                      return (
                        <tr key={id}>
                          <th scope="row">{i + 1}</th>
                          <td>{name}</td>
                          <td className="text-right">
                            <Button
                              size="sm"
                              type="button"
                              label="Associate"
                              onClick={() => {
                                setCompany(company);
                                openModal();
                              }}
                            />
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              )}
            </div>
          </Card>
        </div>

        <div className="col-lg-5 d-none d-lg-block">
          <Card>
            <div className="search-img-wrapper">
              <img src={SearchImg} alt="dummy image" />
            </div>
          </Card>
        </div>
      </AuthLayout>

      <Switch condition={isModalOpen}>
        <ConfirmAssociationModal
          company={company}
          closeModal={closeModal}
          isSubmitting={isSubmitting}
          associateToCompany={associateToCompany}
        />
      </Switch>

      <Switch condition={isHasAdminModalOpen}>
        <HasAdminModal closeModal={closeHasAdminModal} company={company} navigate={navigate} />
      </Switch>
    </SearchWrapper>
  );
};

export default Search;
