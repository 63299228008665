import React from 'react'

import { CheckboxLabel } from 'components/auth'
import TableSwitch from 'components/pages/generic/table/TableSwitch'
import {
  StyledTable,
  StyledTableWrapper,
  StyledTableWrapperHeading,
} from 'components/app/styles/table'
import { createCheckBoxCell, Table, TableWrapper, TableWrapperHeading } from 'components/app/styles'

const THCheckboxCell = createCheckBoxCell('th')
const TDCheckboxCell = createCheckBoxCell('td')

type IProps = {
  title: string
  description?: string
  columns: Array<string>
  rows: Array<any>
  multipleSelect?: boolean
}

export default ({ title, description, columns, rows, multipleSelect }: IProps) => {
  const [selectedIDs, setSelectedIDs] = React.useState<Array<string | number>>([])

  const handleSetSelectedIDs = (evt, id: string | number | null) => {
    const checked = evt.target.checked
    if (id) {
      if (checked) {
        setSelectedIDs((prevSelectedIDs) => Array.from(new Set([...prevSelectedIDs, id])))
      } else {
        setSelectedIDs((prevSelectedIDs) => prevSelectedIDs.filter((el) => el !== id))
      }
    } else {
      if (checked) {
        setSelectedIDs(['1', '2'])
      } else {
        setSelectedIDs([])
      }
    }
  }

  return (
    <React.Fragment>
      <StyledTableWrapper>
        {title && (
          <StyledTableWrapperHeading>
            <h4>{title}</h4>
            {description && <p>{description}</p>}
          </StyledTableWrapperHeading>
        )}

        <TableSwitch selectedIDs={selectedIDs} setSelectedIDs={setSelectedIDs} />

        <StyledTable>
          <thead>
            <tr>
              {multipleSelect && (
                <THCheckboxCell>
                  <CheckboxLabel>
                    <input
                      type="checkbox"
                      checked={selectedIDs.length > 0}
                      onChange={(evt) => handleSetSelectedIDs(evt, null)}
                    />
                    <span />
                  </CheckboxLabel>
                </THCheckboxCell>
              )}
              {columns.map((column: string) => (
                <th>{column}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {rows.map((row: any, i) => {
              let _row
              if (multipleSelect) {
                _row = [
                  <TDCheckboxCell key={i}>
                    <CheckboxLabel>
                      <input
                        type="checkbox"
                        checked={selectedIDs.includes('1')}
                        onChange={(evt) => handleSetSelectedIDs(evt, row.id)}
                      />
                      <span />
                    </CheckboxLabel>
                  </TDCheckboxCell>,
                  ...row.props.children,
                ]
              } else {
                _row = row.props.children
              }

              return React.cloneElement(row, {}, _row)
            })}
          </tbody>
        </StyledTable>
      </StyledTableWrapper>
    </React.Fragment>
  )
}
