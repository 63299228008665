import React, { FC, ReactNode } from "react";

import { InfoSectionWrapper } from "./styles";

type Props = {
  title?: string;
  children: ReactNode;
};

const InfoSection: FC<Props> = ({ title, children }) => {
  return (
    <InfoSectionWrapper>
      {!!title && <div className="custom-title">{title}</div>}

      <div className="section-body">{children}</div>
    </InfoSectionWrapper>
  );
};

export default InfoSection;
