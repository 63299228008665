import React, { FC } from 'react'
import MoonLoader from 'react-spinners/MoonLoader'

import { theme } from '../../../layouts/theme'
import { ButtonWrapper } from './styles'

type Props = {
  label: string
  loading?: boolean
  disabled?: boolean
  size?: 'sm' | 'base'
  onClick?: () => void
  variant?: Omit<keyof typeof theme, 'base_font' | 'base_font_size'>
  type?: 'button' | 'submit' | 'reset'
}

const Button: FC<Props> = ({
  label,
  size = 'base',
  type = 'button',
  loading = false,
  disabled = false,
  onClick = () => null,
  variant = 'primary_color',
}) => (
  <ButtonWrapper
    size={size}
    type={type}
    className="btn"
    variant={variant}
    onClick={onClick}
    disabled={disabled}
  >
    {loading ? <MoonLoader color="#fff" size={12} loading={true} /> : label}
  </ButtonWrapper>
)

export default Button
