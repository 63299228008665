import React, { useMemo } from "react";
import {
  Chart as ChartJS,
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  Legend,
  Tooltip,
  LineController,
  BarController,
} from "chart.js";
import { Chart } from "react-chartjs-2";
import faker from "faker";
import useJql from "hooks/useJql";
import { AUTHENTICATION_CLASS } from "common/constants";

ChartJS.register(
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  Legend,
  Tooltip,
  LineController,
  BarController,
);

const jql = {
  __meta__: {
    namespace: "debt.PaymentTransactionLog",
    schema: "model",
    intent: "aggregate",
    authenticationClass: AUTHENTICATION_CLASS,
    groupBy: ["payment_date"],
    truncate: {
      granularity: "month",
      computation: "discrete",
      cast: "date",
    },
    ordering: "month",
  },
  month: {
    __transform__: true,
    dateFormat: "%b-%y",
  },
  debtByMonth: {
    __meta__: {
      field: "amount",
      function: "sum",
    },
  },
  countByMonth: {
    __meta__: {
      field: "*",
      function: "count",
    },
  },
};

function DebtRecorded() {
  const { data: res, isLoading } = useJql({
    jqlQuery: jql,
  });
  // console.log(res);

  const { labels, amount, count } = useMemo(() => {
    const labels: string[] = [];
    const count: number[] = [];
    const amount: number[] = [];

    res?.forEach((record: { countByMonth: number; debtByMonth: number; month: string }) => {
      labels.push(record.month);
      count.push(record.countByMonth);
      amount.push(record.debtByMonth);
    });

    return { labels, amount, count };
  }, [res]);

  const data = {
    labels,
    datasets: [
      {
        type: "line" as const,
        label: "Number of MDAs",
        backgroundColor: "rgb(255, 99, 132)",
        borderColor: "rgb(255, 99, 132)",
        borderWidth: 2,
        fill: false,
        data: count,
        yAxisID: "y1",
      },
      {
        type: "bar" as const,
        label: "Debt value",
        backgroundColor: "rgb(75, 192, 192)",
        data: amount,
        yAxisID: "y",
      },
    ],
  };

  return (
    <div className="p-4">
      <div className="">
        <h4 className="fw-bold mb-2">Debt Recorded</h4>
      </div>
      <div className="pt-4">
        <Chart
          type="bar"
          data={data}
          options={{
            aspectRatio: 1.2,
            plugins: {
              legend: {
                position: "bottom" as const,
                labels: {
                  boxWidth: 20,
                  boxHeight: 20,
                },
              },
            },
            layout: {
              padding: {
                // top: 50,
                // bottom: 50,
              },
            },
            scales: {
              y: {
                display: true,
                type: "linear",
                position: "left",
              },
              y1: {
                display: true,
                type: "linear",
                position: "right",
                grid: {
                  drawOnChartArea: false, // only want the grid lines for one axis to show up
                },
              },
            },
          }}
        />
      </div>
    </div>
  );
}

export default DebtRecorded;
