import React, { FC, useState, useEffect } from "react";
import { RouteComponentProps } from "@reach/router";
import toast from "react-hot-toast";
import { navigate } from "@reach/router";

import UserForm from "./UserForm";
import Card from "components/auth/Card";
import { makeRequest } from "utils/httpClient";
import AuthLayout from "components/auth/AuthLayout";
import { Intents } from "common/enums";
import { AUTHENTICATION_CLASS } from "common/constants";

const defaultInitialVals = {
  firstName: "",
  lastName: "",
  phone: "",
  email: "",
  userType: "",
  mda: "",
};

const breadcrumbs = [
  { label: "Home", link: "/app/welcome" },
  { label: "Users", link: "/app/admin/users" },
  { label: "Edit User" },
];

const EditUser: FC<RouteComponentProps<{ id: string }>> = ({ id }) => {
  const [initialValues, setInitialValues] = useState(defaultInitialVals);

  useEffect(() => {
    let isMounted = true;

    const runAsync = async () => {
      const [data, , error] = await makeRequest({
        path: "/jql",
        method: "POST",
        config: {
          data: {
            __meta__: {
            authenticationClass: AUTHENTICATION_CLASS,
              namespace: "system.User",
              schema: "model",
              intent: Intents.retrieveOne,
              uniqueObject: {
                id,
              },
            },
            firstName: null,
            lastName: null,
            phone: null,
            email: null,
            userType: null,
            mda: {
              id: null,
              name: null,
            },
          },
        },
      });

      if (isMounted) {
        const { firstName, lastName, phone, email, userType, mda } = data.data;
        setInitialValues({ firstName, lastName, phone, email, userType, mda });
      }

      //! handle error
    };

    runAsync();

    return () => {
      isMounted = false;
    };
  }, [id]);

  const handleSubmit = async (payload) => {
    const [data, , error] = await makeRequest({
      path: "/jql",
      method: "POST",
      config: {
        data: {
          __meta__: {
            authenticationClass: AUTHENTICATION_CLASS,
            namespace: "system.User",
            schema: "model",
            intent: Intents.update,
            uniqueObject: {
              id,
            },
          },
          ...payload,
        },
      },
    });

    if (error && error.errors && Array.isArray(error.errors)) {
      error.errors.map(({ error_message }) => {
        return toast.error(error_message);
      });
    } else {
      toast.success(data.message);
      navigate("/app/admin/users");
    }
  };

  return (
    <AuthLayout title="Edit User" breadcrumbs={breadcrumbs}>
      <div className="col-12 col-lg-8">
        <Card>
          <UserForm handleSubmit={handleSubmit} initialValues={initialValues} />
        </Card>
      </div>
    </AuthLayout>
  );
};

export default EditUser;
