import React, { FC } from 'react'

import { PublicLayoutWrapper } from './styles'

type Props = {
  title: string,
  children: any
}

const PublicLayout: FC<Props> = ({ title, children }) => (
  <PublicLayoutWrapper>
    <div className="container">
      <div className="row">
        <div className="col-xl-12 d-lg-flex align-items-center">
          <div className="form-promo text-white position-relative">
            <div className="form-promo-content text-center">
              <h1 className="text-white">Pay your Debt</h1>
              <p className="text-white">
                Lighthouse is the new simple, secure way to verify your debt and offset your
                obligations to the government online. It makes it easier and more efficient for
                companies and government to process payments, saving time and effort for service
                teams across government.
              </p>
            </div>
          </div>

          <div className="form-wrapper">
            <h4 className="text-uppercase text-center mb-5">{title}</h4>
            {children}
          </div>
        </div>
      </div>
    </div>
  </PublicLayoutWrapper>
)

export default PublicLayout
