export enum Intents {
  retrieve = "retrieve",
  retrieveOne = "retrieveOne",
  create = "create",
  createBulk = "createBulk",
  update = "update",
  updateMany = "updateMany",
  patch = "patch",
  delete = "delete",
  deleteOne = "deleteOne",
  authenticate = "authenticate",
  verifyToken = "verifyToken",
  refreshToken = "refreshToken",
  metadata = "metadata",
  deactivate = "deactivate",
}

export enum UserTypes {
  SUPER_ADMIN = "SUPER_ADMIN",
  ADMIN = "ADMIN",
  MDA_ADMIN = "MDA_ADMIN",
  MDA_USER = "MDA_USER",
  COMPANY_USER = "CORPORATE_REPRESENTATIVES",
}
