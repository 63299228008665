import React, { useEffect, useState } from "react";
import { Redirect, Router, useLocation } from "@reach/router";
import { graphql } from "gatsby";
import { Toaster } from "react-hot-toast";
import { ThemeProvider } from "styled-components";
import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";
import { theme } from "../layouts/theme";
import Company from "components/pages/company";
import Welcome from "components/pages/welcome";
import Profile from "components/pages/profile";
import Search from "components/pages/search";
import Users from "components/pages/admin/users";
import AddUser from "components/pages/admin/users/AddUser";
import EditUser from "components/pages/admin/users/EditUser";
import Companies from "components/pages/admin/companies";
import AddCompany from "components/pages/admin/companies/AddCompany";
import EditCompany from "components/pages/admin/companies/EditCompany";
import VerifyCompany from "components/pages/admin/companies/VerifyCompany";
import CompanyRepresentatives from "components/pages/admin/companies/CompanyRepresentatives";
import AssociatedCompanies from "components/pages/admin/users/AssociatedCompanies";
import MDAS from "components/pages/admin/mdas";
import AddMDA from "components/pages/admin/mdas/AddMDA";
import EditMDA from "components/pages/admin/mdas/EditMDA";
import ViewDebtors from "components/pages/admin/mdas/ViewDebtors";
import Debts from "components/pages/admin/debt";
import ConfigureDebt from "components/pages/admin/debt/ConfigureDebt";
import LogDebt from "components/pages/admin/debt/LogDebt";
import Payments from "components/pages/admin/payments";
import AddPayment from "components/pages/admin/payments/AddPayment";
import { Layout } from "components/layout_v2/index";

// AUTH
import Login from "components/pages/auth/Login";
import Register from "components/pages/auth/Register";
import ForgotPassword from "components/pages/auth/ForgotPassword";
import PrivateRoute from "components/PrivateRoute";
import { AuthContext } from "../contexts";
import { observer } from "mobx-react";
import authenticationStore from "stores/AuthenticationStore";
import { UserTypes } from "models/db-schema";
import { AUTHENTICATION_CLASS } from "common/constants";
import { isEmpty } from "lodash";
import { SWRConfig } from "swr";
import { JqlService } from "common/client";

const superAdminAndMDARoutes = [
  { path: "/admin/debts/configure", component: ConfigureDebt },
  { path: "/admin/debts/add", component: LogDebt },
  { path: "/admin/payments/add", component: AddPayment },
];

const sharedPrivateRoutes = [
  { path: "/admin", component: Users },
  { path: "/admin/users", component: Users },
  { path: "/admin/users/add", component: AddUser },
  { path: "/admin/users/:id/edit", component: EditUser },
  { path: "/admin/companies", component: Companies },
  { path: "/admin/debts", component: Debts },
  { path: "/admin/mdas/:id/debtors", component: ViewDebtors },
  { path: "/admin/payments", component: Payments },
  { path: "/admin/debts/:debtId/log-debt", component: LogDebt },
  { path: "/admin/debts/:mdaId/:debtorId/add-payment", component: AddPayment },
];

const sharedAdminRoutes = [
  ...sharedPrivateRoutes,
  { path: "/admin/mdas", component: MDAS },
  { path: "/admin/companies", component: Companies },
  { path: "/admin/companies/add", component: AddCompany },
  { path: "/admin/companies/:id/edit", component: EditCompany },
  { path: "/admin/companies/:id/verify", component: VerifyCompany },
  { path: "/admin/companies/:id/company-representatives", component: CompanyRepresentatives },
  { path: "/admin/users/:id/associated-companies", component: AssociatedCompanies },
];

// SA
const superAdminRoutes = [
  ...sharedAdminRoutes,
  ...superAdminAndMDARoutes,
  { path: "/admin/mdas", component: MDAS },
  { path: "/admin/mdas/add", component: AddMDA },
  { path: "/admin/mdas/:id/edit", component: EditMDA },
];

// AD
const adminRoutes = sharedAdminRoutes;

// MA & MU
const mdaAdminRoutes = [
  ...sharedPrivateRoutes,
  ...superAdminAndMDARoutes,
  { path: "/admin/companies/add", component: AddCompany },
];

const App = observer(({ data }) => {
  const contact_data = data?.allPrismicContact?.edges?.[0]?.node?.data;
  const pageContext = {
    menuItems: data?.allPrismicNavigation?.edges?.[0]?.node?.data?.body,
  };
  const location = useLocation();

  useEffect(() => {
    (async () => {
      const token = localStorage.getItem("token");
      if (AUTHENTICATION_CLASS === "jwt" && token && isEmpty(authenticationStore.user)) {
        try {
          await authenticationStore.verify();
        } catch (error) {
          // console.log({ error });
        }
      } else {
        authenticationStore.setLoading(false);
      }
    })();
  }, []);

  const userType = authenticationStore.user?.userType as UserTypes;

  if (authenticationStore?.isLoading)
    return (
      <div className="d-flex flex-column min-vh-100 align-items-center justify-content-center">
        <Spin indicator={<LoadingOutlined style={{ fontSize: 50 }} spin />} />
      </div>
    );

  if (location.pathname.includes("/app/auth") && authenticationStore.isLoggedIn) {
    return <Redirect noThrow to="/app/welcome" />;
  }

  if (!location.pathname.includes("/app/auth") && !authenticationStore.isLoggedIn) {
    return <Redirect noThrow to="/app/auth/login" />;
  }
  // Just a simple change
  return (
    <SWRConfig
      value={{
        refreshInterval: 100000,
        fetcher: async (jqlQuery) => {
          const response = await JqlService.post(JqlService.JQL_ENDPOINT, jqlQuery);
          return response.data;
        },
      }}
    >
      <AuthContext.Provider
        value={{
          currentUser: authenticationStore.user,
          logout: authenticationStore.logout,
          isLoggedIn: authenticationStore.isLoggedIn,
        }}
      >
        <ThemeProvider theme={theme}>
          <Layout pageContext={pageContext} contact={contact_data}>
            <Toaster />
            <Router basepath="/app">
              {/* AUTH */}
              <Login path="/auth/login" />
              <Register path="/auth/register" />
              <ForgotPassword path="/auth/forgot" />

              {/* WELCOME PAGES */}
              <PrivateRoute path="/welcome" as={Welcome} />
              <PrivateRoute path="/search" as={Search} />
              <PrivateRoute path="/profile-settings" as={Profile} />

              {/* ADMIN */}
              {userType === UserTypes.SUPER_ADMIN &&
                superAdminRoutes.map(({ path, component }, i) => (
                  <PrivateRoute key={i} path={path} as={component} />
                ))}

              {userType === UserTypes.ADMIN &&
                adminRoutes.map(({ path, component }, i) => (
                  <PrivateRoute key={i} path={path} as={component} />
                ))}

              {[UserTypes.MDA_ADMIN, UserTypes.MDA_USER].includes(userType) &&
                mdaAdminRoutes.map(({ path, component }, i) => (
                  <PrivateRoute key={i} path={path} as={component} />
                ))}

              {/* <PrivateRoute path="/admin/:companyId/docs" as={CompanyDocumentList} />
            <PrivateRoute path="/admin/:companyId/docs/:docId" as={CompanyDocumentView} /> */}

              {/* Company*/}
              <PrivateRoute exact path="/company/:companyId" as={Company} />
              <PrivateRoute exact path="/company/:companyId/:page" as={Company} />
              {/* <PrivateRoute path="/company/:companyId/verify" as={Verify} /> */}
            </Router>
          </Layout>
        </ThemeProvider>
      </AuthContext.Provider>
    </SWRConfig>
  );
});

export default App;

export const query = graphql`
  query AppNavigationQuery {
    allPrismicContact {
      edges {
        node {
          data {
            address_geo {
              latitude
              longitude
            }
            email
            facebook
            address
            instagram
            linkedin
            banner {
              url
            }
            phone
            phone2
            twitter
            name {
              text
            }
          }
          uid
        }
      }
    }
  }
`;
