import styled from "styled-components";
import { Button, Dropdown, Input, Menu } from "antd";
import { THEME } from "common/theme";
import { ButtonType, FlexBoxType, StyledButtonType } from "./types";
import { Link } from "gatsby";

export const BaseButton = styled(Button)<ButtonType>`
  color: ${(props) => props.colour || "inherit"};
  cursor: ${(props) => props.cursor || ""};
  margin: ${(props) =>
    (typeof props.margin === "number" ? props.margin.toString() + "px" : props.margin) || ""};
  margin-top: ${(props) =>
    (typeof props.marginTop === "number" ? props.marginTop.toString() + "px" : props.marginTop) ||
    ""};
  margin-right: ${(props) =>
    (typeof props.marginRight === "number"
      ? props.marginRight.toString() + "px"
      : props.marginRight) || ""};
  margin-bottom: ${(props) =>
    (typeof props.marginBottom === "number"
      ? props.marginBottom.toString() + "px"
      : props.marginBottom) || ""};
  margin-left: ${(props) =>
    (typeof props.marginLeft === "number"
      ? props.marginLeft.toString() + "px"
      : props.marginLeft) || ""};
  padding: ${(props) =>
    (typeof props.padding === "number" ? props.padding.toString() + "px" : props.padding) || ""};
  padding-top: ${(props) =>
    (typeof props.paddingTop === "number"
      ? props.paddingTop.toString() + "px"
      : props.paddingTop) || ""};
  padding-right: ${(props) =>
    (typeof props.paddingRight === "number"
      ? props.paddingRight.toString() + "px"
      : props.paddingRight) || ""};
  padding-bottom: ${(props) =>
    (typeof props.paddingBottom === "number"
      ? props.paddingBottom.toString() + "px"
      : props.paddingBottom) || ""};
  padding-left: ${(props) =>
    (typeof props.paddingLeft === "number"
      ? props.paddingLeft.toString() + "px"
      : props.paddingLeft) || ""};
  background-color: ${(props) => props.backgroundColour || ""};
`;

export const StyledOutlineButton = styled(BaseButton)<StyledButtonType>`
  &,
  &:focus {
    color: ${(props) => (props.outline ? THEME.colors.gray800 : "auto")};
    border-color: ${(props) => (props.outline ? THEME.colors.gray800 : "auto")};
    background-color: ${(props) => (props.outline ? "white" : "auto")};
  }

  &:hover {
    color: ${(props) => (props.outline ? "white" : props.colour || THEME.colors.dark)};
    border-color: ${(props) =>
      props.outline ? props.colour || THEME.colors.link : THEME.colors.gray800};
    background-color: ${(props) =>
      props.outline ? props.colour || THEME.colors.link : THEME.colors.white};
  }
`;

export const StyledButton = styled(BaseButton)<ButtonType>`
  &,
  &:focus {
    color: ${(props) => THEME.colors.white || "auto"};
    border-color: ${(props) => THEME.colors.link || "auto"};
    background-color: ${(props) => THEME.colors.link || "auto"};
  }

  &:hover {
    color: ${(props) => THEME.colors.white};
    border-color: ${(props) => props.colour || THEME.colors.green};
    background-color: ${(props) => props.colour || THEME.colors.green};
  }
`;

export const StyledMenu = styled(Menu)`
  li:nth-child(2) {
    margin-left: 0 !important;
  }

  .ant-menu-item {
    &:hover,
    &:focus,
    &.active,
    &.visited {
      color: ${THEME.colors.link} !important;
      border-color: ${THEME.colors.link} !important;
    }
  }

  .ant-menu-item-selected {
    color: ${THEME.colors.link} !important;
    border-color: ${THEME.colors.link} !important;
  }
`;

export const StyledDropdownButton = styled(Dropdown.Button)`
  .ant-btn:active,
  .ant-btn:focus,
  .ant-btn:hover {
    color: ${THEME.colors.link};
    border-color: ${THEME.colors.link};
  }
`;

export const StyledLinkButton = styled(Link)`
  color: ${THEME.colors.white};
  background-color: ${THEME.colors.link} !important;
  padding: 6px 20px;
  font-size: ${THEME.fontSizes.normal}px;

  &:hover {
    color: ${THEME.colors.white};
    background-color: ${THEME.colors.green};
  }
`;

export const StyledLink = styled(Link)<ButtonType>`
  color: ${THEME.colors.link};
  padding: ${(props) => (props.padding ? props.padding + "px" : "inherit")};

  &:hover {
    color: ${THEME.colors.green};
  }
`;
