import React from "react";
import HeaderCell from "components/generic/table/HeaderCell";

export const ColumnHeader = [
  {
    Header: "Payment Reference",
  },
  {
    Header: "Company",
  },
  {
    Header: "Debt",
  },
  {
    Header: <HeaderCell textAlign={"text-right"} value={"Amount"} />,
    accessor: "Amount",
  },
  {
    Header: "Payment Date",
  },
  {
    Header: "Payment Status",
  },
];
