import styled from "styled-components";
import { TypographyType } from "./types";
import { THEME } from "common/theme";

const FontWeights = {
  ultralight: 200,
  light: 300,
  normal: 400,
  medium: 500,
  semibold: 600,
  bold: 700,
};

export const BaseSpan = styled.span<TypographyType>`
    text-transform: ${(props) => props.transform || "inherit"};
    color: ${(props) => props.colour || "inherit"};
    font-family: ${THEME.fonts.primary};
    font-weight: ${(props) => (props.weight && FontWeights[props.weight]) || 400};
    font-style: ${(props) => (props.italic ? "italic" : "normal")};
    font-size: ${(props) => props.size || 14};px;
`;

export const BaseParagraph = styled.p<TypographyType>`
  margin: 0;
  text-align: ${(props) => props.align || "left"};
  text-transform: ${(props) => props.transform || "inherit"};
  color: ${(props) => props.colour || "inherit"};
`;

export const Text = styled(BaseParagraph)<TypographyType>`
  font-family: ${THEME.fonts.primary};
  font-weight: ${(props) => (props.weight && FontWeights[props.weight]) || 400};
  font-style: ${(props) => (props.italic ? "italic" : "normal")};
  font-size: ${(props) => props.size || 14}px;
  margin: ${(props) => props.margin || 0}px;
  margin-top: ${(props) => props.marginTop || 0}px;
  margin-right: ${(props) => props.marginRight || 0}px;
  margin-bottom: ${(props) => props.marginBottom || 0}px;
  margin-left: ${(props) => props.marginLeft || 0}px;
  padding: ${(props) => props.padding || 0}px;
  padding-top: ${(props) => props.paddingTop || 0}px;
  padding-right: ${(props) => props.paddingRight || 0}px;
  padding-bottom: ${(props) => props.paddingBottom || 0}px;
  padding-left: ${(props) => props.paddingLeft || 0}px;
`;

export const Title = styled(Text)<TypographyType>`
  font-family: ${THEME.fonts.primary};
  font-weight: ${(props) => (props.weight && FontWeights[props.weight]) || 500};
  font-size: 48px;
  color: ${(props) => props.colour || THEME.colors.green};
`;

export const H1 = styled(Text)<TypographyType>`
  font-family: ${THEME.fonts.primary};
  font-weight: ${(props) => (props.weight && FontWeights[props.weight]) || 500};
  font-size: 32px;
  color: ${(props) => props.colour || THEME.colors.green};
`;

export const H2 = styled(Text)<TypographyType>`
  font-family: ${THEME.fonts.primary};
  font-weight: ${(props) => (props.weight && FontWeights[props.weight]) || 500};
  font-size: 28px;
  color: ${(props) => props.colour || THEME.colors.green};
`;

export const H3 = styled(Text)<TypographyType>`
  font-family: ${THEME.fonts.primary};
  font-weight: ${(props) => (props.weight && FontWeights[props.weight]) || 500};
  font-size: 24px;
  color: ${(props) => props.colour || THEME.colors.green};
`;

export const H4 = styled(Text)<TypographyType>`
  font-family: ${THEME.fonts.primary};
  font-weight: ${(props) => (props.weight && FontWeights[props.weight]) || 500};
  font-size: 20px;
  font-style: ${(props) => (props.italic ? "italic" : "normal")};
  color: ${(props) => props.colour || THEME.colors.green};
`;

export const H5 = styled(Text)<TypographyType>`
  font-family: ${THEME.fonts.primary};
  font-weight: ${(props) => (props.weight && FontWeights[props.weight]) || 500};
  font-weight: ${(props) => props.color || THEME.colors.black};
  font-size: 18px;
  font-style: ${(props) => (props.italic ? "italic" : "normal")};
  color: ${(props) => props.colour || THEME.colors.green};
`;

export const H6 = styled(Text)<TypographyType>`
  font-family: ${THEME.fonts.primary};
  font-weight: ${(props) => (props.weight && FontWeights[props.weight]) || 500};
  font-size: 16px;
  font-style: ${(props) => (props.italic ? "italic" : "normal")};
  color: ${(props) => props.colour || THEME.colors.green};
`;

export const Body = styled(Text)<TypographyType>`
  font-weight: ${(props) => (props.weight && FontWeights[props.weight]) || 400};
`;

export const Span = styled(BaseSpan)<TypographyType>`
  font-weight: ${(props) => (props.weight && FontWeights[props.weight]) || 400};
`;

export const Body2 = styled(Text)<TypographyType>`
  font-weight: ${(props) => (props.weight && FontWeights[props.weight]) || 400};
`;

export const Small = styled(Text)<TypographyType>`
  font-weight: ${(props) => (props.weight && FontWeights[props.weight]) || 400};
  font-size: 12px;
`;

export const Caption = styled(Text)<TypographyType>`
  font-weight: ${(props) => (props.weight && FontWeights[props.weight]) || 300};
  font-size: 10px;
`;

export const TextLink = styled(Text)<TypographyType>`
  color: ${(props) => props.color || THEME.colors.link};
  cursor: pointer;
  vertical-align: middle;
`;
