import React, { FC, useEffect, useState } from "react";
import { number, object, string } from "yup";
import { Form, Formik, FormikHelpers } from "formik";
import { navigate, RouteComponentProps, useLocation, useParams } from "@reach/router";

import AdminWrapper from "../index";
import Button from "components/auth/Button";
import TextField from "components/auth/TextField";
import { Intents } from "common/enums";
import SelectField from "components/auth/SelectField";
import { Box } from "components/app/styles/generic";
import { H4, Small } from "components/app/styles/typography";
import client from "common/client";
import { JQL_ENDPOINT } from "common/config";
import { DebtType } from "models/app/Debt";
import { THEME } from "common/theme";
import { AxiosResponse } from "axios";
import { ResponsePayloadType } from "models/types/Services";
import { AUTHENTICATION_CLASS } from "common/constants";

const debtBreadcrumbs = [
  { label: "Home", link: "/app/welcome" },
  { label: "Debts", link: "/app/admin/debts" },
  { label: "Log a Debt" },
];

const currency = [
  {
    label: "Select Currency",
    value: "",
  },
  {
    label: "NGN",
    value: "ngn",
  },
  {
    label: "USD",
    value: "usd",
  },
  {
    label: "GBP",
    value: "gbp",
  },
];

const initialValues = {
  date: "",
  amount: "",
  currency: "ngn",
};

const validationSchema = object({
  date: string().required(),
  currency: string().required(),
  amount: number().min(1).required(),
});

type AddDebtFormData = {
  date: string;
  amount: string;
  currency: string;
};

const LogDebt: FC<RouteComponentProps<{ mdaId: string; debtorId: string }>> = ({
  mdaId = "",
  debtorId = "",
}) => {
  const { pathname } = useLocation();
  const { debtId } = useParams();
  const [debt, setDebt] = useState<DebtType>();

  const isDebtChild = pathname === "/app/admin/debts/add";

  useEffect(() => {
    if (!debtId) return;

    client
      .post(JQL_ENDPOINT, {
        __meta__: {
          authenticationClass: AUTHENTICATION_CLASS,
          namespace: "debt.Debt",
          schema: "model",
          intent: Intents.retrieveOne,
          uniqueObject: {
            id: debtId,
          },
        },
        id: null,
        debtType: {
          name: null,
          mda: {
            id: null,
            name: null,
          },
        },
        "currency({function: get_display_name})": null,
        amountOutstanding: null,
      })
      .then((response: AxiosResponse<ResponsePayloadType>) => {
        setDebt(response.data?.data);
      });
  }, [debtId]);

  const onSubmit = async (values: AddDebtFormData, actions: FormikHelpers<AddDebtFormData>) => {
    actions.setSubmitting(true);

    await client.post(JQL_ENDPOINT, {
      __meta__: {
        authenticationClass: AUTHENTICATION_CLASS,
        namespace: "debt.DebtLog",
        schema: "model",
        intent: Intents.create,
      },
      debt: debtId,
      ...values,
    });

    navigate(`/app/admin/debts`);

    actions.setSubmitting(false);
  };

  const mdaBreadcrumbs = [
    { label: "Home", link: "/app/welcome" },
    { label: "MDAs", link: "/app/admin/mdas" },
    { label: "MDA Debtors", link: `/app/admin/mdas/${mdaId}/debtors` },
    { label: "Log a Debt" },
  ];

  return (
    <AdminWrapper
      title="Log a Debt"
      className="col-12 col-lg-8"
      breadcrumbs={isDebtChild ? debtBreadcrumbs : mdaBreadcrumbs}
    >
      <Box marginBottom={THEME.sizes.sm}>
        <H4>{debt?.debtType.name}</H4>
        <Small colour={THEME.fontColours.light}>{debt?.debtType.mda.name}</Small>
        <Small weight={"bold"} colour={THEME.colors.darkRed}>
          {debt?.currency} {debt?.amountOutstanding.toLocaleString("en-GB")}
        </Small>
        <hr />
      </Box>
      <Formik
        enableReinitialize
        onSubmit={onSubmit}
        validationSchema={validationSchema}
        initialValues={initialValues}
      >
        {({ isSubmitting }) => (
          <Form>
            <div className="row">
              <div className="col-md-6">
                <TextField type="date" name="date" label="Date Due" />
              </div>
            </div>

            <div className="row">
              <div className="col-md-6">
                <SelectField label="Currency" name="currency" options={currency} />
              </div>
            </div>

            <div className="row">
              <div className="col-md-6">
                <TextField name="amount" label="Amount Owed" />
              </div>
            </div>

            <div className="text-left mt-2">
              <Button
                type="submit"
                label="Log Debt"
                loading={isSubmitting}
                disabled={isSubmitting}
              />
            </div>
          </Form>
        )}
      </Formik>
    </AdminWrapper>
  );
};

export default LogDebt;
