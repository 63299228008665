import React, { FC, useEffect, useState } from "react";
import { number, object, string } from "yup";
import { Form, Formik } from "formik";
import { navigate, RouteComponentProps } from "@reach/router";
import AdminWrapper from "../index";
import Button from "components/auth/Button";
import TextArea from "components/auth/TextArea";
import TextField from "components/auth/TextField";
import { Intents } from "common/enums";
import SelectField from "components/auth/SelectField";
import AutoComplete from "components/auth/Autocomplete";
import client from "common/client";
import { JQL_ENDPOINT } from "common/config";
import { CompanyType } from "models/app/Profile";
import { Box } from "components/app/styles/generic";
import { H4, Small } from "components/app/styles/typography";
import { THEME } from "common/theme";
import { InjectableOptions } from "models/types/Services";
import { AUTHENTICATION_CLASS } from "common/constants";

const currency = [
  {
    label: "Select Currency",
    value: "",
  },
  {
    label: "NGN",
    value: "ngn",
  },
  {
    label: "USD",
    value: "usd",
  },
  {
    label: "GBP",
    value: "gbp",
  },
];

const initialValues = {
  company: "",
  paymentDate: "",
  amount: "",
  currency: "ngn",
  remarks: "",
};

const paymentBreadcrumbs = [
  { label: "Home", link: "/app/welcome" },
  { label: "Payments", link: "/app/admin/payments" },
  { label: "Add Payment" },
];

const validationSchema = object({
  company: string().required(),
  debt: string().when("company", {
    is: (val) => !!(val && val.length > 0),
    then: string().required("Debt is a required field"),
  }),
  paymentDate: string().required(),
  amount: number().min(1).required(),
  currency: string().required(),
  remarks: string(),
});

const AddPayment: FC<RouteComponentProps<{ mdaId: string; debtorId: string }>> = ({
  mdaId = "",
  debtorId = "",
}) => {
  const [companyId, setCompanyId] = useState<number | string | undefined>();
  const [company, setCompany] = useState<CompanyType | undefined>();
  const [debts, setDebts] = useState<Array<InjectableOptions>>([]);

  // retrieve company information after company select
  useEffect(() => {
    if (!companyId) return;

    (async () => {
      const response = await client.post(JQL_ENDPOINT, {
        __meta__: {
          authenticationClass: AUTHENTICATION_CLASS,
          namespace: "profile.Company",
          schema: "model",
          intent: Intents.retrieveOne,
          uniqueObject: {
            id: companyId,
          },
        },
        tin: null,
        registrationNumber: null,
        name: null,
        debts: {
          id: null,
          debtType: {
            id: null,
            name: null,
          },
        },
      });
      setCompany(response?.data?.data);
      const _debts = response.data?.data.debts?.map((item) => ({
        label: item?.debtType?.name,
        value: item?.id,
      }));
      setDebts(_debts);
    })();
  }, [companyId]);

  // submit the payment
  const onSubmit = async (values, actions) => {
    actions.setSubmitting(true);
    await client.post(JQL_ENDPOINT, {
      __meta__: {
        authenticationClass: AUTHENTICATION_CLASS,
        namespace: "debt.ManualTransactionLog",
        schema: "model",
        intent: Intents.create,
      },
      ...values,
    });
    navigate("/app/admin/payments");
    actions.setSubmitting(false);
  };

  return (
    <AdminWrapper
      title="Log a Payment"
      className="col-12 col-lg-8"
      breadcrumbs={paymentBreadcrumbs}
    >
      {company && (
        <Box marginBottom={THEME.sizes.sm}>
          <H4>{company.name}</H4>
          <Small colour={THEME.fontColours.light}>
            Registration Number: {company.registrationNumber}
          </Small>
          <Small colour={THEME.fontColours.light}>TIN: {company.tin}</Small>
          <hr />
        </Box>
      )}
      {!company && (
        <Box marginBottom={THEME.sizes.sm}>
          <H4>Select a company to continue</H4>
        </Box>
      )}
      <Formik
        enableReinitialize
        onSubmit={onSubmit}
        validationSchema={validationSchema}
        initialValues={{ ...initialValues, debt: debtorId }}
      >
        {({ isSubmitting, values }) => {
          setCompanyId(values.company);
          return (
            <Form>
              {!companyId && (
                <div className="row">
                  <div className="col-md-12">
                    <AutoComplete
                      name="company"
                      disabled={!!values.company}
                      namespace="profile.Company"
                      defaultOptions={[{ label: "Select Company", value: "" }]}
                    />
                  </div>
                </div>
              )}

              {companyId && (
                <>
                  <div className="row">
                    <div className="col-md-6">
                      <SelectField
                        label="Debt"
                        name="debt"
                        options={[{ value: "", label: "-----" }, ...debts]}
                      />
                    </div>

                    <div className="col-md-6">
                      <TextField type="date" name="paymentDate" label="Payment Date" />
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-6">
                      <SelectField label="Currency" name="currency" options={currency} />
                    </div>

                    <div className="col-md-6">
                      <TextField name="amount" label="Amount" />
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-12">
                      <TextArea name="remarks" label="Remarks" rows={2} />
                    </div>
                  </div>
                </>
              )}

              <div className="text-left mt-2">
                <Button
                  type="submit"
                  label={companyId ? "Log Payment" : "Retrieve Company"}
                  loading={isSubmitting}
                  disabled={isSubmitting}
                />
              </div>
            </Form>
          );
        }}
      </Formik>
    </AdminWrapper>
  );
};

export default AddPayment;
