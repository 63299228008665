import React from 'react'
import { Formik, Field } from 'formik'

import {
  ModalWrapper,
  ChangePasswordForm,
  FormInputLabel,
  ActionButton,
  CloseModalBtn,
} from 'components/app/styles'
import { httpClient } from 'utils/index'
import Button from 'components/auth/Button'
import TextField from 'components/auth/TextField'
import { AUTHENTICATION_CLASS } from 'common/constants'

const ChangePasswordModal = ({ closeModal }) => {
  const [, setLoading] = React.useState(false)

  const handleResetPassword = (data) => {
    ;(async () => {
      setLoading(true)
      const res = await httpClient.post('/jql', {
        __meta__: {
          authenticationClass: AUTHENTICATION_CLASS,
          namespace: 'system.User',
          schema: 'controller',
          intent: 'reset_password',
        },
        ...data,
      })
      setLoading(false)
      closeModal()
    })()
  }

  return (
    <ModalWrapper>
      <Formik
        initialValues={{
          current_password: '',
          new_password: '',
          confirm_new_password: '',
        }}
        onSubmit={(values, { setSubmitting }) => {
          handleResetPassword(values)
        }}
      >
        {({ handleSubmit, isSubmitting }) => {
          return (
            <ChangePasswordForm onSubmit={handleSubmit}>
              <CloseModalBtn onClick={closeModal}>
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none">
                  <path
                    d="M18 6L6 18M6 6l12 12"
                    stroke="#C20303"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
                Close
              </CloseModalBtn>

              <p>Let’s change that password!</p>

              <div className="row">
                <div className="col-12">
                  <TextField type="password" name="current_password" label="Current Password" />
                </div>
              </div>

              <div className="row">
                <div className="col-12">
                  <TextField type="password" name="new_password" label="New Password" />
                </div>
              </div>

              <div className="row">
                <div className="col-12">
                  <TextField
                    type="password"
                    name="confirm_new_password"
                    label="Re-enter new Password"
                  />
                </div>
              </div>

              <div className="text-left mt-2">
                <Button
                  type="submit"
                  label="SAVE PASSWORD"
                  loading={isSubmitting}
                  disabled={isSubmitting}
                />
              </div>
            </ChangePasswordForm>
          )
        }}
      </Formik>
    </ModalWrapper>
  )
}

export default ChangePasswordModal
