import React, { useContext } from "react";
import { Modal } from "antd";
import { Link } from "gatsby";
import toast from "react-hot-toast";
import { navigate } from "@reach/router";
import { FaUserEdit, FaUserTimes, FaUserMinus, FaEdit } from "react-icons/fa";
import AdminWrapper from "../index";
import { AuthContext } from "contexts";
import { UserType } from "models/app/Profile";
import { makeRequest } from "utils/httpClient";
import DataTable from "components/generic/table/DataTable";
import { CastJqlType, JqlQueryType } from "models/types/Services";
import ActionMenu from "components/generic/menu/ActionMenu";
import { Intents } from "common/enums";
import { ColumnHeader } from "components/pages/admin/users/constants";
import { UserTypes } from "models/db-schema";
import { AUTHENTICATION_CLASS } from "common/constants";

const internalAdmins = [UserTypes.SUPER_ADMIN, UserTypes.ADMIN];

const restrictedKeys = [
  {
    key: "key__userMenuItem__delete",
    label: "Delete User",
    icon: FaUserMinus,
  },
  {
    key: "key__userMenuItem__deactivate",
    label: "Deactivate User",
    icon: FaUserTimes,
  },
  {
    key: "key__userMenuItem__manage_companies",
    label: "Manage Associated Companies",
    icon: FaEdit,
  },
];

const generalKeys = [
  {
    key: "key__userMenuItem__edit",
    label: "Edit User",
    icon: FaUserEdit,
  },
];

const Users = () => {
  const { currentUser } = useContext(AuthContext);
  const isMDAUser =
    currentUser?.userType === UserTypes.MDA_USER || currentUser?.userType === UserTypes.MDA_ADMIN;

  const { confirm } = Modal;
  const columns = isMDAUser ? ColumnHeader : [...ColumnHeader, { Header: "Actions" }];
  const jql: JqlQueryType<CastJqlType<UserType>> = {
    __meta__: {
      authenticationClass: AUTHENTICATION_CLASS,
      namespace: "system.User",
      schema: "model",
      intent: Intents.retrieve,
    },
    id: null,
    fullname: null,
    email: null,
    phone: null,
    userType: null,
    mda: {
      id: null,
      code: null,
      name: null,
    },
    companies: {
      uuid: null,
      name: null,
      registrationNumber: null,
      tin: null,
      phone1: null,
      email: null,
      website: null,
      businessName: null,
      verified: null,
    },
  };

  // menu click handler
  const handleMenuClick = (e, id) => {
    switch (e.key) {
      // case 'key__peopleMenuItem__verify':
      //   // navigate to the company verification page
      //   // process({ personId: id, action: 'verify_person' })
      //   goto('companyAdminVerification', { userId: id })
      //   break
      case "key__userMenuItem__edit":
        navigate(`/app/admin/users/${id}/edit`);
        break;

      case "key__userMenuItem__delete":
        confirm({
          okText: "Yes",
          centered: true,
          okType: "danger",
          cancelText: "No",
          title: "Are you sure you want to delete this user?",
          onOk() {
            const runAsync = async () => {
              try {
                const [data, , error] = await makeRequest({
                  path: "/jql",
                  method: "POST",
                  config: {
                    data: {
                      __meta__: {
                        authenticationClass: AUTHENTICATION_CLASS,
                        namespace: "system.User",
                        schema: "model",
                        intent: Intents.deleteOne,
                        uniqueObject: {
                          id,
                        },
                      },
                    },
                  },
                });
                toast.success(data.message);
              } catch (error) {
                // toast.error("Failed to delete user");
              }
            };

            runAsync();
          },
        });
        break;

      case "key__userMenuItem__deactivate":
        confirm({
          okText: "Yes",
          centered: true,
          okType: "danger",
          cancelText: "No",
          title: "Are you sure you want to deactivate this user?",
          onOk() {
            const runAsync = async () => {
              const [data, , error] = await makeRequest({
                path: "/jql",
                method: "POST",
                config: {
                  data: {
                    __meta__: {
                      authenticationClass: AUTHENTICATION_CLASS,
                      namespace: "system.User",
                      schema: "controller",
                      intent: Intents.deactivate,
                    },
                    id,
                  },
                },
              });

              if (error && error.errors && Array.isArray(error.errors)) {
                error.errors.map(({ error_message }) => {
                  return toast.error(error_message);
                });
              } else {
                toast.success(data.message);
              }
            };

            runAsync();
          },
        });
        break;

      case "key__userMenuItem__manage_companies":
        navigate(`/app/admin/users/${id}/associated-companies`);
        break;

      default:
        break;
    }
  };

  const keys = internalAdmins.includes(currentUser?.userType)
    ? [...generalKeys, ...restrictedKeys]
    : generalKeys;

  // table row component
  const TableRow = ({ row }: { row: UserType }) => {
    return (
      <tr>
        <td>
          {row.fullname} <br />
          <strong>{row.userType}</strong>
        </td>
        <td>{row.email}</td>
        <td>{row.phone}</td>
        <td>{row.mda?.name}</td>
        <td>
          {row.companies.map((v) => (
            <div>{v.name}</div>
          ))}
        </td>
        {!isMDAUser && (
          <td>
            <ActionMenu id={row.id} keys={keys} clickHandler={handleMenuClick} />
          </td>
        )}
      </tr>
    );
  };

  return (
    <AdminWrapper>
      <DataTable
        columns={columns}
        jqlQuery={jql}
        title="List of People"
        enableSearch
        description={<Link to="/app/admin/users/add">Add User</Link>}
        searchPlaceholder={"search for a user"}
        RowComponent={TableRow}
        debug={true}
      />
    </AdminWrapper>
  );
};

export default Users;
