import React, { useEffect } from "react";
import styled from "styled-components";
import BeatLoader from "react-spinners/BeatLoader";
import { RouteComponentProps, useLocation, useNavigate, useParams } from "@reach/router";
import _ from "lodash";

import { CompanyInfoLeft } from "components/app/styles";
import AuthLayout from "components/auth/AuthLayout";
import Tabs from "components/auth/Tabs";
import Card from "components/auth/Card";
import { AuthContext } from "contexts";
import Account from "./Account";
import DebtPortfolio from "./DebtPortfolio";
import TaxInformation from "components/pages/company/TaxInformation";
import Settings from "./Settings";
import CompanySettings from "./company-settings";
import Switch from "components/Switch";
import { CompanyType, PersonType, RepresentativeType } from "models/app/Profile";
import { DebtSummaryType, DebtType, DebtTypeType } from "models/app/Debt";
import { BankType, CompanyBankAccountType, PersonBankAccountType } from "models/app/Services";
import { Pages, PortalTabProps } from "components/pages/company/types";
import CardPayment from "components/pages/company/payment/card/Payment";
import DirectDebitPayment from "components/pages/company/payment/direct-debit/Payment";
import { parseUrlQuery } from "common/utils";
import Confirmation from "components/pages/company/payment/card/Confirmation";
import useJql from "hooks/useJql";
import { AuthLayoutWrapper } from "components/auth/AuthLayout/styles";
import If from "components/generic/If";
import { AUTHENTICATION_CLASS } from "common/constants";

const PageLoaderWrapper = styled.div`
  height: 560px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const breadcrumbs = [
  { label: "Home", link: "/app/welcome" },
  { label: "Admin", link: "/app/admin/companies" },
  { label: "Company Overview" },
];

const Company: React.FC<RouteComponentProps<{ companyId: string }>> = ({ companyId }) => {
  const { currentUser } = React.useContext(AuthContext);

  const [activeTab, setActiveTab] = React.useState<PortalTabProps["activeTab"]>(Pages.account);
  const [transactionId, setTransactionId] = React.useState<number | string | null>();

  const [debts, setDebts] = React.useState<Array<DebtType>>([]);
  const [, setDebtTypes] = React.useState<Array<DebtTypeType>>([]);

  const [representatives, setRepresentatives] = React.useState<Array<RepresentativeType>>([]);
  const [bankAccounts, setBankAccounts] = React.useState<
    Array<CompanyBankAccountType | PersonBankAccountType>
  >([]);

  const [company, setCompany] = React.useState<Partial<CompanyType>>({
    name: "",
    tin: "",
    registrationNumber: "",
    nodeDebtCurrencySummary: {
      ngn: {
        amountOutstandingStr: "",
        amountOwedStr: "",
        amountPaidStr: "",
        currency: "",
      },
    },
    representatives: [],
    directors: [],
    debts: [],
  });
  const { page } = useParams();
  const urlQuery = parseUrlQuery(useLocation().search);
  const urlPage = urlQuery?.p;
  const navigate = useNavigate();

  const goto = (urlPage) => {
    navigate(`/app/company/${companyId}?p=${urlPage}`);
  };

  if (!companyId) {
    return <></>;
  }

  const { data: companyData, isLoading: companyIsLoading } = useJql({
    jqlQuery: {
      __meta__: {
        authenticationClass: AUTHENTICATION_CLASS,
        namespace: "profile.Company",
        schema: "model",
        intent: "retrieveOne",
        uniqueObject: {
          id: companyId,
        },
      },
      id: null,
      name: null,
      verified: null,
      tin: null,
      registration_number: null,
      website: null,
      email: null,
      phone1: null,
      line1: null,
      line2: null,
      city: null,
      state: null,
      node_debt_summary_ngn: null,
      node_debt_currency_summary: null,
      representatives: {
        user: {
          id: null,
          email: null,
          fullname: null,
          phone: null,
        },
      },
      debts: {
        __meta__: {
          authenticationClass: AUTHENTICATION_CLASS,
          limit: 100,
        },
        id: null,
        debt_description: null,
        debt_type: {
          id: null,
          name: null,
          mda: {
            id: null,
            name: null,
          },
        },
        amount_owed: null,
        amount_paid: null,
        amount_outstanding: null,
        currency: null,
      },
      bank_accounts: {
        id: null,
        bank: {
          code: null,
          name: null,
        },
        account_name: null,
        nuban: null,
        verified: null,
        __str__: null,
      },
      num_of_creditors: null,
      num_of_debts: null,
    },
  });
  const { data: meta, isLoading: metaIsLoading } = useJql({
    jqlQuery: {
      __meta__: {
        authenticationClass: AUTHENTICATION_CLASS,
        intent: "documentation",
        namespace: "profile.Company",
        schema: "model",
      },
    },
  });
  const { data: user, isLoading: userIsLoading } = useJql({
    jqlQuery: {
      __meta__: {
        authenticationClass: AUTHENTICATION_CLASS,
        namespace: "system.User",
        schema: "model",
        intent: "retrieveOne",
        uniqueObject: {
          id: currentUser?.id,
        },
      },
      id: null,
      person: {
        id: null,
        first_name: null,
        last_name: null,
        tin: null,
        jtb_tin: null,
        bvn: null,
        nin: null,
        phone: null,
        email: null,
      },
    },
  });
  const { data: banksData, isLoading: banksIsLoading } = useJql({
    jqlQuery: {
      __meta__: {
        authenticationClass: AUTHENTICATION_CLASS,
        namespace: "services.Bank",
        schema: "model",
        intent: "retrieve",
        limit: 100,
      },
      id: null,
      code: null,
      name: null,
    },
  });

  useEffect(() => {
    if (urlQuery?.TRANSACTION_ID) {
      setActiveTab(Pages.paymentCardConfirmation);
      setTransactionId(urlQuery?.TRANSACTION_ID);
    } else {
      setActiveTab(urlPage as Pages);
    }
  }, [urlQuery]);

  useEffect(() => {
    if (!companyData) return;
    setCompany(companyData);
    setDebts(companyData.debts);
    setRepresentatives(companyData.representatives);
    setBankAccounts(companyData.bankAccounts);

    const _debtTypes = [];
    companyData.debts.map((x: DebtType) => {
      // @ts-ignore
      return !_.some(_debtTypes, { id: x.debtType?.id }) && _debtTypes.push(x.debtType);
    });
    setDebtTypes(_debtTypes);
  }, [companyData]);

  if (metaIsLoading || userIsLoading || banksIsLoading || companyIsLoading) {
    return (
      <AuthLayoutWrapper>
        <div className="container">
          <div className="mb-4 mr-auto">
            <PageLoaderWrapper>
              <BeatLoader color="#000" size={30} loading={true} />
            </PageLoaderWrapper>
          </div>
        </div>
      </AuthLayoutWrapper>
    );
  }

  if (!user || !companyData || !banksData) {
    return (
      <AuthLayoutWrapper>
        <div className="container">
          <h4>Oops!</h4>
          <p>Failed to fetch page data...</p>
        </div>
      </AuthLayoutWrapper>
    );
  }

  return (
    <AuthLayout title="Company Overview" breadcrumbs={breadcrumbs}>
      <div className="col-lg-4 mb-4 mb-lg-0">
        <Card title={company.name}>
          <CompanyInfoLeft>
            <div>
              <p>
                Email: <span>{company.email}</span>
              </p>

              <p>
                Phone: <span>{company.phone1}</span>
              </p>

              <p>
                Website: <span>{company.website}</span>
              </p>

              <p>
                RC: <span>{company.registrationNumber}</span>
              </p>

              <p>
                TIN: <span>{company.tin}</span>
              </p>

              <p>
                Rep. Online:{" "}
                <span>
                  {Array.isArray(company.representatives) &&
                  company.representatives.some((reps) => reps?.user?.id === currentUser?.id)
                    ? company.representatives.find((reps) => reps?.user?.id === currentUser?.id)
                        ?.user?.fullname || "No Representative online"
                    : "No Representative Online"}
                </span>
              </p>

              <p className="text-capitalize">
                Address:{" "}
                <span>
                  {company.line1}, {company.city}, {company.state}
                </span>
              </p>
            </div>
          </CompanyInfoLeft>
        </Card>
      </div>

      <div className="col-lg-8">
        <Card>
          <If is={!activeTab}>
            <Tabs>
              {currentUser?.userType !== "ADMIN" && (
                <div label="Account">
                  <Account
                    goto={goto}
                    debts={debts}
                    companyId={companyId}
                    bankAccounts={bankAccounts}
                    debtSummary={company.nodeDebtCurrencySummary as any}
                  />
                </div>
              )}

              <div label="Debt Portfolio">
                <DebtPortfolio
                  goto={goto}
                  debts={debts}
                  companyId={companyId}
                  bankAccounts={bankAccounts}
                  debtSummary={company.nodeDebtCurrencySummary?.ngn as DebtSummaryType}
                />
              </div>

              <div label="Tax Information">
                <TaxInformation />
              </div>

              <div label="Company Settings">
                <CompanySettings
                  company={company as CompanyType}
                  states={meta?.injectable?.state?.options || []}
                />
              </div>

              <div label="Settings">
                <Settings
                  banks={banksData}
                  person={user?.person}
                  companyId={companyId}
                  bankAccounts={bankAccounts}
                  representatives={representatives}
                  setBankAccounts={setBankAccounts}
                />
              </div>
            </Tabs>
          </If>

          {/* AUXILIARY VIEWS */}
          <Switch condition={activeTab === Pages.paymentDirectDebit}>
            <DirectDebitPayment debts={debts} companyId={companyId} bankAccounts={bankAccounts} />
          </Switch>
          <Switch condition={activeTab === Pages.paymentCard}>
            <CardPayment debts={debts} company={company as Partial<CompanyType>} />
          </Switch>
          <Switch condition={page === "payment-card-confirmation"}>
            <Confirmation transactionId={transactionId} goto={goto} />
          </Switch>
        </Card>
      </div>
    </AuthLayout>
  );
};

export default Company;
