export const ColumnHeader = [
  {
    Header: "Fullname",
  },
  {
    Header: "Email",
  },
  {
    Header: "Phone",
  },
  {
    Header: "MDA",
  },
  {
    Header: "Associated Companies",
  },
];
