import styled from 'styled-components'

export const SearchWrapper = styled.div`
  .form-group {
    margin-bottom: 0;
  }

  .search-result-wrapper {
    height: 25rem;
    overflow-y: auto;

    &.empty-list {
      display: flex;
      align-items: center;
      justify-content: center;

      svg {
        opacity: 0.25;
        color: ${({ theme }) => theme.primary_color};
      }
    }

    .company-list {
      thead {
        top: 0;
        z-index: 10;
        position: sticky;
        background-color: ${({ theme }) => theme.white};

        th {
          border-top-width: 0;
        }
      }

      tbody {
        th,
        td {
          vertical-align: middle;
        }
      }
    }
  }

  .search-img-wrapper {
    height: 31.1rem;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
`

export const ConfirmAssociation = styled.div`
  background-color: #fff;
  width: 100%;
  max-width: 500px;
  margin: 10rem auto 0;
  padding: 1.875rem 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 3px;
  border-top: 4px solid #156148;

  p {
    font-weight: normal;
    font-size: 14px;
    line-height: 22px;
    color: #1e3c10;
    max-width: 310px;
    text-align: center;
    margin-bottom: 2.5rem;
  }

  div {
    display: flex;
  }
`
