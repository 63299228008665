import React, { FC, ReactElement } from 'react'
import { Field, useField, ErrorMessage } from 'formik'

import { SelectFieldWrapper } from './styles'

type Props = {
  name: string
  label?: string
  disabled?: boolean
  options: {
    value: string | number
    label: string
  }[]
}

const SelectField: FC<Props> = ({ name, label = '', disabled = false, options = [] }) => {
  const [_, meta] = useField<string>(name)
  const isInvalid = !!(meta.touched && meta.error)

  return (
    <SelectFieldWrapper className="form-group">
      {label && <label htmlFor={name}>{label}</label>}

      <Field
        name={name}
        component="select"
        disabled={disabled}
        className={isInvalid ? 'form-control is-invalid' : 'form-control'}
      >
        {options.map(({ label, value }) => (
          <option key={value} value={value}>
            {label}
          </option>
        ))}
      </Field>

      <ErrorMessage name={name}>
        {(errMsg) => <div className="invalid-feedback">{errMsg}</div>}
      </ErrorMessage>
    </SelectFieldWrapper>
  )
}

export default SelectField
