import React from "react";
import HeaderCell from "components/generic/table/HeaderCell";

export const ColumnHeader = [
  {
    Header: "Debt Information",
  },
  {
    Header: "Company",
  },
  {
    Header: <HeaderCell textAlign={"text-right"} value={"Amount Outstanding"} />,
    accessor: "AmountOutstanding",
  },
  {
    Header: "Log Debt",
  },
  {
    Header: "Actions",
  },
];
