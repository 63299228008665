import styled from "styled-components";
import { darken } from "polished";

export const ButtonWrapper = styled.button<{ size: "sm" | "base"; variant: any }>`
  border: none;
  z-index: 0;
  font-weight: 500;
  text-align: center;
  min-width: 5.3125rem;
  font-size: 0.6875rem;
  text-transform: uppercase;
  color: ${({ theme }) => theme.white};
  background: ${({ theme, variant }) => theme[variant]};
  padding: ${({ size }) => (size === "base" ? "0.8rem 1.5rem" : "0.5rem 1rem")};

  &:hover {
    color: ${({ theme }) => theme.white};
    background: ${({ theme }) => darken(0.05, theme.primary_color)};
  }

  &::after,
  &::before {
    content: none;
  }

  > div {
    margin: 0 auto;
  }
`;
