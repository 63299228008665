import React from 'react'

const useModal = () => {
  const [isModalOpen, setModalOpen] = React.useState(false)

  React.useEffect(() => {
    if (isModalOpen) {
      document.body.style.overflowY = 'hidden'
    } else {
      document.body.style.overflowY = 'scroll'
    }
  }, [isModalOpen])

  const openModal = () => setModalOpen(true)
  const closeModal = () => setModalOpen(false)

  return { isModalOpen, openModal, closeModal }
}

export default useModal
