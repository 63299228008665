import React from "react";
import { Formik, Form } from "formik";
import { Link } from "gatsby";
import { RouteComponentProps } from "@reach/router";
import { object, string } from "yup";
import PublicLayout from "components/auth/PublicLayout";
import TextField from "components/auth/TextField";
import Button from "components/auth/Button";
import { JqlService } from "common/client";
import { handleError, handleResponse } from "common/utils";
import { AUTHENTICATION_CLASS } from "common/constants";

const validationSchema = object({
  email: string().email().required("Email is required"),
});

const ForgotPassword: React.FC<RouteComponentProps> = ({ navigate }: any) => {
  const handleSubmit = async (values) => {
    try {
      const response = await JqlService.post(JqlService.JQL_ENDPOINT, {
        __meta__: {
          namespace: "system.User",
          intent: "initiatePasswordReset",
          schema: "controller",
          authenticationClass: AUTHENTICATION_CLASS,
        },
        ...values,
      });
      handleResponse(response);
      navigate("/app/auth/login");
    } catch (error) {
      handleError(error);
    }
  };
  return (
    <PublicLayout title="Forgot Password">
      <Formik
        validationSchema={validationSchema}
        initialValues={{ email: "" }}
        onSubmit={handleSubmit}
      >
        {({ handleSubmit, isSubmitting }) => {
          return (
            <Form onSubmit={handleSubmit}>
              <div className="mb-4">
                <TextField name="email" placeholder="john.doe@carterltd.com" />
              </div>

              <div className="d-flex flex-wrap align-items-center justify-content-between">
                <div className="mr-4">
                  <Button
                    type="submit"
                    label="SEND LINK"
                    loading={isSubmitting}
                    disabled={isSubmitting}
                  />
                </div>

                <div className="text-right">
                  <Link to="/app/auth/register">Create a new account</Link>
                </div>
              </div>
            </Form>
          );
        }}
      </Formik>
    </PublicLayout>
  );
};

export default ForgotPassword;
