import React, { useMemo } from "react";
import { AUTHENTICATION_CLASS } from "common/constants";
import useJql from "hooks/useJql";
import styled from "styled-components";
import numeral from "numeral";

const companiesCountJQL = {
  __meta__: {
    namespace: "profile.Company",
    schema: "model",
    intent: "aggregate",
    authenticationClass: AUTHENTICATION_CLASS,
    exclude: {
      governmentOwnedEnterprise: true,
    },
    sideEffects: {
      global: true,
    },
  },
  countCompanies: {
    __meta__: {
      field: "*",
      function: "count",
    },
  },
};
const totalDebtRecovered = {
  __meta__: {
    namespace: "debt.Debt",
    schema: "model",
    intent: "aggregate",
    authenticationClass: AUTHENTICATION_CLASS,
    groupBy: ["currency"],
  },
  currency: null,
  totalRecovered: {
    __meta__: {
      field: "amount_paid",
      function: "sum",
    },
  },
};

const mdaJQL = {
  __meta__: {
    namespace: "system.MDA",
    schema: "model",
    intent: "aggregate",
    authenticationClass: "jwt",
  },
  countMDA: {
    __meta__: {
      field: "*",
      function: "count",
    },
  },
};
const totalOutstandingDebts = {
  __meta__: {
    namespace: "debt.Debt",
    schema: "model",
    intent: "aggregate",
    authenticationClass: "jwt",
    groupBy: ["currency"],
  },
  currency: null,
  totalOutstanding: {
    __meta__: {
      field: "amount_outstanding",
      function: "sum",
    },
  },
};
const formatAmountWithAbbr = (amount: number, currency: string) => {
  return `${currency.toUpperCase()}${
    amount < 1000000 ? numeral(amount).format("0,0.00") : numeral(amount).format("0,0.00a")
  }`;
};

function MetricsList() {
  const { data: companiesCount } = useJql({
    jqlQuery: companiesCountJQL,
  });

  const { data: totalRecovered } = useJql({
    jqlQuery: totalDebtRecovered,
  });

  const { data: mdaCount } = useJql({
    jqlQuery: mdaJQL,
  });
  const { data: totalOutstanding } = useJql({
    jqlQuery: totalOutstandingDebts,
  });

  const metrics = useMemo(
    () => [
      {
        name: "Total Debt",
        value: totalOutstanding?.[0]
          ? formatAmountWithAbbr(
              totalOutstanding?.[0]?.totalOutstanding,
              totalOutstanding?.[0]?.currency,
            )
          : "",
      },
      {
        name: "Recovered Debt",
        value: totalRecovered?.[0]
          ? formatAmountWithAbbr(totalRecovered?.[0]?.totalRecovered, totalRecovered?.[0]?.currency)
          : "",
      },
      {
        name: "Companies",
        value: numeral(companiesCount?.countCompanies).format("0,0"),
      },
      {
        name: "MDAs",
        value: numeral(mdaCount?.countMda).format("0,0") || "",
      },
    ],
    [mdaCount, totalOutstanding, totalRecovered, companiesCount],
  );

  return (
    <Ul className="list-unstyled">
      {metrics.map((item) => (
        <li key={"item" + item.name} className="">
          <div className="p-3 h-100 d-flex flex-column justify-content-between">
            <p className="font-weight-bold mb-2 h5">{item.value || 0}</p>{" "}
            <span className="font-weight-light mb-0">{item.name}</span>
          </div>
        </li>
      ))}
    </Ul>
  );
}
const Ul = styled.ul`
  display: grid;
  grid-gap: 20px;
  li > div {
    border-radius: 0.3125rem;
    box-shadow: 0 1px 10px 1px rgba(0, 0, 0, 0.05);
    background-color: #fff;
  }
  @media screen and (min-width: 760px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media screen and (min-width: 992px) {
    grid-template-columns: repeat(4, 1fr);
  }
`;
export default MetricsList;
