import React, { FC, ReactElement } from 'react'
import { Field, useField, ErrorMessage } from 'formik'

import { TextFieldWrapper } from './styles'

type Props = {
  name: string
  type?: string
  label?: string
  readOnly?: boolean
  disabled?: boolean
  icon?: ReactElement
  placeholder?: string
}

const TextField: FC<Props> = ({
  name,
  label = '',
  icon = null,
  type = ' text',
  readOnly = false,
  placeholder = '',
  disabled = false,
}) => {
  const hasIcon = !!icon
  const [_, meta] = useField<string>(name)
  const isInvalid = !!(meta.touched && meta.error)

  return (
    <TextFieldWrapper hasIcon={hasIcon} className="form-group">
      {label && <label htmlFor={name}>{label}</label>}

      <div className="form-inner-wrapper">
        {hasIcon && <div className="form-icon-wrapper">{icon}</div>}

        <Field
          name={name}
          type={type}
          disabled={disabled}
          readOnly={readOnly}
          placeholder={placeholder}
          className={isInvalid ? 'form-control is-invalid' : 'form-control'}
        />
      </div>

      <ErrorMessage name={name}>
        {(errMsg) => <div className="invalid-feedback">{errMsg}</div>}
      </ErrorMessage>
    </TextFieldWrapper>
  )
}

export default TextField
