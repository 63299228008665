import React, { useState, useMemo } from "react";
import { AUTHENTICATION_CLASS } from "common/constants";
import TableUnstyled from "components/TableUnstyled";
import numeral from "numeral";
import { SearchInputWrapper } from "components/pages/admin/styles";
import { debounce } from "lodash";
import useSWR from "swr";
import { Input } from "antd";
import { SearchOutlined } from "@ant-design/icons";

const pageSize = 10;
function MDAsChart() {
  const [searchString, setSearchString] = useState("");
  const [searchValue, setSearchValue] = useState("");
  const [pageIndex, setPageIndex] = useState(0);

  const jql = useMemo(
    () => ({
      __meta__: {
        namespace: "system.MDA",
        schema: "model",
        intent: "retrieve",
        authenticationClass: AUTHENTICATION_CLASS,
        side_effects: {
          global: true,
        },
        filter: {
          "name.icontains": searchString,
        },
        limit: pageSize,
        offset: pageSize * pageIndex,
        ordering: ["amountTotalByDebtors"],
      },
      id: null,
      name: null,
      countOfDebtTypesbyMda: {
        __meta__: {
          namespace: "debt.DebtType",
          intent: "subquery",
          ordering: "mda",
          onField: "mda",
          outerRefMapping: [
            {
              field: "pk",
              referenceField: "mda.pk",
            },
          ],
          sequencedProcess: [
            {
              function: "count",
              castType: "int",
              onField: "*",
            },
          ],
        },
      },
      amountTotalByDebtors: {
        __meta__: {
          namespace: "debt.DebtorCorporate",
          intent: "subquery",
          ordering: "mda",
          onField: "mda",
          outerRefMapping: [
            {
              field: "pk",
              referenceField: "mda.pk",
            },
          ],
          sequencedProcess: [
            {
              function: "sum",
              castType: "decimal",
              onField: "amount_outstanding",
              default: 0,
            },
          ],
        },
      },
      countOfDebtByDebtors: {
        __meta__: {
          namespace: "debt.DebtorCorporate",
          intent: "subquery",
          ordering: "mda",
          onField: "mda",
          outerRefMapping: [
            {
              field: "pk",
              referenceField: "mda.pk",
            },
          ],
          sequencedProcess: [
            {
              function: "count",
              castType: "int",
              onField: "*",
            },
          ],
        },
      },
    }),
    [pageIndex, searchString],
  );

  const { data } = useSWR(jql, { keepPreviousData: true });

  const handleSearch = React.useCallback(
    debounce((searchString: string) => {
      setSearchString(searchString);
    }, 500),
    [],
  );

  const columns = useMemo(
    () => [
      {
        Header: (
          <div className="d-flex align-items-center">
            <span>MDAs</span>
            <Input
              onChange={(evt) => {
                setSearchValue(evt.target.value);
                handleSearch(evt.target.value);
              }}
              className="ml-3"
              style={{ maxWidth: "300px" }}
              allowClear
              placeholder="Search MDA..."
              prefix={<SearchOutlined />}
            />
          </div>
        ),
        accessor: "name",
      },
      {
        Header: () => <div className="text-center">No. of Dept Type</div>,
        accessor: "countOfDebtTypesbyMda",
        Cell: ({ value }) => <div className="text-center">{value}</div>,
      },
      {
        Header: () => (
          <div className="text-right">
            Total Amount <br /> (NGN)
          </div>
        ),
        accessor: "amountTotalByDebtors",
        Cell: ({ value }) => <div className="text-right">{numeral(value).format("0,0")}</div>,
      },
      {
        Header: () => <div className="text-center">Total Companies</div>,
        accessor: "countOfDebtByDebtors",
        Cell: ({ value }) => <div className="text-center">{value}</div>,
      },
    ],
    [],
  );

  return (
    <div className="p-4">
      <div className="">
        {/* <h4 className="fw-bold mb-2">Dept Analysis</h4> */}
        {/* <p className="text-muted">% of debt collected compared to the total debt owed</p> */}
      </div>
      <div>
        {/* <div className="d-flex justify-content-end">
          <SearchInputWrapper className="text-right">
            <input
              type="text"
              placeholder="Search..."
              value={searchValue}
              onChange={(evt) => {
                setSearchValue(evt.target.value);
                handleSearch(evt.target.value);
              }}
            />
          </SearchInputWrapper>
        </div> */}
        <TableUnstyled
          columns={columns}
          data={data?.data || []}
          paginate
          onPageClick={(page) => {
            setPageIndex(page);
          }}
          pageSize={pageSize}
          totalCount={data?.extras?.filtered || 0}
        />
      </div>
    </div>
  );
}

export default MDAsChart;
