import styled from 'styled-components'

export const AuthContainer = styled.div`
  background-color: #e7eaec;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 8.125rem 0 5rem;
  // padding-bottom: 17.5625rem;
`

export const AuthHeaderText = styled.h3`
  font-weight: bold;
  font-size: 24px;
  line-height: 38px;
  color: #1e3c10;
  margin-bottom: 0.625rem;
  margin-top: 0;
`

export const WarningMessage = styled.p`
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  color: #fff;
  background-color: #f2994a;
  padding: 1rem;
  margin: 0;
  margin-bottom: 1.875rem;
  display: flex;

  svg {
    margin-right: 1rem;
  }
`

export const ErrorMessage = styled.div`
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  color: #fff;
  background-color: #f25c78;
  padding: 1rem;
  margin: 0;
  margin-bottom: 1.875rem;
  display: flex;
  align-items: center;

  div {
    display: flex;
    margin-right: 1rem;
  }
`

export const SuccessMessage = styled.p`
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  color: #fff;
  background-color: #42be65;
  display: flex;
  padding: 1rem;
  margin: 0;
  margin-bottom: 1.875rem;

  svg {
    margin-right: 1rem;
  }
`

export const AuthForm = styled.form`
  background-color: #fff;
  padding: 2.875rem 2.5rem;
  max-width: 480px;
  width: 100%;
  border: 0.5px solid #979797;
`

export const AuthDesc = styled.p`
  font-weight: normal;
  font-size: 24px;
  line-height: 18px;
  color: #1e3c10;
  margin-bottom: 1.625rem;
  margin-top: 0;
`

export const AuthDesc2 = styled.p`
  font-weight: 300;
  font-size: 14px;
  line-height: 18px;
  margin-bottom: 1.5625rem;
  color: #161616;
  margin-bottom: 2.1875rem;
  max-width: 300px;
`

export const FormInputLabel = styled.label`
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  color: #1e3c10;
  display: block;
  width: 100%;

  input {
    border: 1px solid #4f4f4f;
    font-weight: normal;
    font-size: 16px;
    line-height: 18px;
    color: #393939;
    padding: 0.6875rem;
    display: block;
    margin-top: 0.625rem;
    width: 100%;
    outline: none;
    border-radius: 0;

    &::placeholder {
      /* Chrome, Firefox, Opera, Safari 10.1+ */
      color: #a8a8a8;
      opacity: 1; /* Firefox */
    }

    &:-ms-input-placeholder {
      /* Internet Explorer 10-11 */
      color: #a8a8a8;
    }

    &::-ms-input-placeholder {
      /* Microsoft Edge */
      color: #a8a8a8;
    }
  }

  &:first-of-type {
    margin-bottom: 0.9375rem;
  }

  &:last-of-type {
    margin-bottom: 1.9375rem;
  }

  &.forgot-password-label {
    margin-bottom: 3.875rem;
  }

  &.mb-0 {
    margin-bottom: 0 !important;
  }
`
export const CheckboxLabel = styled.label`
  display: flex;
  align-items: center;

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;

    &:checked + span {
      background-color: #000;
      background-image: url('/media/checkmark.svg');
      background-position: center center;
      background-repeat: no-repeat;
    }
  }

  span {
    display: inline-block;
    width: 20px;
    height: 20px;
    border: 1px solid #000;
    border-radius: 2px;
    margin-right: 0.625rem;
  }
`

export const Hr = styled.hr`
  margin-bottom: 2.1875rem;
`

export const RememberMeForgotPassword = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1.9375rem;

  a {
    font-size: 14px;
    line-height: 18px;
    color: #054ada;
    text-decoration: none;
  }
`

export const SubmitButton = styled.button`
  /* background-color: #4c9a2a;
  border-radius: 2px;
  font-weight: 600;
  font-size: 18px;
  line-height: 28px;
  color: #fff6fe;
  padding: 0.6875rem 2rem;
  margin-bottom: 2.0625rem;
  border: none;
  display: flex;
  align-items: center; */

  &.verify,
  &.search {
    margin-bottom: 0;
  }

  &.loading span {
    margin-left: 1rem;
  }
`

export const NoAccount = styled.p`
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  color: #1e3c10;
  margin: 0;

  a {
    font-size: 14px;
    line-height: 18px;
    color: #054ada;
    display: inline-block;
    margin-left: 1rem;
  }
`

export const TOS = styled(NoAccount)`
  margin-bottom: 1.875rem;
  margin-top: 1.875rem;
`
