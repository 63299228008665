import React, { FC, FocusEvent } from "react";
import { ValueType } from "react-select";
import AsyncSelect from "react-select/async";
import { useField, ErrorMessage } from "formik";

import { AUTHENTICATION_CLASS, Intents } from "common/constants";
import { SelectFieldWrapper } from "./styles";
import { makeRequest } from "utils/httpClient";

type Props = {
  name: string;
  label?: string;
  namespace: string;
  disabled?: boolean;
  resultObj?: { [key: string]: null | { [key: string]: null } };
  resultCallback?: (params: { [key: string]: any }) => { label: string; value: string };
  defaultOptions?: {
    value: string | number;
    label: string;
  }[];
  defaultValue?: {
    value: string | number;
    label: string;
  }[];

};

export type SelectOption<T = string | boolean> = {
  value: T;
  label: string;
};

const AutoComplete: FC<Props> = ({

  name,
  namespace,
  label = "",
  disabled = false,
  defaultOptions = [],
  defaultValue=[],
  resultObj = { id: null, name: null },
  resultCallback = ({ id, name }) => ({ label: name, value: id }),



}) => {
  const [field, meta, helpers] = useField<string>(name);
  const isInvalid = !!(meta.touched && meta.error);

  const onBlur = (event: FocusEvent<HTMLElement>) => {
    field.onBlur(event);
    helpers.setTouched(true);
  };

  const onChange = (option: ValueType<SelectOption, boolean>) => {
    if (option) {
      helpers.setValue(option.value);
    }
  };

  const loadOptions = async (inputValue: string) => {
    const params = {
      value: inputValue,
    };

    const [data] = await makeRequest({
      path: "/jql",
      method: "POST",
      config: {
        data: {
          __meta__: {
            authenticationClass: AUTHENTICATION_CLASS,
            namespace,
            schema: "model",
            intent: Intents.retrieve,
            search: { ...params },
            side_effects: {
              global: true,
            },
          },
          ...resultObj,
        },
      },
    });

    if (data.data && Array.isArray(data.data)) {
      return data.data.map(resultCallback);
    }

    return [];
  };

  return (
    <SelectFieldWrapper className="form-group">
      {label && <label htmlFor={name}>{label}</label>}

      <AsyncSelect
        name={name}
        cacheOptions
        onBlur={onBlur}
        instanceId={name}
        onChange={onChange}
        isDisabled={disabled}
        loadOptions={loadOptions}
        defaultOptions={defaultOptions}
        defaultValue={defaultValue}

      />

      <ErrorMessage name={name}>
        {(errMsg) => <div className="invalid-feedback">{errMsg}</div>}
      </ErrorMessage>
    </SelectFieldWrapper>
  );
};

export default AutoComplete;
