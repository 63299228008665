import React from "react";
import { Box, GridBox } from "components/app/styles/generic";
import { Field, Formik } from "formik";
import Select from "react-select";
import { navigate } from "gatsby";
import { Label, Row } from "components/pages/verify/styles";
import { H5 } from "components/app/styles/typography";
import { THEME } from "common/theme";
import { SubmitButton } from "components/auth";
import cx from "classnames";
import MoonLoader from "react-spinners/MoonLoader";
import { httpClient } from "utils/index";
import { DebtType } from "models/app/Debt";
import { CompanyBankAccountType, PersonBankAccountType } from "models/app/Services";
import { AUTHENTICATION_CLASS } from "common/constants";
import { formatSelectOptions } from "common/utils";

type IProps = {
  companyId?: string | number;
  debts: Array<DebtType>;
  bankAccounts: Array<CompanyBankAccountType | PersonBankAccountType>;
};

export default ({ companyId, debts, bankAccounts }: IProps) => {
  const [isLoading, setLoading] = React.useState(false);

  const debtOptions = formatSelectOptions(debts, "id", "debt_description");
  const bankAccountOptions = formatSelectOptions(bankAccounts, "id", "__str__");

  const handleSubmit = async (values) => {
    setLoading(true);
    try {
      await httpClient.post("/jql", {
        __meta__: {
          authenticationClass: AUTHENTICATION_CLASS,
          namespace: "debt.Payment",
          schema: "controller",
          intent: "payment_intent",
        },
        company: companyId,
        ...values,
      });
    } catch (e) {}

    // go to home page
    navigate(`/app/company/${companyId}?p=account`);

    setLoading(false);
  };

  if (isLoading) return null;

  return (
    <Box margin={0}>
      <Formik
        initialValues={{
          account: "",
          amount: "",
          debt: "",
        }}
        onSubmit={handleSubmit}
      >
        {({ handleSubmit, setFieldValue }) => (
          <form onSubmit={handleSubmit}>
            <Box>
              <Row>
                <H5 marginBottom={THEME.sizes.md} weight={"medium"}>
                  Payment Details
                </H5>

                <GridBox
                  backgroundColour={THEME.colors.grayA100}
                  padding={THEME.sizes.md}
                  marginBottom={THEME.sizes.md}
                  columns={2}
                >
                  <Label>
                    Select a Debt to Pay
                    <Select
                      name="debt"
                      onChange={(event) => setFieldValue("debt", event.value)}
                      options={debtOptions}
                    />
                  </Label>

                  <Label>
                    Account to Debit
                    <Select
                      name="account"
                      onChange={(event) => setFieldValue("account", event.value)}
                      options={bankAccountOptions}
                    />
                  </Label>
                </GridBox>

                <GridBox
                  backgroundColour={THEME.colors.grayA100}
                  padding={THEME.sizes.md}
                  marginBottom={THEME.sizes.md}
                  columns={2}
                >
                  <Label>
                    Amount
                    <Field type="number" name="amount" placeholder="" />
                  </Label>
                </GridBox>
              </Row>
              <SubmitButton
                className={cx({ loading: isLoading, verify: true })}
                type="submit"
                disabled={isLoading}
              >
                <MoonLoader color="#fff" size={15} loading={isLoading} />
                <span>Process Payment</span>
              </SubmitButton>
            </Box>
          </form>
        )}
      </Formik>
    </Box>
  );
};
