import React, { useState } from "react";
import toast from "react-hot-toast";

import {
  AccountStatsStyle,
  ActionButton,
  AccountStatsItem,
  TableWrapper,
  TableWrapperHeading,
  TableWrapperFooter,
  PrintReportBtn,
  Table as OldTable,
} from "../../app/styles";
import useModal from "hooks/useModal";
import Switch from "components/Switch";
import Table from "components/auth/Table";
import Button from "components/auth/Button";
import { DebtSummaryType, DebtTransactionLogType, DebtType } from "models/app/Debt";
import { makeRequest } from "utils/httpClient";
import { NewPaymentModal } from "components/pages/company/debtPortfolio/components/index";
import { CompanyBankAccountType, PersonBankAccountType } from "models/app/Services";
import PaymentButtons from "components/pages/company/components/PaymentButtons";
import { Pages } from "components/pages/company/types";
import { CastJqlType } from "models/types/Services";
import { CompanyType } from "models/app/Profile";
import { httpClient } from "utils/index";
import { AUTHENTICATION_CLASS } from "common/constants";

interface DebtPortfolioProps {
  debtSummary: DebtSummaryType;
  debts: Array<DebtType>;
  companyId?: string | number;
  bankAccounts: Array<CompanyBankAccountType | PersonBankAccountType>;
  goto: (x: Pages) => void;
}

const DebtPortfolio: React.FC<DebtPortfolioProps> = ({
  debtSummary,
  debts,
  companyId,
  bankAccounts,
  goto,
}) => {
  const { isModalOpen, openModal, closeModal } = useModal();

  const [_debtSummary, setDebtSummary] = useState<DebtSummaryType>(debtSummary);
  const [, setLoading] = React.useState(false);
  const [_debts, setDebts] = React.useState<Array<DebtType>>([]);
  const [transactionLog, setTransactionLog] = React.useState<Array<DebtTransactionLogType>>([]);

  const handleGenerateStatement = async () => {
    await makeRequest({
      path: "/jql",
      method: "POST",
      config: {
        data: {},
      },
    });
    const res = await httpClient.post(
      "jql",
      {
        __meta__: {
          authenticationClass: AUTHENTICATION_CLASS,
          namespace: "debt.Report",
          schema: "controller",
          intent: "generate_company_payment_statement",
        },
        company: companyId,
      },
      {
        responseType: "blob",
      },
    );
    const url = window.URL.createObjectURL(new Blob([res.data]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", `Account Statement - ${companyId}.pdf`);
    document.body.appendChild(link);
    link.click();
  };

  React.useEffect(() => {
    const asyncRequest = async () => {
      setLoading(true);
      try {
        const [paymentHistory, , error]: any = await makeRequest({
          path: "/jql",
          method: "POST",
          config: {
            data: {
              __meta__: {
                authenticationClass: AUTHENTICATION_CLASS,
                namespace: "debt.PaymentTransactionLog",
                schema: "model",
                intent: "retrieve",
                filter: {
                  "debt.company.id": companyId,
                },
              },
              id: null,
              reference: null,
              amount: null,
              currency: null,
              payment_date: null,
              debt: {
                debt_type: {
                  name: null,
                  mda: {
                    name: null,
                  },
                },
              },
            },
          },
        });
        const [debt, , error1]: any = await makeRequest({
          path: "/jql",
          method: "POST",
          config: {
            data: {
              __meta__: {
                authenticationClass: AUTHENTICATION_CLASS,
                namespace: "debt.Debt",
                schema: "model",
                intent: "retrieve",
                filter: {
                  "company.id": companyId,
                },
              },
              id: null,
              debt_type: {
                id: null,
                name: null,
                mda: {
                  id: null,
                  name: null,
                },
              },
              amount_owed: null,
              amount_paid: null,
              amount_outstanding: null,
            },
          },
        });
        // console.log({ debt, paymentHistory });
        if (error && error.errors && Array.isArray(error.errors)) {
          error.errors.map(({ error_message }) => {
            return toast.error(error_message);
          });
          return;
        }
        if (error1 && error1.errors && Array.isArray(error1.errors)) {
          error.errors.map(({ error_message }) => {
            return toast.error(error_message);
          });
          return;
        }

        setTransactionLog(paymentHistory.data);
        setDebts(debt.data);
      } catch (err) {
        // @ts-ignore
        toast.error(err?.message);
      } finally {
        setLoading(false);
      }
    };

    asyncRequest();
  }, [_debtSummary]);

  return (
    <>
      {_debtSummary && (
        <div>
          <div className="row my-4">
            <div className="col-6 col-sm-4">
              <h4 className="mb-0">
                {_debtSummary.currency}
                {_debtSummary.amountOwedStr}
              </h4>
              <small className="text-muted text-uppercase">Initial Debt</small>
            </div>

            <div className="col-6 col-sm-4">
              <h4 className="mb-0">
                {_debtSummary.currency}
                {_debtSummary.amountOutstandingStr}
              </h4>
              <small className="text-muted text-uppercase">Outstanding Debt</small>
            </div>

            <div className="col-6 col-sm-4 mt-3 mt-sm-0">
              <h4 className="mb-0">
                {_debtSummary.currency}
                {_debtSummary.amountPaidStr}
              </h4>
              <small className="text-muted text-uppercase">Amount Paid</small>
            </div>
          </div>

          <div className="mb-5">
            <Table
              title="Debt Records"
              columns={["MDA", "Debts Type", "Amount Owed", "Amount Paid", "Amount Outstanding"]}
              rows={_debts.map(({ amountOwed, amountOutstanding, amountPaid, debtType }) => ({
                mda: debtType.mda.name,
                name: debtType.name,
                owed: amountOwed.toLocaleString("en-US", { currency: "NGN", style: "currency" }),
                paid: amountPaid.toLocaleString("en-US", { currency: "NGN", style: "currency" }),
                outstanding: amountOutstanding.toLocaleString("en-US", {
                  currency: "NGN",
                  style: "currency",
                }),
              }))}
            />
          </div>

          <Table
            title="Payment History"
            columns={["Receipt Number", "MDA", "Debt Type", "Amount Paid", "Date Paid"]}
            rows={transactionLog.map(({ reference, debt, amount, paymentDate }) => ({
              reference,
              mda: debt.debtType.mda.name,
              type: debt.debtType.name,
              amount: amount.toLocaleString("en-US", { currency: "NGN", style: "currency" }),
              paymentDate,
            }))}
            footer={
              <PrintReportBtn onClick={handleGenerateStatement}>
                Print Report
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none">
                  <path
                    d="M6 9V2h12v7M6 18H4a2 2 0 01-2-2v-5a2 2 0 012-2h16a2 2 0 012 2v5a2 2 0 01-2 2h-2"
                    stroke="#4C9A2A"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M18 14H6v8h12v-8z"
                    stroke="#4C9A2A"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </PrintReportBtn>
            }
          />

          <div className="d-flex mt-4">
            <div className="mr-3">
              <Button label="New Card Payment" onClick={() => goto(Pages.paymentCard)} />
            </div>
          </div>

          {/*  <div>*/}
          {/*    <Button label="Direct Debit" onClick={() => goto(Pages.paymentDirectDebit)} />*/}
          {/*  </div>*/}
          {/*</div>*/}
        </div>
      )}

      <Switch condition={isModalOpen}>
        <NewPaymentModal
          closeModal={closeModal}
          debts={debts}
          setDebtSummary={setDebtSummary}
          bankAccounts={bankAccounts}
        />
      </Switch>
    </>
  );
};

export default DebtPortfolio;
