export const theme = {
  base_font:
    "-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji'",
  base_font_size: '14px',

  white: '#ffffff',
  black: '#000000',
  black_light: '#141414',
  gray_dark: '#737373',
  gray: '#949494',
  gray_light: '#c6c5c7',
  gray_lighter: '#f7f7f4',

  border_color: '#e5e8eb',

  primary_color: '#156148',
  secondary_color: '#2dab52',
  success_color: '#3dba6f',
  danger_color: '#fe413b',
  warning_color: '#fab63f',
  info_color: '#18b9d4',
  light_color: '#f4f6f9',
  dark_color: '#2f3c4b',
  dark_nav: '#30384c',

  error: '#dc3545',
  primary_color_dark: '#0071ff',
  turquoise_color: '#31c3b2',
}
