import React, { FC, useState, useEffect } from "react";
import { RouteComponentProps } from "@reach/router";
import toast from "react-hot-toast";

import MDAForm from "./MDAForm";
import Card from "components/auth/Card";
import { makeRequest } from "utils/httpClient";
import AuthLayout from "components/auth/AuthLayout";
import { Intents } from "common/enums";
import { AUTHENTICATION_CLASS } from "common/constants";

const defaultInitialVals = {
  code: "",
  name: "",
  abbr: "",
};

const breadcrumbs = [
  { label: "Home", link: "/app/welcome" },
  { label: "MDAs", link: "/app/admin/mdas" },
  { label: "Add MDA" },
];

const EditMDA: FC<RouteComponentProps<{ id: string }>> = ({ id }) => {
  const [initialValues, setInitialValues] = useState(defaultInitialVals);

  useEffect(() => {
    let isMounted = true;

    const runAsync = async () => {
      const [data, , error] = await makeRequest({
        path: "/jql",
        method: "POST",
        config: {
          data: {
            __meta__: {
            authenticationClass: AUTHENTICATION_CLASS,
              namespace: "system.MDA",
              schema: "model",
              intent: Intents.retrieveOne,
              uniqueObject: {
                id,
              },
            },
            abbr: null,
            code: null,
            name: null,
          },
        },
      });

      if (isMounted) {
        const { abbr, code, name } = data.data;
        setInitialValues({ abbr, code, name });
      }

     
    };

    runAsync();

    return () => {
      isMounted = false;
    };
  }, [id]);

  const handleSubmit = async (payload) => {
    const [data, , error] = await makeRequest({
      path: "/jql",
      method: "POST",
      config: {
        data: {
          __meta__: {
            authenticationClass: AUTHENTICATION_CLASS,
            namespace: "system.MDA",
            schema: "model",
            intent: Intents.update,
            uniqueObject: {
              id,
            },
          },
          ...payload,
        },
      },
    });

    if (error && error.errors && Array.isArray(error.errors)) {
      error.errors.map(({ error_message }) => {
        return toast.error(error_message);
      });
    } else {
      toast.success(data.message);
      // redirect
    }
  };

  return (
    <AuthLayout title="Edit User" breadcrumbs={breadcrumbs}>
      <div className="col-12 col-lg-8">
        <Card>
          <MDAForm handleSubmit={handleSubmit} initialValues={initialValues} />
        </Card>
      </div>
    </AuthLayout>
  );
};

export default EditMDA;
