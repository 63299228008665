import React, { FC, useState, useEffect } from "react";
import { object, string } from "yup";
import { Formik, Form, FormikHelpers, useFormikContext } from "formik";

import Button from "components/auth/Button";
import { MdaType } from "models/app/System";
import { makeRequest } from "utils/httpClient";
import TextField from "components/auth/TextField";
import SelectField from "components/auth/SelectField";
import { CastJqlType, JqlQueryType } from "models/types/Services";
import { Intents } from "common/enums";
import { UserTypes } from "models/db-schema";
import { AUTHENTICATION_CLASS } from "common/constants";

const defaultInitialVals = {
  firstName: "",
  lastName: "",
  phone: "",
  email: "",
  userType: "",
  mda: "",
};

const validationSchema = object({
  firstName: string().required(),
  lastName: string().required(),
  phone: string().required(),
  email: string().email().required(),
  userType: string().required(),
  mda: string().when("userType", {
    is: (val) => {
      const showMDAField = val === UserTypes.MDA_ADMIN || val === UserTypes.MDA_USER;
      return showMDAField;
    },
    then: string().required("Please select MDA"),
  }),
});

const useTypes: { label: string; value: UserTypes }[] = [
  { label: "Super Admin", value: UserTypes.SUPER_ADMIN },
  { label: "Admin", value: UserTypes.ADMIN },
  { label: "MDA Admin", value: UserTypes.MDA_ADMIN },
  { label: "MDA User", value: UserTypes.MDA_USER },
  { label: "Company User", value: UserTypes.CORPORATE_REPRESENTATIVES },
];

const jqlQuery: JqlQueryType<CastJqlType<MdaType>> = {
  __meta__: {
    authenticationClass: AUTHENTICATION_CLASS,
    namespace: "system.MDA",
    schema: "model",
    intent: Intents.retrieve,
  },
  id: null,
  name: null,
};

type UserFormData = {
  firstName: string;
  lastName: string;
  phone: string;
  email: string;
  userType: string;
  mda: string;
};

type Props = {
  initialValues?: UserFormData;
  handleSubmit: (val: UserFormData) => Promise<void>;
};

const MDASwitch: FC = () => {
  const {
    values: { userType },
    setFieldValue,
  } = useFormikContext<UserFormData>();

  useEffect(() => {
    const showMDAField = userType === UserTypes.MDA_ADMIN || userType === UserTypes.MDA_USER;

    if (!showMDAField) {
      setFieldValue("mda", "");
    }
  }, [userType]);

  return null;
};

const UserForm: FC<Props> = ({ handleSubmit, initialValues = defaultInitialVals }) => {
  const [MDAs, setMDAs] = useState<{ label: string; value: number }[]>([]);

  useEffect(() => {
    let isMounted = true;

    const runAsync = async () => {
      const [data, , error] = await makeRequest({
        path: "/jql",
        method: "POST",
        config: {
          data: jqlQuery,
        },
      });

      if (isMounted) {
        const formattedMDAs = data.data?.map(({ id, name }) => ({ label: name, value: id }));
        setMDAs(formattedMDAs);
      }
    };

    runAsync();

    return () => {
      isMounted = false;
    };
  }, []);

  const onSubmit = async (values: UserFormData, actions: FormikHelpers<UserFormData>) => {
    await handleSubmit(values);
    actions.setSubmitting(false);
  };

  return (
    <Formik
      enableReinitialize
      onSubmit={onSubmit}
      initialValues={initialValues}
      validationSchema={validationSchema}
    >
      {({ isSubmitting, values }) => {
        const { userType } = values;
        const showMDAField = userType === UserTypes.MDA_ADMIN || userType === UserTypes.MDA_USER;

        return (
          <Form>
            <div className="row">
              <div className="col-md-6">
                <TextField name="firstName" label="First Name" />
              </div>

              <div className="col-md-6">
                <TextField name="lastName" label="Last Name" />
              </div>
            </div>

            <div className="row">
              <div className="col-md-6">
                <TextField name="phone" label="Phone number" />
              </div>

              <div className="col-md-6">
                <TextField name="email" label="Email Address" />
              </div>
            </div>

            <div className="row">
              <div className="col-md-6">
                <SelectField
                  name="userType"
                  label="User Type"
                  options={[{ label: "Select User Type", value: "" }, ...useTypes]}
                />
              </div>

              {showMDAField && (
                <div className="col-md-6">
                  <SelectField
                    name="mda"
                    label="MDA"
                    options={[{ label: "Select MDA", value: "" }, ...MDAs]}
                  />
                </div>
              )}
            </div>

            <MDASwitch />
            <div className="text-left mt-2">
              <Button type="submit" label="SUBMIT" loading={isSubmitting} disabled={isSubmitting} />
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

export default UserForm;
