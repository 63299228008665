import React, { FC, useState, useEffect } from "react";
import { FaEdit, FaRegListAlt } from "react-icons/fa";
import { RouteComponentProps, navigate } from "@reach/router";

import AdminWrapper from "../index";
import { DebtType } from "models/app/Debt";
import { makeRequest } from "utils/httpClient";
import { DebtorsColumnHeader } from "./constants";
import { Intents } from "common/enums";
import { CastJqlType, JqlQueryType } from "models/types/Services";
import DataTable from "components/generic/table/DataTable";
import ActionMenu from "components/generic/menu/ActionMenu";
import { AUTHENTICATION_CLASS } from "common/constants";

const keys = [
  {
    key: "key__mdasMenuItem__payment",
    label: "Log a Payment",
    icon: FaEdit,
  },
  {
    key: "key__mdasMenuItem__debt",
    label: "Log a debt",
    icon: FaEdit,
  },
  {
    key: "key__mdasMenuItem__statement",
    label: "Generate statement",
    icon: FaRegListAlt,
  },
];

const ViewDebtors: FC<RouteComponentProps<{ id: string }>> = ({ id }) => {
  const [mdaName, setMDAName] = useState("");

  useEffect(() => {
    let isMounted = true;

    const runAsync = async () => {
      const [data] = await makeRequest({
        path: "/jql",
        method: "POST",
        config: {
          data: {
            __meta__: {
              authenticationClass: AUTHENTICATION_CLASS,
              namespace: "system.MDA",
              schema: "model",
              intent: Intents.retrieveOne,
              uniqueObject: {
                id,
              },
            },
            abbr: null,
            code: null,
            name: null,
          },
        },
      });

      if (isMounted) {
        const { name } = data.data;
        setMDAName(name);
      }
    };

    runAsync();

    return () => {
      isMounted = false;
    };
  }, [id]);

  const jql: JqlQueryType<CastJqlType<DebtType>> = {
    __meta__: {
      authenticationClass: AUTHENTICATION_CLASS,
      namespace: "debt.Debt",
      schema: "model",
      intent: Intents.retrieve,
      filter: {
        debtType: {
          mda: {
            id,
          },
        },
        "amountOutstanding.gt": 0,
      },
    },
    id: null,
    debtType: {
      id: null,
      name: null,
      mda: {
        id: null,
        name: null,
      },
    },
    company: {
      id: null,
      name: null,
    },
    amountOutstanding: null,
    currency: null,
  };

  const handleMenuClick = (e, rowid) => {
    switch (e.key) {
      case "key__mdasMenuItem__payment":
        navigate(`/app/admin/debts/${id}/${rowid}/add-payment`);
        break;

      case "key__mdasMenuItem__debt":
        navigate(`/app/admin/debts/${id}/${rowid}/add-debt`);
        break;

      default:
        break;
    }
  };

  const TableRow = ({ row }: { row: DebtType }) => {
    return (
      <tr>
        <td>{row.debtType.name}</td>
        <td>{row.company.name}</td>
        <td>
          {row.amountOutstanding} [{row.currency}]
        </td>
        <td>
          <ActionMenu id={row.id} keys={keys} clickHandler={handleMenuClick} />
        </td>
      </tr>
    );
  };

  return (
    <AdminWrapper breadcrumbs={[]}>
      <DataTable
        columns={DebtorsColumnHeader}
        jqlQuery={jql}
        title={mdaName}
        description={"description goes here"}
        enableSearch
        searchPlaceholder={"search for a debtor"}
        RowComponent={TableRow}
        debug={true}
      />
    </AdminWrapper>
  );
};

export default ViewDebtors;
