import styled from 'styled-components'

export const FileFieldWrapper = styled.div`
  margin-bottom: 1rem;

  label {
    margin: 0;
    display: block;
  }

  .label,
  .label-approved {
    font-weight: 600;
    font-size: 0.875rem;
    display: inline-block;
    margin-bottom: 0.5rem;
    font-family: 'Montserrat', sans-serif;
    color: ${({ theme }) => theme.dark_color};
  }

  .label-approved {
    margin-bottom: 0;
  }

  .form-inner-wrapper {
    display: flex;

    .form-icon-wrapper {
      display: flex;
      color: #495057;
      margin-left: -1px;
      align-items: center;
      justify-items: center;
      border: 1px solid #ced4da;
      padding: 0.375rem 0.75rem;
      border-top-right-radius: 0.25rem;
      border-bottom-right-radius: 0.25rem;
      background: ${({ theme }) => theme.white};

      svg {
        width: 1.25rem;
        height: 1.25rem;
      }
    }

    .form-control-wrapper {
      position: relative;

      .form-control {
        line-height: 2.2;
        font-size: 0.8rem;
        border-radius: 0.25rem;
        padding: 0.375rem 0.75rem;
        border: 1px solid #ced4da;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        background-color: ${({ theme }) => theme.white};
        transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
      }

      .custom-file {
        top: 50%;
        left: 50%;
        display: flex;
        position: absolute;
        align-items: center;
        width: calc(100% - 1.5rem);
        height: calc(100% - 0.75rem);
        transform: translate(-50%, -50%);
        background-color: ${({ theme }) => theme.white};
      }
    }
  }

  .file-status {
    font-style: italic;
  }
`
