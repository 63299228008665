import React, { useState, FC } from "react";
import toast from "react-hot-toast";
import { object, string } from "yup";
import { navigate } from "@reach/router";
import { Formik, Form, FormikHelpers } from "formik";
import { Button as AntButton } from "antd";
import AdminWrapper from "../index";
import Button from "components/auth/Button";
import { makeRequest } from "utils/httpClient";
import { Intents } from "common/enums";
import AutoComplete from "components/auth/Autocomplete";
import { AUTHENTICATION_CLASS } from "common/constants";
import CreateNewDeptType from "./CreateNewDeptType";

const breadcrumbs = [
  { label: "Home", link: "/app/welcome" },
  { label: "Debts", link: "/app/admin/debts" },
  { label: "Configure Debt" },
];

const initialValues = {
  debtType: "",
  company: "",
};

const validationSchema = object({
  debtType: string().required(),
  company: string().required(),
});

type ConfigureDebtFormData = {
  debtType: string;
  company: string;
};

const ConfigureDebt: FC = () => {
  const [showCreateDeptType, setShowCreateDeptType] = useState(false);
  const onSubmit = async (
    values: ConfigureDebtFormData,
    actions: FormikHelpers<ConfigureDebtFormData>,
  ) => {
  
    const [data, , error] = await makeRequest({
      path: "/jql",
      method: "POST",
      config: {
        data: {
          __meta__: {
            authenticationClass: AUTHENTICATION_CLASS,
            namespace: "debt.Debt",
            schema: "model",
            intent: Intents.create,
          },
          ...values,
        },
      },
    });

    if (error && error.errors && Array.isArray(error.errors)) {
      error.errors.map(({ error_message }) => {
        return toast.error(error_message);
      });
    } else {
      toast.success(data.message);
      navigate("/app/admin/debts");
    }
  };

  return (
    <>
      <AdminWrapper title="Configure Debt" breadcrumbs={breadcrumbs} className="col-12 col-lg-8">
        <Formik
          enableReinitialize
          onSubmit={onSubmit}
          initialValues={initialValues}
          validationSchema={validationSchema}
        >
          {({ isSubmitting }) => {
            return (
              <Form>
                <div className="row">
                  <div className="col-12 ">
                    <AutoComplete
                      name="debtType"
                      label="Debt Type"
                      namespace="debt.DebtType"
                      resultObj={{
                        id: null,
                        name: null,
                        mda: {
                          id: null,
                          name: null,
                        },
                      }}
                      resultCallback={({ id, name, mda }) => ({
                        label: `${name} - ${mda.name}`,
                        value: id,
                      })}
                      defaultOptions={[{ label: "Select Debt Type", value: "" }]}
                    />
                  </div>

                  <div className="col-12 m-0">
                    <p className="mb-2">
                      Can't find a matching dept type?
                      <AntButton type="link" onClick={() => setShowCreateDeptType(true)}>
                        Create new dept type
                      </AntButton>
                    </p>
                  </div>

                  <div className="col-12">
                    <AutoComplete
                      name="company"
                      label="Company"
                      namespace="profile.Company"
                      defaultOptions={[{ label: "Select Company", value: "" }]}
                    />
                  </div>
                </div>

                <div className="text-left mt-2">
                  <Button
                    type="submit"
                    label="SUBMIT"
                    loading={isSubmitting}
                    disabled={isSubmitting}
                  />
                </div>
              </Form>
            );
          }}
        </Formik>
      </AdminWrapper>
      <CreateNewDeptType
        onCancel={() => setShowCreateDeptType(false)}
        showCreateDeptType={showCreateDeptType}
      />
    </>
  );
};

export default ConfigureDebt;
