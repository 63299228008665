import React from 'react'
import MoonLoader from 'react-spinners/MoonLoader'
import cx from 'classnames'

import { Company } from './index'
import Button from 'components/auth/Button'
import { ConfirmAssociation } from './styles'
import { ModalWrapper } from '../../app/styles'

type ConfirmAssociationModalProps = {
  closeModal: () => void
  associateToCompany: (c: Company) => void
  company: Company
  isSubmitting: boolean
}
const ConfirmAssociationModal: React.FC<ConfirmAssociationModalProps> = ({
  company,
  closeModal,
  isSubmitting,
  associateToCompany,
}) => {
  return (
    <ModalWrapper>
      <ConfirmAssociation>
        <p>Are you sure you want to be associated to this company?</p>
        <p>{company.name}</p>
        <div>
          <Button label="No" variant="gray_light" onClick={closeModal} />
          <Button label="Yes" onClick={() => associateToCompany(company)} disabled={isSubmitting} />
        </div>
      </ConfirmAssociation>
    </ModalWrapper>
  )
}

export default ConfirmAssociationModal
